import { Coord, Patron, MimeTypes, FXinfo } from '../../vibe/vibe';
import { store } from '../../data/store';
import * as logger from '../../utils/logger';
import { useState, useRef, useEffect, useCallback } from 'react';
import StreamPanelHUDremote from '../StreamPanelHUDremote/StreamPanelHUDremote';
import ChatPanelMini from '../ChatPanelMini/ChatPanelMini';
import { getColorByKey } from '../../utils/clrs';
import { polycastTouched, streamPanelCoordsChanged, streamPanelFXreceived } from '../../relay/signalRelay';
import { gsap } from 'gsap';
import { getGUID, makeTopmost, getParentStreamPanel } from '../../utils/utils';
import SendMenu from '../SendMenu/SendMenu';
import { FXevent } from '../../relay/p2pRelay';
import FXpanel from '../FXpanel/FXpanel';

const CN : string = 'StreamPanelFaux';

const baseVidURL : string = 'https://appcloud9.com/vid/';

const names : string[] = [
  'dirtybird',
  'lite_work',
  'starCore',
  'FluffyBunsy',
  'morty99',
  'no shade',
  'golden',
  'she shell',
  'kittehkit',
  'zenzen',
  'totoro',
  'FluffyYoda',
  'strongbaby',
  'CloudCar',
  'spiral9'
];

let index : number = -1;

const fauxPatrons : Patron[] = [];

// populate fauxPatrons from names
names.find( ( name , i ) => {
  const id : string = 'faux' + i;
  const fauxPatron : Patron = {
    patronID : id,
    accountID : id,
    mojoBalance : 0,
    name : name,
    profile : {
      awayMedia : {
        mediaID : id,
        mimeType : MimeTypes.mp4,
        url : `${ baseVidURL }${ id }.mp4`
      }
    }
  };
  fauxPatrons.push( fauxPatron );
} );

function getNextFauxPatron() : Patron {
  index++;
  if( index >= fauxPatrons.length ) index = 0;
  return fauxPatrons[ index ];
}

interface props {
  coord : Coord
}

export default function StreamPanelFaux( {
  coord
} : props ) {

  // hooks
  const [ isChatDisplayed, setIsChatDisplayed ] = useState( false );
  const [ isSendMenuDisplayed, setIsSendMenuDisplayed ] = useState( false );
  const [ tweens, setTweens ] = useState( [] as any[] );
  const [ patron, setPatron ] = useState( getNextFauxPatron() );
  const [ clr, setClr ] = useState( getColorByKey( patron.patronID, 'StreamPanel' ) );
  const [ fxInfo, setFXinfo ] = useState( null as FXinfo | null );

  // refs
  const streamPanelDivRef = useRef( null as any );
  const tweenRef = useRef( null as gsap.core.Tween | null );

  // callbacks
  const receivedFX = useCallback( ( fxEvent : FXevent ) => {
    console.log( 'fxEvent :', fxEvent );
    console.log( CN + '.receivedFX' );
    console.log( 'patron.patronID :', patron.patronID );
    const patronID = patron.patronID || 'noPatron';
    if( fxEvent?.to?.includes( patronID ) ){
      setFXinfo( fxEvent?.fxInfo as FXinfo );
    }
  }, [] );

  const displayNameplate = useCallback( () => {
    const nameplate : HTMLElement | undefined = streamPanelDivRef?.current?.querySelector( '.ch-nameplate' );
    if( !nameplate ) return logger.warn( 'no nameplate to display...' );

    const outro : gsap.core.Tween | undefined = tweens[ 0 ];

    if( outro !== undefined ) {
      outro.pause();
      gsap.to( nameplate, 0.25, {
        opacity : 1,
        height : 39,
        onComplete : () => {
          outro.restart( true );
        }
      } );
    } else {
      tweens[ 0 ] = gsap.to( nameplate, 1, {
        delay : 3,
        height : 0,
        opacity : 0,
      } );
    }
  }, [] );

  useEffect( () => {
    displayNameplate();
    polycastTouched.add( displayNameplate );
    streamPanelFXreceived.add( receivedFX );
    return cleanup;
  }, [] );

  return <div
    ref={ streamPanelDivRef }
    id={ `panel-stream-${ patron.patronID }` }
    className={ `panel-stream remote ${ clr }${ isSendMenuDisplayed || isChatDisplayed ? ' focused' : '' }` }
    data-coord={ coord }
    data-patronid={ getGUID() }
    draggable={ !isSendMenuDisplayed }
    onMouseDown={ e => makeTopmost( getParentStreamPanel( e.target as HTMLElement ) ) }
    onDragEnter={ e => {
      ( e.target as HTMLElement ).classList.add( 'targeted' );
      e.preventDefault();
    } }
    onDragOver={ e => {
      ( e.target as HTMLElement ).classList.add( 'targeted' );
      e.preventDefault();
    } }
    onDragLeave={ e => {
      ( e.target as HTMLElement ).classList.remove( 'targeted' );
    } }
    onDragStart={ e => {
      dragStarted( e.target );
    } }
    onDrop={ dropped }
  >
    <div className={ `remote-video-container` } >
      <video
        className="ch-video"
        autoPlay={ true }
        playsInline={ true }
        muted={ true }
        src={ patron.profile?.awayMedia?.url }
        loop={ true }
      />
      <header className="ch-nameplate" >
        <p className="ch-text">{ patron.name }</p>
      </header>
    </div>
    { isSendMenuDisplayed &&
      <SendMenu
        patronID={ patron.patronID }
        setIsSendMenuDisplayed={ setIsSendMenuDisplayed }
      />
    }
    <StreamPanelHUDremote
      setStreamPanelIsChatDisplayed={ setIsChatDisplayed }
      streamPanelDivRef={ streamPanelDivRef }
      setIsSendMenuDisplayed={ setIsSendMenuDisplayed }
      isSendMenuDisplayed={ isSendMenuDisplayed }
    />
    { fxInfo &&
      <FXpanel
        fxInfo={ fxInfo }
        setFXinfo={ setFXinfo }
      />
    }
    <ChatPanelMini
      chatMsgs={ [] }
      classNames={ isChatDisplayed ? 'displayed' : '' }
      patronID={ patron.patronID }
      fauxName={ patron.name }
    />
  </div>;

  function dragStarted( streamPanel ) : void {
    streamPanelDivRef.current.classList.add( 'dragging' );
    store.sessionData = {
      streamPanelDragged : streamPanel
    };
  }

  function dragEnded() {
    const panelBeingDragged : HTMLDivElement = document.querySelector( '.dragging' ) as HTMLDivElement;
    panelBeingDragged && panelBeingDragged.classList.remove( 'dragging' );
    const dropTargets = document.querySelectorAll( '.drop-target' );
    const streamPanels = document.querySelectorAll( '.panel-stream' );
    dropTargets.forEach( dropTarget => dropTarget.classList.remove( 'targeted' ) );
    streamPanels.forEach( streamPanel => streamPanel.classList.remove( 'targeted' ) );
    streamPanelCoordsChanged.dispatch();
  }

  function dropped( e ) : void {
    let streamPanel;
    let target = e.target;
    while( !streamPanel ) {
      if( target.classList.contains( 'panel-stream' ) ) {
        streamPanel = target;
      } else {
        if( target.parentNode === null ) {
          e.preventDefault();
          dragEnded();
          return;
        }
        target = target.parentNode;
      }
    }
    const streamPanelDragged = store.sessionData.streamPanelDragged;
    const movedToCoord = streamPanel.getAttribute( 'data-coord' );
    const movedFromCoord = streamPanelDragged.getAttribute( 'data-coord' );
    streamPanel.setAttribute( 'data-coord', movedFromCoord );
    streamPanelDragged.setAttribute( 'data-coord', movedToCoord );
    dragEnded();
  }

  function cleanup() {
    polycastTouched.remove( displayNameplate );
    streamPanelFXreceived.remove( receivedFX );
  }

}
