
export enum rtcDeviceTypes {
  audioinput = 'audioinput',
  videoinput = 'videoinput'
}

export interface rtcDevice {
  deviceId : string,
  kind : rtcDeviceTypes,
  label : string,
  groupId : string
}
/*
[
  {
    "deviceId": "default",
    "kind": "audioinput",
    "label": "Default - JBL Quantum 400 Chat (0ecb:1f4c)",
    "groupId": "409d4ad84bf7a536543c8d14f3e395450340953291583bce4961ee34b37e2309"
  },
  {
    "deviceId": "d3f42737528ffb0aef82a09e0f82c4957dba23122479a6db8f12b0740d3a7b39",
    "kind": "audioinput",
    "label": "Display Audio (05ac:1107)",
    "groupId": "236107f4f365c3147804888ce859ce329c69bc804533d4db7c3b239f61f2e429"
  },
  {
    "deviceId": "dc195408e0520be943b22901136a82e7030765d92791f14cc46f491fbc194e1e",
    "kind": "audioinput",
    "label": "Display Audio (05ac:1107)",
    "groupId": "af87f495a613401f8fa7323c6eef9e9a6b43711207d06ea3b61ad111856c1b92"
  },
  {
    "deviceId": "d2dfe9c8096c9b635245106305265e6876c3a8b48eb810f6794f77da280848bf",
    "kind": "audioinput",
    "label": "JBL Quantum 400 Chat (0ecb:1f4c)",
    "groupId": "409d4ad84bf7a536543c8d14f3e395450340953291583bce4961ee34b37e2309"
  },
  {
    "deviceId": "204fd63518ad533ab741e823eab3db366d64ff8a1aad4bf511731baff2efddb7",
    "kind": "audioinput",
    "label": "Soundflower (2ch)",
    "groupId": "f752530d2e5aca998a749df4e7837f7094274654ac9bfa2c38aa289913fa543d"
  },
  {
    "deviceId": "44e66118c8b635aad999a077a88c59896a78c50e32171bc112c2e3b64dd59ed6",
    "kind": "audioinput",
    "label": "Soundflower (64ch)",
    "groupId": "62826d41517ca8e8196b10f334971448580adf36f9ce60b7bec028ae8b25e800"
  },
  {
    "deviceId": "cf346b6bc8f0cf0066c0e45d819dc17ae67c9c7f02d7efb4903707039d620335",
    "kind": "videoinput",
    "label": "FaceTime HD Camera (Display) (05ac:1112)",
    "groupId": "b256b4ad40e34cd05c90c323b4d06346ddff408823d8c2fb88b990a3113c0e2e"
  },
  {
    "deviceId": "ce8b4c4dce3eddfe6236639e631fbcdf192117bd91f5443eac8884e20dabc8ef",
    "kind": "videoinput",
    "label": "FaceTime HD Camera (Display) #2 (05ac:1112)",
    "groupId": "e87ef7aa106726d1d3c8a885d85f515a19281565e2a1d34e15f9483ea57360b8"
  }
]
 */
