import * as utils from '../utils/utils';
import { getParams } from '../utils/utils';
import { store } from '../data/store';
import { AppModes, SettingsPanelModes, ReservedClubNames } from '../data/consts';
import * as logger from '../utils/logger';
import { getClubAndEventByPath } from '../api/api';
import { subscribeToDoc, docTypes } from '../fbase/fbase';

const CN : string = 'routes';

const signalPathChanged : signals.Signal = utils.getSignal( 'pathChanged' );

export function start() : void {
  logger.log( CN + '.start' );

  signalPathChanged.add( pathChanged );
  window.addEventListener( 'popstate', e => {
    pathChanged( getCurrentPath() );
  } );
  // set initial app state
  pathChanged( getCurrentPath() );
}

export function getCurrentPath() : string {
  return document.URL.split( document.location.host + '/' )[ 1 ].split( '?' )[ 0 ];
}

export function setPath( newPath : string = '' ) : any {
  logger.log( CN + '.setPath' );

  store.flags = getParams();

  newPath = newPath || '';

  let shouldNavigate : boolean = false;

  switch( true ) {
    case newPath === getCurrentPath() :
      return logger.warn( 'not setting newPath as it is the same as the current path.' );
    case store.hasUnsavedData :
      shouldNavigate = window.confirm( 'Changes you made may not be saved.  Do you wish to leave?' );
      break;
    default :
      shouldNavigate = true;
  }

  if( shouldNavigate ) {
    window.history.pushState( {}, setTitleByPathOrString( newPath ), document.location.protocol + '//' + document.location.host + '/' + newPath );
    signalPathChanged.dispatch( newPath );
  }
}

// spec here : https://docs.google.com/spreadsheets/d/1YP5Uho08wUAE6kTq6-JzieTagGjzcgYUhLjimwdl9v4/edit#gid=0

function pathChanged( path ) : void {

  store.flags = getParams();

  // TODO : lowercase case by case
  // path = path.toLowerCase();

  const pathArray = path.split( '/' );

  setTitleByPathOrString( path );

  switch( true ) {
    case !path || path === 'home' :
      store.appMode = AppModes.HOME;
      break;
    case pathArray[ 0 ] === ReservedClubNames.settings :
      store.appMode = AppModes.SETTINGS;
      switch( pathArray[ 1 ] ) {
        case ReservedClubNames.profile :
          store.settingsPanelMode = SettingsPanelModes.PROFILE;
          break;
        case ReservedClubNames.account :
          store.settingsPanelMode = SettingsPanelModes.ACCOUNT;
          break;
        case ReservedClubNames.club :
          store.settingsPanelMode = SettingsPanelModes.CLUB;
          break;
        case ReservedClubNames.star :
          store.settingsPanelMode = SettingsPanelModes.STAR;
          break;
        default :
          store.settingsPanelMode = SettingsPanelModes.PROFILE;
      }
      break;
    case pathArray.length === 2 && pathArray[ 0 ] === ReservedClubNames.club :
      console.log( 'we think we have a vibeEvent ID :' +  pathArray[ 1 ] + ':' );
      subscribeToDoc( docTypes.club, pathArray[ 1 ] );
      store.appMode = AppModes.CLUB;
      break;
    case pathArray.length === 2 && pathArray[ 0 ] === ReservedClubNames.star :
      store.appMode = AppModes.STAR;
      // TODO : subscribe to the star to get it populated in the store?
      break;
    case pathArray.length === 2 && pathArray[ 0 ] === ReservedClubNames.patron :
      store.appMode = AppModes.PATRON;
      // TODO : subscribe to the patron (viewingPatron?, or...)?? (can't be patron in the store as that is you...) to get it populated in the store?
      break;
    case pathArray.length === 2 :  // {vibeEvent.urlSafeName}/{vibeEvent.urlSafeName}
      store.appMode = AppModes.EVENT;
      getClubAndEventByPath( {
        clubURLsafeName : pathArray[ 0 ],
        eventURLsafeName : pathArray[ 1 ] } )
        .then( ( { club, vibeEvent } ) => {
          switch( true ) {
            case !club :
              console.log( 'myClub does not exist - sending to home.' );
              return setPath( '' );
            case !vibeEvent :
              return setPath( pathArray[ 0 ] );
            default :
              store.club = club;
              store.vibeEvent = vibeEvent;
              subscribeToDoc( docTypes.club, club.clubID );
              subscribeToDoc( docTypes.vibeEvent, vibeEvent.eventID );
              setTitleByPathOrString( '', `${club.name } • ${ vibeEvent.name }` );
          }

        } )
        .catch( err => {
          console.error( err )
        } );
      break;
    case pathArray.length === 1 :
      store.appMode = AppModes.CLUB;
      getClubAndEventByPath( {
        clubURLsafeName : pathArray[ 0 ],
        eventURLsafeName : '' } )
        .then( ( { club, vibeEvent } ) => {
          switch( true ) {
            case !club :
              console.log( 'myClub does not exist - sending to home.' );
              return setPath( '' );
            default :
              store.club = club;
              subscribeToDoc( docTypes.club, club.clubID );
          }

        } )
        .catch( err => {
          console.error( err )
        } );
      break;
    default :
      logger.warn( CN + '.pathChanged encountered an unknown case - setting appMode to HOME.' );
      store.appMode = AppModes.HOME;
  }
}

export function setTitleByPathOrString( path : string = '', suppliedTitle : string = '' ) : string {
  let title : string = suppliedTitle ? suppliedTitle : 'VibeWith.me';

  switch( true ) {
    case !!suppliedTitle || !!path :
      // title is already correct in both of these cases
      break;

  }

  store.appTitle = title;
  return title;
}
