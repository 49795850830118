import { ChatMsg } from '../../relay/p2pRelay';
import { useCallback, useRef, useState, useEffect } from 'react';
import ChatMessage from '../ChatMessage/ChatMessage';
import * as logger from '../../utils/logger';
import signals from 'signals';

import './StreamPanelOverlay.less';

interface props {
  isChatPanelDisplayed : boolean,
  msgSignal : signals.Signal,
  clr : string
}

const CN : string = 'StreamPanelOverlay';
const duration : number = 2000;

export default function StreamPanelOverlay( {
  isChatPanelDisplayed,
  msgSignal,
  clr
} : props ) {

  // refs
  const divRef = useRef( null as any );

  // hooks
  const [ chatMsg, setChatMsg ] = useState( null as ChatMsg | null );
  const [ localStore, setLocalStore ] = useState( {} as any );

  // callbacks
  const chatMsgReceived = useCallback( ( newChatMsg : ChatMsg ) => {
    logger.log( CN + '.chatMsgReceived' );

    if( !isChatPanelDisplayed ) {
      divRef.current.classList.add( clr, 'msg' );
      setChatMsg( newChatMsg );
      localStore.timeoutID && window.clearTimeout( localStore.timeoutID );
      localStore.timeoutID = window.setTimeout( () => {
        divRef.current.classList.remove( clr, 'msg' );
      }, duration );
    }
  }, [ isChatPanelDisplayed ] );

  useEffect( () => {
    msgSignal.add( chatMsgReceived );

    return cleanup;
  }, [] );


  return <div
    ref={ divRef }
    className={ `stream-panel-overlay${ isChatPanelDisplayed ? ` chat-panel-displayed ${ clr }` : '' }` }>
    { chatMsg &&
      <ChatMessage
        chatMsg={ chatMsg as ChatMsg }
      />
    }
  </div>;

  function cleanup() {
    msgSignal.remove( chatMsgReceived );
  }
}
