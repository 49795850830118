import { store } from '../data/store';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import * as logger from '../utils/logger';
import { isUserValid } from '../fbase/auth';
import { createCheckoutSession } from '../api/api';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  addDoc,
  collection,
  getFirestore,
  doc,
  getDoc,
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  onSnapshot,
  FirestoreError
} from 'firebase/firestore';

const CN : string = "ecomm";

const STRIPE_API_KEY : string = "pk_test_l8uI6sbjZGUkyNuhrP2ctjuC0020lDVNaI";
let stripe : Stripe;

export function start() {
  logger.log( CN + ".start" );

  loadStripe( STRIPE_API_KEY )
    .then( ( newStripeInstance ) => {
      store.isLoading = false;
      stripe = newStripeInstance as Stripe;
    } )
    .catch( ( err ) => {
      logger.error( err );
    } );
}

// TODO : we need to know who / what we are subscribing to
export async function purchaseSubscription() {
  logger.log( CN + ".purchaseSubscription" );

  const db = getFirestore( getApp() );

  store.isLoading = true;
  if( isUserValid() && stripe ) {
    try {
      const checkoutSessionsCollectionRef : CollectionReference = collection( db, 'stripe_customers', store.user.uid, 'checkout_sessions' );
      const docRef : DocumentReference = await addDoc(
        checkoutSessionsCollectionRef, {
          price : 'price_1HghvHKptTjekPceenUJNKQO',
          success_url : window.location.origin,
          cancel_url : window.location.origin
      } );

      onSnapshot( docRef, {
        next : ( docSnap : DocumentSnapshot ) => {
          const { error, sessionId } = docSnap.data() as any;
          error && logger.error( error );
          if( sessionId ) {
            stripe!.redirectToCheckout( { sessionId } );
            store.isLoading = false;
          }
        },
        error : ( err : FirestoreError ) => logger.error( err ),
        complete : () => logger.log( 'checkout session addition completed' )
      } );

    } catch( err ) {
      logger.error( err );
      store.isLoading = false;
    }
  } else {
    logger.warn( "either user is not valid, or we have no stripe" );
    logger.warn( "store.user :", store.user );
    logger.warn( "stripe :", stripe );
    store.isLoading = false;
  }
}

export function gotoCustomerPortal() {
  store.isLoading = true;
  const cloudFunctionName : string = 'ext-firestore-stripe-subscriptions-createPortalLink';
  const functions = getFunctions();
  const stripeCreatePortalLink = httpsCallable( functions, cloudFunctionName );

  stripeCreatePortalLink( {
    returnUrl : window.location.origin
  } )
    .then( ( result : any ) => {
      const url = result.data.url;
      store.isLoading = false;
      window.location.assign( url );
    } )
    .catch( ( err : any ) => {
      store.isLoading = false;
      logger.error( err );
    } );
}

// TODO : either rename to show subscription or something
export async function purchaseItemByPriceID( priceId : string, quantity : number ) {
  store.isLoading = true;
  try {
    let customerStripeId : string;
    const db = getFirestore( getApp() );
    const docRef = doc( db, 'stripe_customers', store.user.uid );
    const docSnap = await getDoc( docRef );

    if( docSnap.exists() ) {
      const docData = docSnap.data();
      customerStripeId = docData.stripId;
      const checkoutSession = await createCheckoutSession( {
        mode : 'subscription', // TODO : make a common enum in vibe for stripe purchase modes (or find stripe's)
        customerId : customerStripeId,
        priceId : priceId,
        quantity : quantity,
        successUrl : window.location.origin,
        cancelUrl : window.location.origin
      } );

      logger.log( 'checkoutSession :', JSON.stringify( checkoutSession, null, 2 ) );
      // You specified `payment` mode but passed a recurring price. Either switch to `subscription` mode or use only one-time prices.

      await stripe.redirectToCheckout( { sessionId : checkoutSession.id } );
      store.isLoading = false;
    }
  } catch( err : any ) {
    logger.error( err );
    store.isLoading = true;
  }

}
