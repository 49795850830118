import { Fragment, useState } from 'react';
import * as logger from '../../utils/logger';
import { Coord } from '../../vibe/vibe';
import { docTypes, unsubscribeFromDoc } from '../../fbase/fbase';
import { store } from '../../data/store';
import { polycastLeave } from '../../api/api';
import { dialogIDs, userConfirmations } from '../../data/consts';
import Menu from '../Menu/Menu';
import { blurDelayWrapper } from '../../utils/utils';

import './PolycastBar.less';
import { polycastAddFaux } from '../../relay/signalRelay';

const CN : string = 'PolycastBar';

interface props {
  isPolycastMuted : boolean,
  polycastDivRef : any,
  hasHostPrivileges : boolean
}

export default function PolycastBar( {
  isPolycastMuted,
  polycastDivRef,
  hasHostPrivileges = false
} : props ) {

  const [ isFullscreen, setIsFullscreen ] = useState( false );
  const [ isPeopleMenuDisplayed, setIsPeopleMenuDisplayed ] = useState( false );

  return <div className="bar-polycast">
    <button
      className={ `icon-leave` }
      onClick={() => {
        polycastLeave( {
          polycastID : store.polycast.polycastID,
          patronID : store.patron.patronID
        } )
          .then( msg => {
            window.location.reload();
          } )
          .catch( err => {
            logger.error( err );
          } )
        unsubscribeFromDoc( docTypes.polycast );
      }}
    />
    { !!store.flags.debug &&
      <button
        className={ `icon-cogwheel` }
        onClick={ () => store.dialogToDisplay = dialogIDs.dialogTableSettings }
      />
    }
    { !!store.flags.debug &&
    <Fragment>
      <button
        className={ 'icon-people' }
        onClick={ () => {
          setIsPeopleMenuDisplayed( !isPeopleMenuDisplayed )
        } }
        onBlur={ blurDelayWrapper( () => setIsPeopleMenuDisplayed( false ) )}
      />

      { isPeopleMenuDisplayed &&
      <Menu
        classNames={ 'menu-people' }
        menuItemInfos={ [
          {
            label : 'waitlist',
            icon : 'no-icon',
            onClick : () => {
              logger.log( 'waitlist clicked' );
              store.dialogToDisplay = dialogIDs.dialogTableWaitlist;
            }
          },
          {
            label : 'invite',
            icon : 'no-icon',
            onClick : () => {
              logger.log( 'invite clicked' );
              store.dialogToDisplay = dialogIDs.dialogTableInvite;
            }
          }
        ] }
      />
      }
      <button
        className={ 'icon-add-person' }
        onClick={ () => polycastAddFaux.dispatch() }
      />
    </Fragment>
    }
    { /* ----- left / right ----- */ }
    <button
      className={ isPolycastMuted ? 'icon-speaker off' : 'icon-speaker' }
      onClick={ () => store.isPolycastMuted = !store.isPolycastMuted }
    />
    <button
      className={ `icon-fullscreen${ isFullscreen ? ' off' : '' }` }
      onClick={ toggleFullScreen }
    />
  </div>;

  function receivedUserResponse( didConfirm : boolean ) {

  }

  function toggleFullScreen() : void {
    if( isFullscreen ) {
      window.document.exitFullscreen()
        .then( data => {
          logger.log( 'exited fullscreen' );
          setIsFullscreen( false );
        } )
        .catch( err => {
          logger.error( err );
        } )
    } else {
      polycastDivRef.current.requestFullscreen()
        .then( data => {
          logger.log( 'entered fullscreen' );
          setIsFullscreen( true );
        } )
        .catch( err => {
          logger.error( err );
        } )
    }
  }
}

