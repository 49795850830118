import { FocusEvent, MouseEvent, useEffect, useRef, useState } from 'react';

import * as logger from '../../utils/logger';

import './SendMenu.less';
import { fxIDs } from '../../data/consts';
import MenuFXhearts from './MenuFXhearts';
import { RelayPayload, RelayTypes, FXevent, useP2Prelay } from '../../relay/p2pRelay';
import { store } from '../../data/store';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

const CN : string = 'SendMenu';
let renderCount : number = 0;

interface props {
  setIsSendMenuDisplayed : any
  patronID? : string,
}

enum itemIDs {
  vibes = 'vibes',
  heart = 'heart',
  fire = 'fire',
  gift = 'gift',
  mojo = 'mojo',
  emote = 'emote',
  none = 'none'
}

enum hMenuIDs {
  fxHeartsRed = 'fxHeartsRed',
  fxHeartsYellow = 'fxHeartsYellow',
  fxHeartsGreen = 'fxHeartsGreen',
  fxHeartsPurple = 'fxHeartsPurple',
  none = '',
}

enum clrs {
  red = 'red',
  yellow = 'yellow',
  green = 'green',
  purple = 'purple',
  none = 'none'
}

let intervalID : number = -1;
const intervalDuration : number = 500;

export default function SendMenu( {
  setIsSendMenuDisplayed,
  patronID = ''
} : props ) {
  logger.log( `${ CN } rendering - ${ ++renderCount }` );

  // hooks
  const [ verticalMenuID, setVerticalMenuID ] = useState( itemIDs.none );
  const [ horizontalMenuID, setHorizontalMenuID ] = useState( hMenuIDs.none );
  const [ state, setState ] = useState( {
    fxID : fxIDs.heartCenter,
    clr : clrs.red
  } );
  const { relaySignals, relayPayload } = useP2Prelay( useAudioVideo() );

  // refs
  const menuRef = useRef( null as any );

  useEffect( () => {
    menuRef.current.focus();
    return cleanup;
  }, [] );

  return <div
    tabIndex={ 0 }
    ref={ menuRef }
    className="menu-send"
    onBlur={ blurred }
  >
    <div className={ `menu-send-item ${ itemIDs.vibes }` }/>
    <div
      className={ `menu-send-item ${ itemIDs.heart } ${ state.clr }` }
      data-item-id={ itemIDs.heart }
      data-heart-id={ state.clr }
      data-vertical-menu-id={ itemIDs.heart }
      onClick={ heartsClicked }
      onMouseDown={ mousePressed }
    >
      { verticalMenuID === itemIDs.heart && <div
        className={ 'menu-hearts' }
      >
        <div
          className={ `menu-send-item ${ itemIDs.heart }` }
          data-heart-id={ clrs.purple }
          data-horizontal-menu-id={ hMenuIDs.fxHeartsPurple }
          onMouseOver={ () => horizontalMenuID && setHorizontalMenuID( hMenuIDs.fxHeartsPurple ) }
        >
          { horizontalMenuID === hMenuIDs.fxHeartsPurple &&
            <MenuFXhearts clr={ clrs.purple } />
          }
        </div>
        <div
          className={ `menu-send-item ${ itemIDs.heart }` }
          data-heart-id={ clrs.green }
          data-horizontal-menu-id={ hMenuIDs.fxHeartsGreen }
          onMouseOver={ () => horizontalMenuID && setHorizontalMenuID( hMenuIDs.fxHeartsGreen ) }
        >
          { horizontalMenuID === hMenuIDs.fxHeartsGreen &&
            <MenuFXhearts clr={ clrs.green } />
          }
        </div>
        <div
          className={ `menu-send-item ${ itemIDs.heart }` }
          data-heart-id={ clrs.yellow }
          data-horizontal-menu-id={ hMenuIDs.fxHeartsYellow }
          onMouseOver={ () => horizontalMenuID && setHorizontalMenuID( hMenuIDs.fxHeartsYellow ) }
        >
          { horizontalMenuID === hMenuIDs.fxHeartsYellow &&
            <MenuFXhearts clr={ clrs.yellow } />
          }
        </div>
        <div
          className={ `menu-send-item ${ itemIDs.heart }` }
          data-heart-id={ clrs.red }
          data-horizontal-menu-id={ hMenuIDs.fxHeartsRed }
          onMouseOver={ () => horizontalMenuID && setHorizontalMenuID( hMenuIDs.fxHeartsRed ) }
        >
          { horizontalMenuID === hMenuIDs.fxHeartsRed &&
            <MenuFXhearts clr={ clrs.red } />
          }
        </div>
      </div>

      }
    </div>
    <div className={ `menu-send-item ${ itemIDs.fire }` }/>
    <div className={ `menu-send-item ${ itemIDs.gift }` }/>
    <div className={ `menu-send-item ${ itemIDs.mojo }` }/>
    <div className={ `menu-send-item ${ itemIDs.emote }` }/>
  </div>;

  function blurred( e : FocusEvent<HTMLDivElement> ) : void {
    const menu : HTMLDivElement = menuRef.current as HTMLDivElement;

    switch( true as boolean ) {
      case !menu :
      case e.nativeEvent.relatedTarget === menu || menu.contains( e.nativeEvent.relatedTarget as Node ) :
      case ( e.nativeEvent.relatedTarget as HTMLElement )?.classList.contains( 'icon-heart' ) &&
          menuRef.current.parentNode.contains( e.nativeEvent.relatedTarget ) :
        return;
      default :
        setIsSendMenuDisplayed( false );
    }
  }

  function resetInterval() : void {
    window.clearInterval( intervalID );
    intervalID = -1;
  }

  function mousePressed( e : MouseEvent<HTMLDivElement> ) : void {
    resetInterval();
    intervalID = window.setInterval( () => mousePressedAndHeld( e ), intervalDuration );
  }

  function mousePressedAndHeld( e : MouseEvent<HTMLDivElement> ) : any {
    const btn : HTMLDivElement | null = e.target as HTMLDivElement | null;
    if( !btn ) return;

    const hMenuID : hMenuIDs = btn.getAttribute( 'data-horizontal-menu-id' ) as hMenuIDs;
    const vMenuID : itemIDs = btn.getAttribute( 'data-vertical-menu-id' ) as itemIDs;

    hMenuID && setHorizontalMenuID( hMenuID );
    vMenuID && setVerticalMenuID( vMenuID );

    resetInterval();
  }

  function heartsClicked( e : MouseEvent<HTMLDivElement> ) : void {
    console.log( CN + '.heartsClicked' );
    if( shouldHandleClick() ){
      const clicked : HTMLDivElement | null = e.target as HTMLDivElement | null;

      if( clicked ) {
        const fxID : fxIDs = clicked.getAttribute( 'data-fx-id' ) as fxIDs || state.fxID;
        if( fxID !== state.fxID ) state.fxID = fxID;

        const heartID : clrs = clicked.getAttribute( 'data-heart-id' ) as clrs || state.clr;
        if( heartID !== state.clr ) state.clr = heartID;

        console.log( 'heartID :', heartID );
        console.log( 'fxID :', fxID );

        const payload : RelayPayload = {
          type : RelayTypes.FXevent,
          data : {
            to : [ patronID ],
            from : store.patron.patronID,
            fxID : fxID,
            filter : heartID
          } as FXevent
        };

        relayPayload( payload );

      } else {
        logger.warn( 'no clicked element - not sending FXevent...' );
      }
      setIsSendMenuDisplayed( false );
    }
    resetInterval();
  }

  function shouldHandleClick() : boolean {
    return intervalID !== -1;
  }

  function cleanup() {
    logger.log( CN + '.cleanup' );

    // polycastMuted.remove( muteFromSignal );
    // polycastUnmuted.remove( unmuteFromSignal );
  }
}

