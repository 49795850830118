import { Fragment, useRef } from 'react';
import { store } from '../../data/store';
import * as logger from '../../utils/logger';
import * as route from '../../routes/routes';
import { displaySignIn, signOutOfVibe } from '../../fbase/auth';
import * as utils from '../../utils/utils';
import { ReservedClubNames, AppModes, dialogIDs } from '../../data/consts';
import { purchaseItemByPriceID, purchaseSubscription } from '../../ecomm/ecomm';
import { test } from '../../api/api';
import { Patron } from '../../vibe/vibe';

import './UserMenu.less';

interface props {
  patron : Patron
}

export default function UserMenu( { patron } : props ) {

  const menuRef = useRef( null as any );

  function blurred( e ) {
    const menu : HTMLDivElement = menuRef.current as HTMLDivElement;

    switch( true as boolean ) {
      case !menu :
      case e.nativeEvent.relatedTarget === menu || menu.contains( e.nativeEvent.relatedTarget ) :
        return;
      default :
        utils.dismiss( document.querySelector( '.user-menu' ) as HTMLDivElement );
    }
  }

  return (
    <div
      ref={ menuRef }
      className="menu-user"
      tabIndex={0}
      onBlur={ blurred }>
      <button
        className="btn btn-user"
        onClick={ () => {
          const menu : HTMLDivElement = document.querySelector( '.user-menu' ) as HTMLDivElement;
          menu.classList.toggle( 'displayed' );
        } }>
        <img
          src={ `img/alphatars/avatar_${ ( patron && patron.name ) ? patron.name[ 0 ].toLowerCase() : '' }.svg` }
          alt=""/>
      </button>
      <div
        className="user-menu"
        onClick={ e => utils.dismiss( document.querySelector( '.user-menu' ) as HTMLElement ) }
      >
        { store.user !== null && (
          <div className="items-signed-in">
            <div className="username">{ ( patron && patron.name ) ? patron.name : '' }</div>
            <div className="hr"/>
            <div
              className="item-menu icon-account"
              tabIndex={0}
              onClick={ () => route.setPath( ReservedClubNames.settings ) }
            >Account Settings
            </div>
            <div
              className="item-menu icon-info"
              tabIndex={0}
              onClick={ () => store.dialogToDisplay = dialogIDs.dialogOrientation }
            >Orientation
            </div>
            { store.flags.debug === '1' &&
              <Fragment>
                <div className="hr"/>
                <div
                  className="item-menu"
                  tabIndex={0}
                  onClick={ () => {
                    test( store.testData )
                      .then( result => {
                        console.log( JSON.stringify( result, null, 2 ) );
                      } )
                      .catch( err => console.error( err ) );
                  } }
                >TEST
                </div>
                <div
                  className="item-menu"
                  tabIndex={0}
                  onClick={ () => route.setPath( 'deserthearts/black' ) }
                >Desert Hearts
                </div>
                <div
                  className="item-menu"
                  tabIndex={0}
                  onClick={ () => route.setPath( 'dj/gbus' ) }
                >DJ Gbus
                </div>
                <div
                  className="item-menu icon-bar-graph"
                  tabIndex={0}
                  onClick={ () => {
                    window[ 'store' ] = store;
                    logger.log( store );
                  } }
                >log store
                </div>
                <div
                  className="item-menu"
                  tabIndex={0}
                  onClick={ () => store.dialogToDisplay = dialogIDs.dialogCreateTable }
                >Create Table
                </div>
                <div
                  className="item-menu icon-star"
                  tabIndex={0}
                  onClick={ () => store.dialogToDisplay = dialogIDs.dialogCreateNewStar }
                >Create New Star
                </div>
                <div
                  className="item-menu icon-equalizer"
                  tabIndex={0}
                  onClick={ () => store.dialogToDisplay = dialogIDs.dialogCreateEvent }
                >Create New Event
                </div>
                <div
                  className="item-menu"
                  tabIndex={0}
                  onClick={ () => store.dialogToDisplay = dialogIDs.dialogAddPayment }
                >Add Payment Method
                </div>
                  <div
                    className="item-menu"
                    tabIndex={0}
                    onClick={ () => store.dialogToDisplay = dialogIDs.dialogSignIn }
                  >Test Sign In
                </div>
                <div
                  className="item-menu"
                  tabIndex={0}
                  onClick={ () => purchaseItemByPriceID('price_1HghvHKptTjekPceenUJNKQO', 2) }
                >Test Checkout
                </div>
                <div
                  className="item-menu"
                  tabIndex={0}
                  onClick={ purchaseSubscription }>
                  Purchase Subscription
                </div>
              </Fragment>
            }
            <div className="hr"/>
            <div
              className="item-menu icon-signout"
              tabIndex={0}
              onClick={ () => signOutOfVibe() }
            >Sign Out
            </div>
          </div>
        ) }
        { store.user === null && (
          <div className="items-signed-out">
            <div
              className="item-menu icon-signout"
              tabIndex={0}
              onClick={ () => displaySignIn() }
            >Sign In
            </div>
          </div>
      ) }
      </div>
    </div>
  );
}
