import * as logger from '../../utils/logger';
import { useState } from 'react';
import { VibeEvent, Profile } from '../../vibe/vibe';
import { getFile } from "../../fbase/fbaseUtils";

import './EventViewPanel.less';

// TODO : move all hard coded UX strings to loc.stringTable
//import Calendar from 'react-calendar'

interface props {
    vibeEvent : VibeEvent
}

const CN : string = 'EventViewPanel';

export default function EventViewPanel( { vibeEvent } : props ) {
    // hooks
    const [ coverImg, setCoverImg ] = useState( '' );
    const [ profileImg, setProfileImg ] = useState( '' );

    vibeEvent.profile = vibeEvent.profile || {};

    vibeEvent.profile.coverImg && getFile( vibeEvent.profile.coverImg )
      .then( url => {
          setCoverImg( url );
      } )
      .catch( err => logger.error( err ) );

    vibeEvent.profile.profileImg && getFile( vibeEvent.profile.profileImg )
      .then( url => {
          setProfileImg( url );
      } )
      .catch( err => logger.error( err ) );


    return <div className='panel-club-view'>
      <div className="imgs">
        <div
          className='cover'
          style={ { backgroundImage: coverImg ? `url(${coverImg})` : '' } }/>
        <div
          className='profile'
          style={{backgroundImage: profileImg ? `url(${profileImg})` : ''}}/>
      </div>
      <h2>{ vibeEvent.name || '' }</h2>
      <h3>{ ( vibeEvent.profile as Profile ).tagLine || '' }</h3>
      <h4>{ ( vibeEvent.profile as Profile ).blurb || '' }</h4>
      <div className="tags">
          { ( vibeEvent.tags || [] ).map( tag => <div className="tag">{ tag.trim() }</div> ) }
      </div>
    </div>;
}
