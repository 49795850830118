import * as logger from '../../utils/logger';
import { useState } from 'react';
import { Club, Profile, LinkURLs } from '../../vibe/vibe';
import { getFile } from "../../fbase/fbaseUtils";


import './ClubViewPanel.less';
import SocialBar, { socialBarTypes } from '../SocialBar/SocialBar';

// TODO : move all hard coded UX strings to loc.stringTable
//import Calendar from 'react-calendar'

enum fields {
    coverImg   = 'coverImg',
    profileImg = 'profileImg',
    name       = 'name',
    tagLine    = 'tagLine',
    blurb      = 'blurb',
    tags       = 'tags'
}

interface props {
    club : Club
}

const CN : string = 'ClubViewPanel';

export default function ClubViewPanel( { club } : props ) {
  console.log( CN + '.ClubViewPanel' );

    // hooks
    const [ coverImg, setCoverImg ] = useState( '' );
    const [ profileImg, setProfileImg ] = useState( '' );

    club.profile = club.profile || {};

    club.profile.coverImg && getFile( club.profile.coverImg )
      .then( url => {
          setCoverImg( url );
      } )
      .catch( err => logger.error( err ) );

    club.profile.profileImg && getFile( club.profile.profileImg )
      .then( url => {
          setProfileImg( url );
      } )
      .catch( err => logger.error( err ) );


    return <div className='panel-club-view'>
      <div className="imgs">
        <div
          className='cover'
          style={ { backgroundImage: coverImg ? `url(${coverImg})` : '' } }/>
        <div
          className='profile'
          style={{backgroundImage: profileImg ? `url(${profileImg})` : ''}}/>
      </div>
      <h2>{ club.name || '' }</h2>
      <h3>{ ( club.profile as Profile ).tagLine || '' }</h3>
      <h4>{ ( club.profile as Profile ).blurb || '' }</h4>
      <SocialBar
        providedLinkURLs={ club.profile.linkURLs as LinkURLs }
        isEditing={ false }
        type={ socialBarTypes.club }
      />
      <div className="tags">
          { ( club.tags || [] ).map( tag => <div className="tag">{ tag.trim() }</div> ) }
      </div>
    </div>;
}
