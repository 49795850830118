import { store } from '../../data/store';
import DialogHeader from '../DialogHeader/DialogHeader';

import './DialogTableWaitlist.less';
import * as logger from '../../utils/logger';
import Carousel from '../Carousel/Carousel';
import { useRef } from 'react';
import PatronProfilePanel from '../PatronProfilePanel/PatronProfilePanel';

const CN : string = 'DialogTableWaitlist';

export default function DialogTableWaitlist() {

  const formRef = useRef( null as any );

  return (
    <div ref={ formRef } className="dialog-table-waitlist">
      <DialogHeader onCloseClick={ () => store.dialogToDisplay = '' }/>
      <div className="body">
        <Carousel
          tileElements={ getTiles() }
          onFirstItem={ () => logger.log( 'onFirstItem' ) }
          onLastItem={ () => logger.log( 'onLastItem' ) }
        />
      </div>
      <div className="footer">
        <button
          className="btn-block"
          onClick={ block }
        >block</button>
        <button
          className="btn-invite"
          onClick={ invite }
        >invite</button>
      </div>
    </div>
  );

  function block( e ) {
    logger.log( CN + '.block' );
    e.preventDefault();
  }

  function invite( e ) {
    logger.log( CN + '.invite' );
    e.preventDefault();
  }

  function getTiles() : any[] {
    const tiles : any[] = [];

    for( let i = 0; i < 10; i++ ) {
      tiles.push( <PatronProfilePanel key={i} patron={ store.patron } />
      /*
      <div>
        <h3>Test Tile</h3>
        <h2>{ i }</h2>
        <p>This is a test tile with interactive elements to test interaction.</p>
        <a href={'https://google.com'}>click here to visit Google</a><br/>
        <button
          className={'btn'}
          onClick={()=>{ console.log( 'clicked the tile\'s button')}}
        >click me</button>
      </div>
      */
    );
}

// <ClubProfilePanel key={i} patron={store.patron}/>
return tiles;
}
}

