export interface VideoEvent { type : string, msg : string }

export const videoEvents : VideoEvent[] = [
  {
    type : 'AD_CUE',
    msg : 'PlayerAdCue - look it up'
  },
  {
    type : 'ANALYTICS_EVENT',
    msg : 'PlayerAnalyticsEvent - look it up'
  },
  {
    type : 'AUDIO_BLOCKED',
    msg : 'Indicates that the browser blocked unmuted playback. This behavior is based on the user\'s policy and can occur if the play request was made without a user gesture. To start playback, mute and play or wait for a user gesture.'
  },
  {
    type : 'BUFFER_UPDATE',
    msg : 'Indicates that the browser blocked unmuted playback. This behavior is based on the user\'s policy and can occur if the play request was made without a user gesture. To start playback, mute and play or wait for a user gesture.'
  },
  {
    type : 'DURATION_CHANGED',
    msg : 'Indicates that source duration changed (or is available for the first time). This can occur after the PlayerState.READY state, to indicate a change in the duration of the media. This value can be set to Infinity or a large integer (1 << 30) for streams of unknown or indefinite length.'
  },
  {
    type : 'ERROR',
    msg : 'Indicates that an error occurred. Errors are fatal and stop playback of the stream. The player moves into PlayerState.IDLE on fatal errors.'
  },
  {
    type : 'INITIALIZED',
    msg : 'Indicates that the player was created.'
  },
  {
    type : 'METADATA',
    msg : 'look it up'
  },
  {
    type : 'MUTED_CHANGED',
    msg : 'Indicates that the player was muted or unmuted.'
  },
  {
    type : 'NETWORK_UNAVAILABLE',
    msg : 'Indicates that a playback unavailable event occurred.'
  },
  {
    type : 'PLAYBACK_BLOCKED',
    msg : 'Indicates that playback is blocked from autoplaying; that is, without a user gesture. This event can fire when autoplay with sound is blocked or all autoplay is blocked. To start playback, mute and play, or wait for a user gesture.'
  },
  {
    type : 'PLAYBACK_RATE_CHANGED',
    msg : 'Indicates that the playback rate changed.'
  },
  {
    type : 'QUALITY_CHANGED',
    msg : 'Indicates that the playing quality changed from either a user action or an internal adaptive-quality switch.'
  },
  {
    type : 'REBUFFERING',
    msg : 'Indicates that the player is buffering from a previous PlayerState.PLAYING state. Excludes user actions such as seeking, starting, or resuming the stream.'
  },
  {
    type : 'RECOVERABLE_ERROR',
    msg : 'look it up'
  },
  {
    type : 'SEEK_COMPLETED',
    msg : 'Indicates that the player seeked to a given position (as requested by Player.seekTo).'
  },
  {
    type : 'SEGMENT_METADATA',
    msg : 'look it up'
  },
  {
    type : 'SESSION_DATA',
    msg : 'look it up'
  },
  {
    type : 'STATE_CHANGED',
    msg : 'look it up'
  },
  {
    type : 'STREAM_SOURCE_CUE',
    msg : 'look it up'
  },
  {
    type : 'TEXT_CUE',
    msg : 'Indicates that a text cue was parsed from the stream.'
  },
  {
    type : 'TEXT_METADATA_CUE',
    msg : 'Indicates that text metadata cues were parsed from the stream.'
  },
  {
    type : 'TIME_UPDATE',
    msg : 'Indicates that the player position changed.'
  },
  {
    type : 'VOLUME_CHANGED',
    msg : 'Indicates that the player volume changed. Volume value can be between 0 and 1, inclusive.'
  },
  {
    type : 'WORKER_ERROR',
    msg : 'look it up'
  }
];
