export const levels = {
  LOG : 1,
  DEBUG : 2,
  INFO : 3,
  WARN : 4,
  ERROR : 5,
  OFF : 100
};

let level : number = levels.LOG;

const placebo = ( msg : any, val : any = {} ) => {
  // console.log( 'placebo called.  current level : ' + level );
};

export let error = placebo;
export let warn = placebo;
export let info = placebo;
export let debug = placebo;
export let log = placebo;

export const tracer = ( msg : string, clr : string = 'green' ) => {
  console.log( `%c[vibe] ${ msg }`, `color : ${ clr }; font-weight : bold;` );
}

function logFactory( levelKey ) {
  return level > levels[ levelKey ] ? placebo : boundLogger( levelKey );
}

function boundLogger( logLevel ) {
  return window.console[ logLevel.toLowerCase() ].bind( window.console, '[vibe]' );
}

export function setLevel( newLevel ) {
  level = newLevel;
  init();
}

function init() : any {
  error = logFactory( 'ERROR' );
  warn = logFactory( 'WARN' );
  info = logFactory( 'INFO' );
  debug = logFactory( 'DEBUG' );
  log = logFactory( 'LOG' );
}

setLevel( levels.LOG );
