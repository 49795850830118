import { noOp } from '../../utils/utils';
import { FocusEvent, KeyboardEvent } from 'react';

import './Input.less';

export enum autoCaps {
  off = 'off',                    // No autocapitalization is applied (all letters default to lowercase)
  none = 'none',                  // No autocapitalization is applied (all letters default to lowercase)
  on = 'on',                      // The first letter of each sentence defaults to a capital letter; all other letters default to lowercase
  sentences = 'sentences',        // The first letter of each sentence defaults to a capital letter; all other letters default to lowercase
  words = 'words',                // The first letter of each word defaults to a capital letter; all other letters default to lowercase
  characters = 'characters'       // All letters should default to uppercase
}

// NOTE : there are other autoComplete values and we can add them in as we need them
export enum autoCompletion {
  off= 'off',
  on= 'on',
  name= 'name',
  email= 'email',
  username= 'username',
  newPassword = 'new-password',
  currentPassword = 'current-password',
  tel = 'tel',

}

export enum autoCorrection {
  on = 'on',
  off = 'off'
}

export enum InputTypes {
  text    = 'text',
  date    = 'date',
  time    = 'time',
  number  = 'number'
}

interface props {
  fieldName : string,
  autoCapitalize? : autoCaps,
  autoComplete? : autoCompletion,
  autoCorrect? : autoCorrection,
  classNames? : string,
  defaultValue? : any
  errorMsgs? : any,
  id? : any,
  isDisplayed? : boolean,
  isRequired? : boolean,
  maxLength? : number,
  onBlur? : { ( e : FocusEvent<HTMLInputElement> ) },
  onChange? : { ( e : FocusEvent<HTMLInputElement> ) },
  onKeyDown? : { ( e : KeyboardEvent<HTMLInputElement> ) },
  placeholder? : string,
  snapshot? : any,
  spellCheck? : boolean,
  tabIndex? : number,
  type? : InputTypes,
  min? : number,
  max? : number,
  step? : number
}

export default function Input( {
  fieldName = '',
  autoCapitalize = autoCaps.none,
  autoComplete = autoCompletion.off,
  autoCorrect = autoCorrection.off,
  classNames = '',
  defaultValue = undefined,
  errorMsgs = {},
  id = null,
  isDisplayed = true,
  isRequired = false,
  maxLength = 50,
  onBlur = noOp,
  onChange = noOp,
  onKeyDown = noOp,
  placeholder = '',
  snapshot = {},
  spellCheck = false,
  tabIndex = 0,
  type = InputTypes.text,
  min = 0,
  max = 0,
  step = 1
} : props ) {

  return <div className={ `input ${type} ${ classNames } ${ isDisplayed ? 'displayed' : '' }`.trim() }>
    { isRequired &&
      <p className={`asterisk${ errorMsgs[ fieldName ] ? ' red' : '' }`}/>
    }
    <input
      data-prop={ fieldName }
      id={ id }
      autoCapitalize={ autoCapitalize }
      autoComplete={ autoComplete }
      autoCorrect={ autoCorrect }
      className={ `input-${ fieldName } ${ classNames }${ errorMsgs[ fieldName ] ? ' error' : '' }`.trim() }
      defaultValue={ defaultValue !== undefined ? defaultValue : snapshot ? snapshot[ fieldName ] : '' }
      maxLength={ maxLength }
      onBlur={ onBlur }
      onChange={ onChange }
      onKeyDown={ onKeyDown }
      placeholder={ placeholder }
      required={ isRequired }
      spellCheck={ spellCheck }
      tabIndex={ tabIndex }
      type={ type }
      min={ min }
      max={ max }
      step={ step }
    />
    { onBlur !== noOp &&
      <p className='error-msg'>{ errorMsgs[ fieldName ] }</p>
    }
  </div>
}
