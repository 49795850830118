import { store } from '../../data/store'
import { useCallback, useState } from 'react';
import { SettingsPanelModes } from '../../data/consts'

import SettingsTabs from '../SettingsTabs/SettingsTabs'
import PatronProfilePanel from '../PatronProfilePanel/PatronProfilePanel'
import AccountPanel from '../AccountPanel/AccountPanel'
import ClubProfilePanel from '../ClubProfilePanel/ClubProfilePanel'
import StarProfilePanel from '../StarProfilePanel/StarProfilePanel'

import './SettingsPanel.less'
import { Patron } from '../../vibe/vibe';

interface props {
  patron : Patron
}

export default function SettingsPanel( {
  patron
} : props ) {
  // hooks
  const [ settingsPanelMode, setSettingsPanelMode ] = useState( store.settingsPanelMode );

  // callbacks
  const settingsPanelModeUpdated = useCallback( newMode => setSettingsPanelMode( newMode ), [] );

  // signals remove
  store.settingsPanelModeUpdated.remove( settingsPanelModeUpdated );

  // signals add
  store.settingsPanelModeUpdated.add( settingsPanelModeUpdated );

  return (
    <div className="panel-settings">
      <h1>Settings</h1>
      <SettingsTabs settingsPanelMode={ settingsPanelMode }/>
      { settingsPanelMode === SettingsPanelModes.PROFILE && (
        <PatronProfilePanel patron={ patron }/>
      ) }
      { settingsPanelMode === SettingsPanelModes.ACCOUNT && (
        <AccountPanel patron={ patron }/>
      ) }
      { settingsPanelMode === SettingsPanelModes.CLUB && (
        <ClubProfilePanel patron={ patron }/>
      ) }
      { settingsPanelMode === SettingsPanelModes.STAR && (
        <StarProfilePanel/>
      ) }
    </div>
  );
}

