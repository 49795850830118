import * as logger from '../utils/logger';
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject
} from "firebase/storage";

const CN : string = 'fbaseUtils';

export interface FirebaseUser {
  displayName? : string,
  email? : string,
  emailVerified? : boolean,
  photoURL? : string,
  isAnonymous? : boolean,
  uid? : string,
  providerData? : {
    providerId? : string,
    uid? : string,
    displayName? : string,
    email? : string,
    photoURL? : string
  }
}

/*
export function updateBasicProfile( user, userInfo : FirebaseUser ) {
  user.updateProfile( {
    displayName : userInfo.displayName,
    photoURL : userInfo.photoURL
  } )
    .then( ( data ) => {
      logger.log( 'successfully updated user profile :', userInfo );
      logger.log( 'data :', data );
    } )
    .catch( ( err ) => {
      logger.log( 'there was an error updating the user profile : ', err );
    } );
}

export function updateUserEmail( user, userInfo : FirebaseUser ) {
  user.updateEmail( userInfo.email )
    .then( ( data ) => {
      logger.log( 'successfully updated user\'s email :', userInfo );
      logger.log( 'data :', data );
    } )
    .catch( ( err ) => {
      logger.log( 'there was an error updating the user profile : ', err );
    } );
}
 */

const cachedFiles = {};

export function getFile( path : string ) : Promise<string> {
  const storage = getStorage();

  if( cachedFiles[ path ] ) {
    return new Promise( ( resolve, reject ) => resolve( cachedFiles[ path ] ) );
  }

  return new Promise( ( resolve, reject ) => {
    getDownloadURL( ref( storage, path ) )
      .then( url => {
        cachedFiles[ path ] = url;
        resolve( url );
      } )
      .catch( err => {
        reject( err );
      } );
  } );
}

export function uploadFile( file : File, path : string ) : Promise<any> {
  const storage = getStorage();
  const storageRef = ref( storage, path );

  return uploadBytes( storageRef, file )
    .then( snapshot => {
      logger.log( `uploaded file to ${ path }`);
    } )
    .catch( err => logger.error( err ) );
}

export function deleteFile( path : string ) : Promise<any> {

  const storage = getStorage();
  const storageRef = ref( storage, path );

  return deleteObject( storageRef )
    .then( () => logger.log( `file deleted ${ path }` ) )
    .catch( err => logger.error( err ) );
}

