import { vibetron } from '../vibe/vibe';
import { getRandBetween } from './utils';
// import * as logger from '../utils/logger';

const trapped = {};

const saved = {};

const clrsOfTheRainbow = [
  'blue',
  'indigo',
  'violet',
  'red',
  'orange',
  'yellow',
  'green'
];

function getNextColor( key : string, nameSpace : string = 'StreamPanel' ) : string {
  if( !trapped[ nameSpace ] || !trapped[ nameSpace ].length ) {
    trapped[ nameSpace ] = [ ...clrsOfTheRainbow ];
  }
  const index : number = getRandBetween( 0, trapped[ nameSpace ].length - 1 );
  const clr : string = trapped[ nameSpace ][ index ];
  if( !saved[ nameSpace ] ) saved[ nameSpace ] = {};
  saved[ nameSpace ][ key ] = clr;
  trapped[ nameSpace ] = trapped[ nameSpace ].filter( color => clr !== color );
  return clr;
}

export function getColorByKey( key : string, nameSpace : string = 'StreamPanel'  ) : string {
  if( key === vibetron ) return '';

  if( saved[ nameSpace ] && saved[ nameSpace ][ key ] ) {
    return saved[ nameSpace ][ key ];
  } else {
    return getNextColor( key, nameSpace );
  }
}
