import * as logger from '../../utils/logger';
import SocialBar, { socialBarTypes } from '../SocialBar/SocialBar';

import './StarPanel.less';
import { store } from '../../data/store';

export default function StarPanel( { user } ) {
  logger.log( 'StarPanel received user : ', user );
  return (
    <div className="panel-star">
      <h1>DJ Panel</h1>
      <SocialBar
        type={ socialBarTypes.star }
        isEditing={ false }
      />
    </div>
  );
}

