
import { useState } from 'react';

import './InputTags.less';
import { store } from '../../data/store';
import signals from 'signals';
import InputText from '../InputText/InputText';

interface props {
  label? : string,
  tags? : string[],
  limit? : number,
  isLowercase? : boolean,
  onTagsChanged : Function
}

// const CN : string = 'InputTags';

export default function InputTags( {
  label = 'tags',
  tags = [],
  limit = 5,
  isLowercase = true,
  onTagsChanged
} : props) {

  const [ displayTags, setDisplayTags ] = useState( getDisplayTags( tags ) );
  const [ hasValue, setHasValue ] = useState( false )

  function saveTags( newTags ) : void {
    let tagsToSave : string[] = [];
    newTags.find( ( tag, i ) => {
      tagsToSave.push( tag );
      if( i === limit - 1 ) return true;
    } );

    if( tagsToSave.length === 1 && !tagsToSave[ 0 ] ) tagsToSave = [];

    onTagsChanged( tagsToSave );
    setDisplayTags( getDisplayTags( tagsToSave ) );
  }

  return <div className={`input-tags`}>
    <InputText
      defaultValue={ tags.join( ', ' ) }
      fieldName={ label }
      onChange={ tagsChanged }
      label={ label }
    />
    <p className={ `blurb${ hasValue ? '' : ' displayed' }` }>{ `comma separated${ limit > 0 ? `, max of ${limit}` : '' }`}</p>
    <div className='tag-labels'>
      {
        displayTags.map( tag => <div
          className={'tag-label'}
          onClick={ () => deleteTag( tag ) }
          tabIndex={0}
          >{ tag }
            <div className={'tag-delete'} />
          </div> )
      }
    </div>
  </div>;

  function getDisplayTags( tagArray : string[] ) : string[] {
    return tagArray.map( item => ` ${ item.trim() }` );
  }

  function tagsChanged( e : any ) : void {
    setHasValue( !!e.target.value );
    const tagArray = isLowercase ? ( e.target.value.toLowerCase() ).split( ',' ) : e.target.value.split( ',' );
    saveTags( tagArray.map( tag => tag.trim() ) );
  }

  function deleteTag( tagToDelete ) : void {
    saveTags( displayTags.filter( tag => tag !== tagToDelete ) );
  }
}

