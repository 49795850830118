import { fxIDs } from '../../data/consts';
import { MimeTypes } from '../../vibe/vibe';

interface props {
  clr : any
}

export default function MenuFXhearts( {
  clr
} : props ) {
  return <div className="menu-fx">
    <div
      className={ `menu-send-item fx` }
      data-fx-id={ fxIDs.heartCenter }
      data-heart-id={ clr }
    >
      <object
        className={ `svg-in-object ${ clr }` }
        type={ MimeTypes.svg }
        data={ `${ window.location.protocol }//${ window.location.host }/img/fx_heart_grow_fade.svg` }
      />
    </div>
    <div
      className={ `menu-send-item fx` }
      data-fx-id={ fxIDs.heartRain }
      data-heart-id={ clr }
    >
      <object
        className={ `svg-in-object ${ clr }` }
        type={ MimeTypes.svg }
        data={ `${ window.location.protocol }//${ window.location.host }/img/fx_heart_rain.svg` }
      />
    </div>
    <div
      className={ `menu-send-item fx` }
      data-fx-id={ fxIDs.heartBeating }
      data-heart-id={ clr }
    >
      <object
        className={ `svg-in-object ${ clr }` }
        type={ MimeTypes.svg }
        data={ `${ window.location.protocol }//${ window.location.host }/img/fx_heart_beating.svg` }
      />
    </div>
  </div>;
}
