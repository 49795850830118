import DialogHeader from '../DialogHeader/DialogHeader';
import ButtonVibe from '../ButtonVibe/ButtonVibe';
import InputText from '../InputText/InputText';
import { useState, MouseEvent } from 'react';

import './DialogCreateNewStar.less';


const CN : string = "DialogCreateNewStar";

enum fields {
  accountID = 'accountID',
  name = 'name',
  starID = 'starID',
  urlSafeName = 'urlSafeName'
}

export default function DialogCreateNewStar() {

  const [ state, setState ] = useState( {
    accountID : '',
    name : '',
    starID : '',
    urlSafeName : ''
  } );

  return <div className="dialog-create-star">
    <DialogHeader title={`Create a New Star`}/>
    <div className="body">
      <InputText
        fieldName={ fields.accountID }
        onChange={ e => state.accountID = e.target.value }
        label={ fields.accountID }
      />
      <InputText
        fieldName={ fields.name }
        onChange={ e => state.name = e.target.value }
        label={ fields.name }
      />
      <InputText
        fieldName={ fields.urlSafeName }
        onChange={ e => state.urlSafeName = e.target.value }
        label={ fields.urlSafeName }
      />
    </div>
    <div className="footer">
      <ButtonVibe
        label={ 'Create Star' }
        onClick={ createButtonClicked }/>
    </div>
  </div>

  function createButtonClicked( e : MouseEvent<HTMLButtonElement> ) {
    console.log( CN + ".createButtonClicked" );

    e.preventDefault();
    console.log( 'state :', JSON.stringify( state, null, 2 ) );
  }

}
