import React from 'react';
import {
  createMuiTheme,
  createStyles,
  withStyles,
  makeStyles,
  Theme,
  ThemeProvider
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, purple } from '@material-ui/core/colors';
import { noOp } from '../../utils/utils';

import './ButtonVibe.less';

interface props{
  label : string,
  onClick : any,
  classNames? : string,
  isDisabled? : boolean
}

export default function ButtonVibe( {
  isDisabled = false,
  label = 'label',
  classNames = '',
  onClick = noOp
} : props ) {

  const VibeButton = withStyles( ( theme : Theme ) => ( {
    root : {
      color : theme.palette.getContrastText( purple[ 500 ] ),
      backgroundColor : purple[ 500 ],
      '&:hover' : {
        backgroundColor : purple[ 700 ]
      }
    }
  } ) )( Button );

  return <VibeButton
      variant="contained"
      color="primary"
      className={ `btn-vibe${ classNames ? ' ' + classNames : '' }` }
      disabled={ isDisabled }
      onClick={ onClick }
      tabIndex={ 0 }
    >
    { label }
  </VibeButton>
}
