import { useState, Fragment, ChangeEvent, FocusEvent } from "react";
import { store } from '../../data/store';
import * as logger from '../../utils/logger';
import { resetPassword, signInWithEmail, signInBtnID, createNewUser } from '../../fbase/auth';

import ButtonVibe from "../ButtonVibe/ButtonVibe";
import InputText, { InputTextTypes, AutoCompletes } from "../InputText/InputText";
import DialogHeader from "../DialogHeader/DialogHeader";

import "./DialogSignIn.less";
import { UserRegData } from '../../vibe/vibe';
import { confirmInviteCode, addToMailingList } from '../../api/api';
import { blurDelayWrapper } from '../../utils/utils';

/*
 * TODO : add reCaptcha to deter bots from repeat confirm inviteCode calls
 */

//region consts & enums
const CN : string = 'DialogSignIn';

enum fields {
  inviteCode = 'inviteCode',
  userName = 'userName',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  email = 'email',
  password = 'password',
  passwordNew = 'passwordNew',
  passwordVerify = 'passwordVerify'
}

enum modes {
  login = 'login',
  inviteCode = 'inviteCode',
  register = 'register',
  emailList = 'emailList'
}

const btnLabels = {
  [ modes.login ] : 'Sign In',
  [ modes.inviteCode ] : 'Redeem Code',
  [ modes.register ] : 'Register',
  [ modes.emailList ] : 'Join Email List',
  noInviteCode : 'request invite code',
  forgotPassword : 'forgot password'
};

const inputLabels = {
  [ fields.inviteCode ] : 'invite code',
  [ fields.userName ] : 'user name',
  [ fields.firstName ] : 'first name',
  [ fields.lastName ] : 'last name',
  [ fields.phone ] : 'phone number',
  [ fields.email ] : 'email',
  [ fields.password ] : 'password',
  [ fields.passwordNew ] : 'new password',
  [ fields.passwordVerify ] : 'verify password'
};

// TODO : these need to be loaded from loc
const errorMsgStrings = {
  [ fields.inviteCode ] : 'invite code invalid',
  [ fields.userName ] : 'please enter a user name',
  [ fields.firstName ] : 'please enter a first name',
  [ fields.lastName ] : 'please enter a last name',
  [ fields.phone ] : 'please enter your phone number',
  [ fields.email ] : 'please enter a valid email',
  [ fields.password ] : 'please enter your password',
  [ fields.passwordNew ] : 'must be at least 8 characters long',
  [ fields.passwordVerify ] : 'the passwords don\'t match'
};

//endregion

export default function DialogSignIn() {

  // hooks
  const [ mode, setMode ] = useState( modes.login );
  const [ hasValidInviteCode, setHasValidInviteCode ] = useState( false );
  const [ hasErrors, setHasErrors ] = useState( true );

  const [ errorMsgs, setErrorMsgs ] = useState( {
    [ fields.userName ] : '',
    [ fields.firstName ] : '',
    [ fields.lastName ] : '',
    [ fields.phone ] : '',
    [ fields.email ] : '',
    [ fields.password ] : '',
    [ fields.passwordNew ] : '',
    [ fields.passwordVerify ] : ''
  } );

  const [ regData, dontCallFunc ] = useState( {
    [ fields.userName ] : '',
    [ fields.firstName ] : '',
    [ fields.lastName ] : '',
    [ fields.phone ] : '',
    [ fields.email ] : '',
    [ fields.password ] : '',
    [ fields.passwordNew ] : '',
    [ fields.passwordVerify ] : '',
    [ fields.inviteCode ] : ''
  } as UserRegData );

  return <div className="dialog-sign-in">
    <DialogHeader/>
    <div className="body">
      { getTabBar() }
      { getBody() }
    </div>
    <div className="footer">
      { getFooter() }
    </div>
  </div>;

//region functions
  function resetErrorMsgs() : void {
    for( let propName in errorMsgs ){
      errorMsgs[ propName ] = '';
    }
  }

  function getTabBar() {
    return <div className="tab-bar">
      <button
        className={ `tab ${ mode === modes.login ? 'active' : '' }` }
        onClick={ () => {
          resetErrorMsgs();
          setHasErrors( true );
          setMode( modes.login );
        } }
        tabIndex={ mode === modes.login ? -1 : 0 }
      >
        Sign In
      </button>
      <button
        className={`tab ${mode === modes.register || mode === modes.inviteCode ? 'active' : ''}`}
        onClick={ () => {
          resetErrorMsgs();
          setHasErrors( true );
          setMode( hasValidInviteCode ? modes.register : modes.inviteCode );
        } }
        tabIndex={ mode === modes.register ? -1 : 0 }
      >
        Register
      </button>
      <button
        className={ `tab ${ mode === modes.emailList ? 'active' : '' }`}
        onClick={ () => {
          setHasErrors( !isEmailValid() );
          setMode( modes.emailList );
        } }
        tabIndex={ mode === modes.emailList ? -1 : 0 }>
        Email List
      </button>
    </div>
  }

  function getBody() {
    switch( mode ) {
      // TODO : should login use phone number and verification every time?
      case modes.login :
        return <Fragment>
          <InputText
            key={ fields.email }
            fieldName={ fields.email }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.email ] }
            label={ inputLabels[ fields.email ] }
            defaultValue={ regData[ fields.email ] }
            autoComplete={ AutoCompletes.email }
          />
          <InputText
            key={ fields.password }
            type={ InputTextTypes.password }
            fieldName={ fields.password }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.password ] }
            label={ inputLabels[ fields.password ] }
            autoComplete={ AutoCompletes.passwordCurrent }
          />
        </Fragment>;
      case modes.inviteCode :
        return <Fragment>
          <InputText
            key={ fields.email }
            fieldName={ fields.email }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.email ] }
            label={ inputLabels[ fields.email ] }
            defaultValue={ regData[ fields.email ] }
            autoComplete={ AutoCompletes.email }
          />
          <InputText
            key={ fields.inviteCode }
            fieldName={ fields.inviteCode }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.inviteCode ] }
            label={ inputLabels[ fields.inviteCode ] }
            defaultValue={ regData[ fields.inviteCode ] }
            autoComplete={ AutoCompletes.off }
          />
        </Fragment>;
      case modes.register :
        return <Fragment>
          <InputText
            key={ fields.userName }
            fieldName = { fields.userName }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.userName ] }
            label={ inputLabels[ fields.userName ] }
            defaultValue={ regData[ fields.userName ] }
          />
          <InputText
            key={ fields.firstName }
            fieldName = { fields.firstName }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.firstName ] }
            label={ inputLabels[ fields.firstName ] }
            autoComplete={ AutoCompletes.nameGiven }
            defaultValue={ regData[ fields.firstName ] }
          />
          <InputText
            key={ fields.lastName }
            fieldName = { fields.lastName }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.lastName ] }
            label={ inputLabels[ fields.lastName ] }
            autoComplete={ AutoCompletes.nameFamily }
            defaultValue={ regData[ fields.lastName ] }
          />
          <InputText
            id={ 'phone' }
            type={ InputTextTypes.phone }
            key={ fields.phone }
            fieldName={ fields.phone }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.phone ] }
            label={ inputLabels[ fields.phone ] }
            autoComplete={ AutoCompletes.tel }
            defaultValue={ regData[ fields.phone ] }
          />
          <InputText
            key={ 'email-register' }
            fieldName={ fields.email }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.email ] }
            label={ inputLabels[ fields.email ] }
            autoComplete={ AutoCompletes.email }
            defaultValue={ regData[ fields.email ] }
          />
          <InputText
            type={ InputTextTypes.password }
            key={ fields.passwordNew }
            fieldName={ fields.passwordNew }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.passwordNew ] }
            label={ inputLabels[ fields.passwordNew ] }
            autoComplete={ AutoCompletes.passwordNew }
          />
          <InputText
            type={ InputTextTypes.password }
            key={ fields.passwordVerify }
            fieldName={ fields.passwordVerify }
            onChange={ fieldChanged }
            onBlur={ fieldBlurred }
            errorMsg={ errorMsgs[ fields.passwordVerify ] }
            label={ inputLabels[ fields.passwordVerify ] }
          />
        </Fragment>;
      case modes.emailList :
        return <InputText
          key={ fields.email }
          fieldName={ fields.email }
          onChange={ fieldChanged }
          onBlur={ fieldBlurred }
          errorMsg={ errorMsgs[ fields.email ] }
          label={ inputLabels[ fields.email ] }
          defaultValue={ regData[ fields.email ] }
          autoComplete={ AutoCompletes.email }
        />;
    }
  }

  function getFooter() {
    return <Fragment>
      { mode === modes.login &&
        <ButtonVibe
          classNames={ 'btn-txt' }
          label={ btnLabels.forgotPassword }
          onClick={ () => {
            if( isEmailValid() ) {
              resetPassword( regData.email )
                .then( result => store.dialogToDisplay = '' )
            } else {
              setErrorMsgs( {
                ...errorMsgs,
                [ fields.email ] : errorMsgStrings[ fields.email ]
              } )
            }
          } }
        />
      }
      { mode === modes.inviteCode &&
      <ButtonVibe
        classNames={ 'btn-txt' }
        label={ btnLabels.noInviteCode }
        onClick={ () => setMode( modes.emailList ) }
      />
      }
      <ButtonVibe
        label={ btnLabels[ mode ] }
        onClick={ btnSubmitClicked }
        isDisabled={ hasErrors }
      />
      <div id={ signInBtnID }> </div>
    </Fragment>;
  }

  function getDataProp( elem : HTMLElement ) : string {
    let target : any = elem;
    let propName : string = target.getAttribute( 'data-prop' );
    let retriesLeft : number = 20;

    while( !propName && !!retriesLeft ){
      target = target.parentNode;
      propName = target.parentNode.getAttribute( 'data-prop' );
      retriesLeft--;
    }

    return propName;
  }

  function fieldChanged( e : ChangeEvent<HTMLInputElement> ) : void {
    const propName = getDataProp( e.target );

    if( propName ) {
      regData[ propName ] = e.target.value;
      resetErrorMsg( propName );
    } else {
      logger.warn( 'no propName found for InputText after checking 20 levels of the DOM!' );
    }

    getErrorReport();
  }

  function fieldBlurred( e : FocusEvent<HTMLInputElement> ) : void {
    if( ( e?.nativeEvent?.relatedTarget as HTMLElement )?.classList?.contains( 'tab' ) ) return;

    const propName = getDataProp( e.target );

    if( propName ){
      const errorReport = getErrorReport();

      setErrorMsgs( {
        ...errorMsgs,
        [ fields[ propName ] ] : errorReport.newErrorMsgs[ fields[ propName ] ]
      } );
    }
  }

  function btnSubmitClicked() {
    if( hasErrors ) return getErrorReport( true );

    switch( mode ) {
      case modes.login :
        signInWithEmail( regData[ fields.email ], regData[ fields.password ] )
          // TODO : show message to check email
          .then( () => store.dialogToDisplay = '' );
        break;
      case modes.inviteCode :
        confirmInviteCode( { email : regData[ fields.email ], inviteCode : regData[ fields.inviteCode ]  } )
          .then( ( { isInviteCodeValid } ) => {
            console.log( 'isInviteCodeValid :', isInviteCodeValid );
            setHasValidInviteCode( true );
            setMode( modes.register );
          } )
          .catch( err => {
            logger.error( err );
          } );
        break;
      case modes.register :
        !getErrorReport( true ).hasErrors && createNewUser( regData )
          .then( ( res ) => {
            logger.log( CN + 'createNewUser returned with : ', res );
          } )
          .catch( err => logger.error( err ) )
        break;
      case modes.emailList :
        addToMailingList( { email : regData.email } )
          .then( response => {
            logger.log( 'response :', response );
          } )
          .catch( err => logger.error( err ) );
        break;
    }
  }

  function getErrorReport( shouldSet : boolean = false ) : { hasErrors : boolean, newErrorMsgs : any } {
    const errs = {
      [ fields.userName ] : mode === modes.register ? !regData[ fields.userName ] : false,
      [ fields.firstName ] : mode === modes.register ? !regData[ fields.firstName ] : false,
      [ fields.lastName ] : mode === modes.register ? !regData[ fields.lastName ] : false,
      [ fields.phone ] : mode === modes.register ? !regData[ fields.phone ] : false,
      [ fields.email ] : !isEmailValid(),
      [ fields.password ] : mode === modes.login ? !regData[ fields.password ] : false,
      [ fields.passwordNew ] : mode === modes.register ? !isNewPasswordValid() : false,
      [ fields.passwordVerify ] : mode === modes.register ? !doPassWordsMatch() : false,
      [ fields.inviteCode ] : mode === modes.inviteCode ? !isInviteCodeValid() : false
    };

    const newErrorMsgs = {
      [ fields.userName ] : errs[ fields.userName ] ? errorMsgStrings[ fields.userName ] : '',
      [ fields.firstName ] : errs[ fields.firstName ] ? errorMsgStrings[ fields.firstName ] : '',
      [ fields.lastName ] : errs[ fields.lastName ] ? errorMsgStrings[ fields.lastName ] : '',
      [ fields.phone ] : errs[ fields.phone ] ? errorMsgStrings[ fields.phone ] : '',
      [ fields.email ] : errs[ fields.email ] ? errorMsgStrings[ fields.email ] : '',
      [ fields.password ] : errs[ fields.password ] ? errorMsgStrings[ fields.password ] : '',
      [ fields.passwordNew ] : errs[ fields.passwordNew ] ? errorMsgStrings[ fields.passwordNew ] : '',
      [ fields.passwordVerify ] : errs[ fields.passwordVerify ] ? errorMsgStrings[ fields.passwordVerify ] : '',
      [ fields.inviteCode ] : errs[ modes.inviteCode ] ? errorMsgStrings[ fields.inviteCode ] : '',
    };

    const hasNewErrors = errs[ fields.userName ] ||
      errs[ fields.firstName ] ||
      errs[ fields.lastName ] ||
      errs[ fields.phone ] ||
      errs[ fields.email ] ||
      errs[ fields.password ] ||
      errs[ fields.passwordNew ] ||
      errs[ fields.passwordVerify ] ||
      errs[ fields.inviteCode ];

    if( shouldSet ){
      setErrorMsgs( newErrorMsgs );
    }

    setHasErrors( hasNewErrors );

    return {
      hasErrors : hasNewErrors,
      newErrorMsgs : newErrorMsgs
    };
  }

  function resetErrorMsg( fieldName : string ) : void {
    setErrorMsgs( {
      ...errorMsgs,
      [ fieldName ] : ''
    } );
  }

  function isEmailValid() : boolean {
    return /(.+)@(.+){2,}\.(.+){2,}/.test( regData[ fields.email ] );
  }

  function isInviteCodeValid() : boolean {
    return !!regData[ fields.inviteCode ];
  }

  function isNewPasswordValid() {
    return regData[ fields.passwordNew ].length >= 8;
  }

  function doPassWordsMatch() {
    return regData[ fields.passwordNew ] === regData[ fields.passwordVerify ];
  }
//endregion
}
