import { noOp } from '../../utils/utils';
import { ChangeEvent } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import './InputCheckbox.less';

interface props {
  isChecked : boolean,
  onChange : ( event : ChangeEvent<HTMLInputElement>, checked: boolean ) => void,
  label? : string
}

const GreenCheckbox = withStyles( {
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
} )( ( props: CheckboxProps ) => <Checkbox color="default" { ...props } /> );

export default function InputCheckbox( {
  isChecked = false,
  label = '',
  onChange = noOp
} : props ) {
  return <div className="input-checkbox">
    <FormControlLabel
      control={
        <GreenCheckbox
          checked={ isChecked }
          onChange={ onChange }
        />
      }
      label={ label }
    />
  </div>;
}
