import { store } from '../data/store'
import * as utils from '../utils/utils'
import * as logger from '../utils/logger'
import { docTypes, subscribeToDoc, unsubscribeFromAll } from './fbase';
import { setPath } from '../routes/routes';
import { dialogIDs } from '../data/consts';
import { noOp } from '../utils/utils';
import { UserRegData } from '../vibe/vibe';
import { registerNewUser } from '../api/api';
import { getApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  UserCredential,
  User,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  ConfirmationResult,
  updateProfile,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth";

const CN : string = 'auth';
let offAuthStateChanged : Function;

export const signInBtnID : string = 'sign-in-button';

export function init() : void {
  logger.log( CN + '.init' );

  const auth = getAuth( getApp() );

  // auth.settings.appVerificationDisabledForTesting = true;

  // TODO : loc - this will need to be dynamic once other languages are supported
  auth.languageCode = 'en';

  store.userUpdated.add( userUpdatedInStore );

  if( typeof offAuthStateChanged === 'function' ){
    offAuthStateChanged();
  }

  // export declare function onAuthStateChanged(auth: Auth, nextOrObserver: NextOrObserver<User>, error?: ErrorFn, completed?: CompleteFn): Unsubscribe;

  offAuthStateChanged = onAuthStateChanged( auth, ( updatedUser ) => {
    if( updatedUser !== null ) {
      store.user = {
        displayName : updatedUser.displayName,
        isAnonymous : updatedUser.isAnonymous,
        photoURL : updatedUser.photoURL,
        uid : updatedUser.uid,
        email : updatedUser.email,
        emailVerified : updatedUser.emailVerified
      };
      subscribeToDoc( docTypes.patron, store.user.uid );
    } else {
      store.user = null;
      unsubscribeFromAll();
      store.sessionData = null;
    }
  },
    ( err ) => {
      logger.error( err );
    },
    () => {
      logger.log('auth state change completed' );
    }
    );
}

export function isUserValid() : boolean {
  return store.user !== null && !!store.user.uid;
}

function userUpdatedInStore( updatedUser ) {
  if( updatedUser === null ) {
    logger.log( 'we have no fbase user yet.' );
    displaySignIn();
  } else {
    logger.log( 'we have an fbase user' );
    dismissSignIn();

    //TODO : get rid of this duck typing - split user into two types
    if( !updatedUser.hasOwnProperty( 'mojo' ) ) {
      logger.log( 'fbase user detected.' );
    }
  }
}

export function displaySignIn() : null {
  store.dialogToDisplay = dialogIDs.dialogSignIn;
  return null;
}

export function dismissSignIn() : void {
  utils.removeElementByID( 'login', true );
}

export function signInWithEmail( email : string, pw : string ) : Promise<any> {
  return signInWithEmailAndPassword( getAuth( getApp() ), email, pw );
}

export function normalizePhone( phoneNumber : string ) : string {
  let phone : string = phoneNumber;

  // TODO : need actual international phone handling (need to check Firebase SMS limitations)
  switch( true ) {
    case phone[ 0 ] === '+' :
      noOp();
      break;
    case phone[ 0 ] === '1' :
      phone = `+${ phone }`;
      break;
    default :
      phone = `+1${ phone }`;
  }

  const numRegex = /\d+/g;
  const phoneArray = phone.match( numRegex );

  if( phoneArray && phoneArray.length ) {
    phone = `+${ phoneArray.join( '' ) }`;
  } else {
    phone = 'invalid';
  }
  return phone;
}

export function createNewUser( regData : UserRegData ) : Promise<any> {
  logger.log( CN + '.createNewUser' );

  const normalizedPhone : string = normalizePhone( regData.phone );

  if( normalizedPhone === 'invalid' ){
    return new Promise( ( resolve, reject ) => reject( 'invalid phone number provided' ) );
  } else {
    regData.phone = normalizedPhone;
  }

  function userUpdated( user : User ) {
    if( user && user.uid ){
      store.userUpdated.remove( userUpdated );
      registerNewUser( {
        ...regData,
        userID : user.uid
      } )
        .then( result => logger.log( 'user is registered with result :', result ) );
    }
  }

  store.userUpdated.add( userUpdated );

  return signInWithPhone( regData.phone );
}

export function signInWithPhone( phoneNumber : string ) : Promise<any> {
  logger.log( CN + '.signInWithPhone' );

  const auth = getAuth( getApp() );

  const recaptchaVerifier = new RecaptchaVerifier(
    signInBtnID,
    {
      size : 'invisible',
      callback : res => logger.log( 'recaptchaVerifier callback response', res ),
      [ 'expired-callback' ] : () => logger.warn( 'TODO : reCAPTCHA response expired. Ask user to solve reCAPTCHA again.' )
    },
    auth
  );

  return signInWithPhoneNumber( auth, phoneNumber, recaptchaVerifier )
      .then( ( confirmationResult : ConfirmationResult ) => {
        store.verCodeUpdated.addOnce( verCode => {
          confirmationResult.confirm( verCode )
            .then( ( result : UserCredential ) => {
              logger.log( 'SMS verification confirmation result :',  result )
            } )
            .catch( err => logger.error( err ) )
        } );

        store.dialogToDisplay = dialogIDs.dialogVerCode;
      } )
      .catch( ( err ) => logger.error( err ) );
}

export function signOutOfVibe() : void {
  signOut( getAuth( getApp() ) )
      .then( () => {
        logger.log( 'user signed out' );
        setPath();
        window.location.reload();
      } )
      .catch( err => logger.error( err ) );
}

export function newUserRegistered( formData ) : void {
  logger.log( CN + '.newUserRegistered' );
  logger.log( 'formData : ', formData );

  const profileUpdate = {
    displayName : formData.displayName,
    photoURL : `https://digital-dance.club/img/alphatars/avatar_${ formData.displayName.charAt( 0 ).toLowerCase() }.svg`
  };

  const auth = getAuth( getApp() );

  const offAuthStateChanged = onAuthStateChanged( auth, ( updatedUser : User | null ) => {
      if( updatedUser ) {
        logger.log( 'calling firebase.User.updateProfile...' );
        auth.currentUser && updateProfile( auth.currentUser, profileUpdate )
          .then( () => {
            logger.log( 'profile updated with displayName and photoURL successfully!' );
            // do a one off refresh of the user
            const oneOffAuthStateChanged = onAuthStateChanged( auth, ( updatedUser ) => {
              store.user = updatedUser;
              oneOffAuthStateChanged();
            } );
          } )
          .catch( ( err ) => {
            logger.warn( 'there was a problem updating the profile with displayName and photoURL.' );
            logger.error( err );
          } );
        sendSignInLinkToEmail( auth, auth.currentUser?.email as string, {
          url : 'https://vibewith.me',
          handleCodeInApp : true
        } )
          .then( () => logger.log( 'Email Verification Sent!' ) )
          .catch( err => logger.error( err ) );
        offAuthStateChanged();
      } else {
        logger.log( 'user signed out' );
      }
    } );
}

export function resetPassword( email : string ) : Promise<any> {
  console.log( CN + '.resetPassword' );
  console.log( 'email :', email );

  return sendPasswordResetEmail( getAuth( getApp() ), email )
    .then( ( data ) => {
      alert( 'Password Reset Email Sent!' );
    } )
    .catch( ( err ) => {
      // TODO : handle possible err.code values : 'auth/invalid-email', 'auth/user-not-found'
      logger.error( CN + '.resetPassword encountered an error.' );
      logger.error( err );
    } );
}

export function getIdToken( needsIdToken : boolean = true ) : Promise<string> {
  return needsIdToken ?
    ( getAuth( getApp() ).currentUser as User ).getIdToken( true ) :
    new Promise( ( resolve, reject ) => resolve( '' ) );
}
