import { vibetron } from '../vibe/vibe';
import { ChatMsg } from '../relay/p2pRelay';

export enum AppModes {
  HOME     = 'HOME',                      // vibewith.me/
  CLUB     = 'CLUB',                      // vibewith.me/myClub/{myClub.clubID}
  STAR     = 'STAR',                      // vibewith.me/star/{star.starID}
  PATRON   = 'PATRON',                    // vibewith.me/patron/{patron.patronID}
  EVENT    = 'EVENT',                     // vibewith.me/{myClub.urlSafeName}/{event.urlSafeName}
                                            // https://vibewith.me/deserthearts/black
                                            // https://vibewith.me/rebar/flammable
                                            // https://vibewith.me/monkeyloft/decibal-upstairs
                                            // https://vibewith.me/monkeyloft/decibal-outside
  SETTINGS = 'SETTINGS'                   // vibewith.me/settings/
                                            // https://vibewith.me/settings/account
                                            // https://vibewith.me/settings/club
                                            // https://vibewith.me/settings/star
}

export const emotiRegex : RegExp = /:[^:\s]*(?:::[^:\s]*)*:/g;

export const starterChatHistory : ChatMsg[] = [ { from : vibetron, fromName : '', to : [], msg : 'check out \'orientation\' in menu \u261D' } ];

export const dummyChat = [
  { from : vibetron, msg : 'check out \'orientation\' in menu \u261D' },
  { from : '0', fromName : 'keldon', msg : 'hey everyone - welcome home' },
  { from : '1', fromName : 'Gracie', msg : 'beautiful people!!' },
  { from : '2', fromName : 'finnigan', msg : 'let\'s turn it up!' },
  { from : '3', fromName : 'kurremkarmerruk', msg : 'VIIIIIIBES!!!' },
  { from : '4', fromName : 'big_daddy', msg : 'feeling this all the way through my souls!' },
  { from : '5', fromName : 'vibalicious', msg : 'sooo glad to be able to pick up your frequency ~ ~' },
  { from : '6', fromName : 'tribal', msg : 'Super good to see you all again - I have needed vibes just like this fir SHURE!' },
  { from : vibetron, msg : 'this performance will be ending in about 25 minutes' }
];

export enum PageTitles {
  HOME     = 'VibeWith.me',
  CLUB     = 'Club',
  DJ       = 'DJ',
  EVENT    = 'Event',
  SETTINGS = 'Settings'
}

export enum ReservedClubNames {
  club     = 'club',
  profile  = 'profile',
  settings = 'settings',
  star     = 'star',
  patron   = 'patron',
  account  = 'account'
}

export enum SettingsPanelModes {
  PROFILE = 'PROFILE',
  ACCOUNT = 'ACCOUNT',
  CLUB    = 'CLUB',
  STAR    = 'STAR'
}

export enum ENVs {
  DEMO = 'DEMO',
  DEV  = 'DEV',
  TEST = 'TEST',
  PROD = 'PROD'
}

export enum dialogIDs {
  dialogCreateTable = 'dialogCreateTable',
  dialogAddPayment = 'dialogAddPayment',
  dialogStreamSources = 'dialogStreamSources',
  dialogSignIn = 'dialogSignIn',
  dialogPurchaseMojo = 'dialogPurchaseMojo',
  dialogTableSettings = 'dialogTableSettings',
  dialogTableWaitlist = 'dialogTableWaitlist',
  dialogTableInvite = 'dialogTableInvite',
  dialogTipTheStar = 'dialogTipTheStar',
  dialogCreateNewStar = 'dialogCreateNewStar',
  dialogCreateEvent = 'dialogCreateEvent',
  dialogVerCode = 'dialogVerCode',
  dialogOrientation = 'dialogOrientation',
  spinner = 'spinner'
}

export enum fxIDs {
  heartCenter = 'heartCenter',
  heartRain = 'heartRain',
  heartBeating = 'heartBeating',
  matrix = 'matrix',
  dropBounce = 'dropBounce'
}

export enum positions {
  top     = 'top',
  right   = 'right',
  bottom  = 'bottom',
  left    = 'left'
}

export enum fileFilters {
  allWebImages = 'image/*, .APNG, .WEBP, .SVG',
  image = 'image/*',
  video = 'video/*',
  audio = 'audio/*',
  any = ''
}

export enum defaultStorage {
  patronCoverImg = 'defaults/default_cover.png',
  patronProfileImg = 'defaults/default_profile.png'
}

export enum userConfirmations {
  costSoundFX = 'constSoundFX'
}
