import { Fragment, useState, useRef, useEffect } from 'react';

import { Coord } from '../../vibe/vibe';
import * as logger from '../../utils/logger';
import './StreamPanelHUDremote.less';
import { makeTopmost, getParentStreamPanel } from '../../utils/utils';

const CN : string = 'StreamPanelHUDremote';

interface props {
  setStreamPanelIsChatDisplayed( shouldDisplay : boolean ) : any,
  setIsSendMenuDisplayed( shouldDisplay : boolean ) : any,
  streamPanelDivRef : any,
  isSendMenuDisplayed : boolean
}

export default function StreamPanelHUDremote( {
  setStreamPanelIsChatDisplayed,
  setIsSendMenuDisplayed,
  streamPanelDivRef,
  isSendMenuDisplayed
} : props ) {

  // hooks
  const [ isHidden, setIsHidden ] = useState( false );
  const [ isFullscreen, setIsFullscreen ] = useState( false );
  const [ isChatPanelDisplayed, setIsChatPanelDisplayed ] = useState( false );

  const hudRef = useRef( null );

  // const muteFromSignal = useCallback( () => setMute( true ), [] );
  // const unmuteFromSignal = useCallback( () => setMute( false ), [] );

  useEffect( () => {
    // polycastMuted.add( muteFromSignal );
    // polycastUnmuted.add( unmuteFromSignal );

    return cleanup;
  }, [] );

  return (
    <div
      ref={ hudRef }
      className={ 'stream-panel-hud remote' }
      onMouseDown={ e => makeTopmost( getParentStreamPanel( e.target as HTMLElement ) ) }
    >
      { getBtns() }
    </div>
  );

  /* TODO : add this back in when we have separate audio...
  <button
    data-coord={ Coord.b3 }
    className={ isMuted ? 'icon-speaker off' : 'icon-speaker' }
    onClick={ () => setMute( !isMuted ) }/>
  <Slider
    parentRef={ hudRef as any }
    signalLevelExternal={ signalSlider }
    height={ 60 }
    onChange={ ( newLevel : number ) => {
      console.log( `slider changed - newLevel : ${ newLevel }` );
      setMute( !newLevel, true );
    } }
    classNames={ `${Coord.b3} above` }
    coord={Coord.b3}
  />
   */

  function getBtns() {
    return <Fragment>
      <button
        data-coord={ Coord.a1 }
        className={ `icon-heart` }
        onClick={ () => {
          setIsSendMenuDisplayed( !isSendMenuDisplayed );
        } }
      />
      <button
        data-coord={ Coord.e1 }
        className={ `icon-dm` }
        onClick={ () => {
          setStreamPanelIsChatDisplayed( !isChatPanelDisplayed );
          setIsChatPanelDisplayed( !isChatPanelDisplayed );
        } }
      />
      <button
        data-coord={ Coord.a3 }
        className={ isHidden ? 'icon-eye off' : 'icon-eye' }
        onClick={ () => {
          // we are in front of the wave here - so boolean is backwards
          if( !isHidden ){
            getVidContainer()?.classList.add( 'hidden' );
          } else {
            getVidContainer()?.classList.remove( 'hidden' );
          }
          setIsHidden( !isHidden );
        } }
      />
      <button
        data-coord={ Coord.e3 }
        className={ isFullscreen ? 'icon-fullscreen off' : 'icon-fullscreen' }
        onClick={ toggleFullScreen }
      />
    </Fragment>;
  }

  /* TODO : add this back in when we have separate audio
  function setMute( shouldMute : boolean, fromSlider : boolean = false ) {
    logger.log( CN + '.setMute' );

    const vid : HTMLVideoElement = getVid();

    console.log( 'vid :', vid );

    if( vid ) vid.muted = shouldMute;

    setIsMuted( shouldMute );

    if( !fromSlider ) {
      if( shouldMute ) {
        signalSlider.dispatch( 0 );
      } else {
        signalSlider.dispatch( vid.volume );
      }
    }
  }
   */

  function toggleFullScreen() : void {
    if( isFullscreen ) {
      window.document.exitFullscreen()
        .then( data => {
          logger.log( 'exited fullscreen' );
          setIsFullscreen( false );
        } )
        .catch( err => {
          logger.error( err );
        } )
    } else {
      ( streamPanelDivRef.current as HTMLDivElement ).requestFullscreen()
        .then( data => {
          logger.log( 'entered fullscreen' );
          setIsFullscreen( true );
        } )
        .catch( err => {
          logger.error( err );
        } )
    }
  }

  function getVidContainer() : HTMLVideoElement {
    return streamPanelDivRef.current.querySelector( '.remote-video-container' );
  }

  function cleanup() {
    logger.log( CN + '.cleanup' );

    // polycastMuted.remove( muteFromSignal );
    // polycastUnmuted.remove( unmuteFromSignal );
  }
}

