import { isMobile } from '../../utils/responsive';
import { Fragment } from 'react';

import './HomePanel.less';
import { dialogIDs } from '../../data/consts';

interface props {
  hasPolycast : boolean,
  dialogToDisplay : dialogIDs
}

export default function HomePanel( {
  hasPolycast,
  dialogToDisplay
} : props ) {

  return (
    <div className={ `panel-home${ hasPolycast ? ' has-polycast' : '' }` }>
      { ( !dialogToDisplay && !isMobile() ) &&
        <Fragment>
          <video
            src="https://appcloud9.com/vid/vibe_with_me_bg.mp4"
            className="underlay"
            role="presentation"
            crossOrigin="anonymous"
            preload="auto"
            playsInline
            muted
            loop
            autoPlay
            onPlay={ e => ( e.target as HTMLVideoElement ).playbackRate = 0.6 }
            onLoadStart={ e => ( e.target as HTMLVideoElement ).play() }
          />
          <div className="bg-overlay"/>
        </Fragment>
      }
      { !dialogToDisplay &&
        <div className="panel">
          <header>WE ARE LAUNCHING SOON.</header>
          <div className="middle">
            <h1>VIBEwith.me</h1>
            <h3>A Face to Face Social Network<br/>for Live Events</h3>
          </div>
          <footer>© 2020 by DigitalDanceClub Inc.</footer>
        </div>
      }
    </div>
  );
}

