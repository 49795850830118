import * as logger from '../utils/logger';
import { getIdToken } from '../fbase/auth';

const publicAPIs : string[] = [
  'confirmInviteCode',
  'addToMailingList'
];

function getBaseURL() {
  return window.location.hostname === 'localhost' ? 'http://localhost:5000/api/' : 'https://vibewith.me/api/';
}

async function callAPI( apiName, payload, needsIDtoken : boolean = true ) : Promise<any> {
  logger.log( 'api.callAPI' );
  logger.log( '\tapiName :', apiName );
  logger.log( '\tpayload :', payload );

  const url = getBaseURL() + apiName;

  return new Promise( async ( resolve, reject ) => {
    if( publicAPIs.includes( apiName ) ) {
      sendRequestWithToken( '' );
    } else {
      const idToken : any = await getIdToken( needsIDtoken );
      sendRequestWithToken( idToken as string );
    }

    function sendRequestWithToken( token : string ) {
      payload.idToken = token;
      payload = JSON.stringify( payload );
      // request
      const request = new XMLHttpRequest();
      request.overrideMimeType( 'application/json' );
      request.onreadystatechange = () => {
        try {
          switch( true ) {
            case request.readyState !== 4 :
              return;
            case request.status !== 200 :
              throw new Error( request.statusText || `HTTP STATUS : ${ request.status }` );
            default :
              try{
                logger.log( 'request.responseText :' +  request.responseText + ':' );
                resolve( JSON.parse( request.responseText ) );
              } catch( err ) {
                logger.warn( 'this doesn\'t look like JSON.' );
                logger.error( err );
                reject( err );
              }
          }
        } catch( err ) {
          reject( err );
        }
      };
      request.open( 'POST', url, true );
      request.setRequestHeader( 'Accept', '*/*' );
      request.setRequestHeader( 'Content-Type', 'application/json' );
      request.send( payload );
    }

  } );
}

export const test = payload => callAPI( 'test', payload );

// accounts
export const accountCreate = payload => callAPI( 'accountCreate', payload );
export const accountRead = payload => callAPI( 'accountRead', payload );
export const accountUpdate = payload => callAPI( 'accountUpdate', payload );
export const accountDelete = payload => callAPI( 'accountDelete', payload );

// auth
export const registerNewUser = payload => callAPI( 'registerNewUser', payload );
export const confirmInviteCode = payload => callAPI( 'confirmInviteCode', payload );
export const addToMailingList = payload => callAPI( 'addToMailingList', payload );

// clubs
export const clubCreate = payload => callAPI( 'clubCreate', payload );
// implement direct read here
export const clubRead = payload => callAPI( 'clubRead', payload );
export const clubUpdate = payload => callAPI( 'clubUpdate', payload );
export const clubDelete = payload => callAPI( 'clubDelete', payload );
export const getClubAndEventByPath = payload => callAPI( 'getClubAndEventByPath', payload, false );

//patrons
export const patronUpdate = payload => callAPI('patronUpdate', payload);

// events
export const eventCreate = payload => callAPI( 'eventCreate', payload );
export const eventRead = payload => callAPI( 'eventRead', payload );
export const eventUpdate = payload => callAPI( 'eventUpdate', payload );
export const eventDelete = payload => callAPI( 'eventDelete', payload );

// mojo
export const mojoCreate = payload => callAPI( 'mojoCreate', payload );

// mojoTxn
export const mojoSpend = payload => callAPI( 'mojoSpend', payload );

// polycasts
export const polycastJoin = payload => callAPI( 'polycastJoin', payload );
export const polycastLeave = payload => callAPI( 'polycastLeave', payload );
export const polycastSetMode = payload => callAPI( 'polycastSetMode', payload );

// tables
export const tableCreate = payload => callAPI( 'tableCreate', payload );
export const tableRead = payload => callAPI( 'tableRead', payload );
export const tableUpdate = payload => callAPI( 'tableUpdate', payload );
export const tableDelete = payload => callAPI( 'tableDelete', payload );
export const tableList = payload => callAPI( 'tableList', payload );

// services
export const getCloudData = payload => callAPI( 'getCloudData', payload );

// stripe
export const createCheckoutSession = payload => callAPI( 'createCheckoutSession', payload );
export const checkoutSessionCompleted = payload => callAPI( 'checkoutSessionCompleted', payload );
