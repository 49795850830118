import { noOp } from '../../utils/utils';

import './MenuItem.less';

export interface MenuItemInfo {
  label : string,
  onClick : any,
  icon? : string,
}

export default function MenuItem( {
  label = 'label',
  icon = '',
  onClick = noOp
} : MenuItemInfo ) {

  return <div
    className={ `menu-item ${ icon ? icon : '' }`.trim() }
    onClick={ onClick }
    tabIndex={ 0 }
  >{ label }
  </div>;
}
