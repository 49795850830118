import * as route from '../../routes/routes';
import { SettingsPanelModes, ReservedClubNames } from '../../data/consts';

import './SettingsTabs.less'

export default function SettingsTabs( { settingsPanelMode } ) {
  return (
    <div className="settings-tabs">
      <div
        className={`tab ${ settingsPanelMode === SettingsPanelModes.PROFILE ? 'active' : '' }`}
        onClick={() => route.setPath( `${ReservedClubNames.settings}/${ReservedClubNames.profile}` )}>Profile
      </div>
      <div
        className={`tab ${ settingsPanelMode === SettingsPanelModes.ACCOUNT ? 'active' : '' }`}
        onClick={() => route.setPath( `${ReservedClubNames.settings}/${ReservedClubNames.account}` )}>Account
      </div>
      <div
        className={`tab ${ settingsPanelMode === SettingsPanelModes.CLUB ? 'active' : '' }`}
        onClick={() => route.setPath( `${ReservedClubNames.settings}/${ReservedClubNames.club}` )}>Club Profile
      </div>
      <div
        className={`tab ${ settingsPanelMode === SettingsPanelModes.STAR ? 'active' : '' }`}
        onClick={() => route.setPath( `${ReservedClubNames.settings}/${ReservedClubNames.star}` )}>Star Profile
      </div>
    </div>
  );
}

