
import './Slider.less'
import { noOp } from '../../utils/utils';
import { MouseEvent, useRef, useState } from 'react';

interface props {
  parentRef : { current : HTMLDivElement },
  signalLevelExternal : signals.Signal,
  height? : number,
  onChange? : Function,
  classNames? : string,
  coord? : string
}

// const CN : string = 'Slider';
let isDragging : boolean = false;

export default function Slider(
  {
    parentRef,
    signalLevelExternal,
    height = 50,
    onChange = noOp,
    classNames = '',
    coord = ''
  } : props ) {

  const [ init, dontCall ] = useState( signalLevelExternal.add( setLevelExternal ) );

  const handle : any = useRef( null );
  const slider : any = useRef( null );
  const heightHandle : number = 8;


  function getHandle() : HTMLDivElement  { return handle.current as HTMLDivElement }
  function getSlider() : HTMLDivElement { return slider.current as HTMLDivElement }

  function setLevelExternal( newLevel : number ) : void {
    let y : number = 1 - newLevel;

    switch( true ) {
      case y <= 0 :
        getHandle().style.top = '0px'
        break;
      case y > 0.95 :
        getHandle().style.top = `calc( 100% - ${ heightHandle }px )`;
        break;
      default :
        getHandle().style.top = ( y * 100 ) + '%';
    }
  }

  function getLevel() : number {
    const top : number = parseInt( window.getComputedStyle( getHandle(), null ).getPropertyValue( 'top' ), 10 );
    let current : number = ( height - top ) / ( height - heightHandle );

    switch( true ) {
      case current <= 0.18 :
        current = 0;
        break;
      case current >= 0.95 :
        current = 1;
        break;
      default :
        let str = current.toString()
          .split( '.' )[ 1 ]
          .substr( 0, 2 );
        current = parseFloat( '0.' + str );
    }

    return current;
  }

  function refresh( e : MouseEvent<HTMLDivElement> ) : void {
    e.preventDefault();
    if( !isDragging ) return;

    const sliderRect : DOMRect = getSlider().getBoundingClientRect();
    let y : number = e.clientY - sliderRect.y;

    // mind the bounds
    if( y < 0 ) y = 0;
    if( y > sliderRect.height - heightHandle ) y = sliderRect.height - heightHandle;

    if( y > sliderRect.height - heightHandle ){
      y = sliderRect.height - heightHandle;
    }
    getHandle().style.top = y + 'px';
    onChange( getLevel() );
  }

  function startDragging( e ) : void {
    e.preventDefault();
    parentRef.current.addEventListener( 'mouseup', stopDragging, true );
    isDragging = true;
    refresh( e );
  }

  function stopDragging( e : any = null ) : void {
    parentRef.current.removeEventListener( 'mouseup', stopDragging, true );
    e && e.preventDefault();
    isDragging = false;
  }

  return (
    <div
      ref={ slider }
      data-coord={ coord ? coord : '' }
      className={ `slider ${ classNames ? classNames : ''}`.trim() }
      style={ {
        height : `${height}px`
      } }
      onMouseDown={ startDragging }
      onMouseMove={ refresh }
    >
      <div className="track-top"/>
      <div className="track-middle"/>
      <div className="track-bottom"/>
      <div
        ref={ handle }
        className="slider-handle"
      />
    </div>
  );
}

