import { store } from '../../data/store'
import { useCallback, useState } from 'react';
import { AppModes, dialogIDs } from '../../data/consts'

import SettingsPanel from '../SettingsPanel/SettingsPanel'
import ClubPanel from '../ClubPanel/ClubPanel'
import StarPanel from '../StarPanel/StarPanel'
import PolycastPanel from '../PolycastPanel/PolycastPanel'
import EventPanel from '../EventPanel/EventPanel'
import HomePanel from '../HomePanel/HomePanel'
import OverlayPanel from '../OverlayPanel/OverlayPanel'

import './ActivityPanel.less'
import { Polycast, Patron } from '../../vibe/vibe';

interface props {
  appMode : string,
  user : any,
  patron : Patron
}

export default function ActivityPanel( {
  appMode,
  user,
  patron
} : props ) {
  // hooks
  const [ dialogToDisplay, setDialogToDisplay ] = useState( store.dialogToDisplay as dialogIDs );
  const [ isLoading, setIsLoading ] = useState( store.isLoading);
  const [ hasPolycast, setHasPolycast ] = useState( !!store.polycast );

  // callbacks
  const dialogToDisplayUpdated = useCallback( setDialogToDisplay, [] );
  const isLoadingUpdated = useCallback( setIsLoading, [] );

  // signals remove
  store.dialogToDisplayUpdated.remove( dialogToDisplayUpdated );
  store.polycastUpdated.remove( polycastUpdated );
  store.isLoadingUpdated.remove( isLoadingUpdated );

  // signals add
  store.dialogToDisplayUpdated.add( dialogToDisplayUpdated );
  store.polycastUpdated.add( polycastUpdated );
  store.isLoadingUpdated.add( isLoadingUpdated );

  function polycastUpdated( polycast : Polycast ) : void {
    const hasPolycastNow : boolean = !!polycast;
    if( hasPolycast !== hasPolycastNow ) {
      setHasPolycast( !hasPolycast );
    }
  }

  function getPanel() : any {
    switch( appMode ) {
      case AppModes.HOME :
        return <HomePanel
          dialogToDisplay={ dialogToDisplay }
          hasPolycast={ hasPolycast }/>;
      case AppModes.EVENT :
        return <EventPanel hasPolycast={ hasPolycast }/>;
      case AppModes.SETTINGS :
        return <SettingsPanel patron={ patron }/>;
      case AppModes.CLUB :
        return <ClubPanel/>;
      case AppModes.STAR :
        return <StarPanel user={ user }/>;
    }
  }

  return (
    <div className="panel-activity">
      { getPanel() }
      { hasPolycast && <PolycastPanel/>}
      { dialogToDisplay && <OverlayPanel dialogToDisplay={ dialogToDisplay as dialogIDs }/> }
      { isLoading && <OverlayPanel dialogToDisplay={ dialogIDs.spinner }/> }
    </div>
  );
}

