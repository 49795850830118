import './StarProfilePanel.less'

export default function StarProfilePanel() {
  return (
    <div className="panel-dj-profile">
      <h3>Are you a DJ?</h3>
      <div className="create-dj-panel">
        <p>If you are going to be booked as a DJ for an event (any club's event) then you'll want to register as a DJ.</p>
        <button>Register as a DJ</button>
      </div>
    </div>
  );
}

