import { useCallback, useRef } from "react";
import { getGUID, noOp } from "../../utils/utils";
import { store } from "../../data/store";
import { fileFilters } from "../../data/consts";
import { uploadFile } from "../../fbase/fbaseUtils";
import * as logger from '../../utils/logger';

import './FileUploader.less';

const CN : string = 'FileUploader';

interface props {
  btn : HTMLElement,
  maxSize? : string,
  accepts? : string,
  onFileSelected? : Function,
  folderName : string
}

export default function FileUploader( {
  btn,
  maxSize = '10MB',
  accepts = fileFilters.any,
  onFileSelected = noOp,
  folderName = ''

} : props ) {

  const inputRef = useRef( null );

  const btnClicked = useCallback( ( e ) => {
    // @ts-ignore
    inputRef.current.click();
  }, [] );

  if( !store.patron ) return null;

  btn && btn.removeEventListener( 'click', btnClicked );
  btn && btn.addEventListener( 'click', btnClicked );

  function fileSelected( e ) {
    const file = e.target.files[ 0 ];
    if( !file ){
      logger.warn( CN + '.fileSelected found no file - exiting.' );
      return;
    }
    const fileNameArray : string[] = file.name.split( '.' );
    const ext : string = fileNameArray[ fileNameArray.length - 1 ];
    const fullName = `${store.patron.patronID}/${folderName}/${getGUID()}`;
    const path = `${fullName}.${ext}`;

    uploadFile( file, path )
      .then( snapshot => {
        onFileSelected( { file, path } );
      } )
      .catch( err => logger.error( err ) );
  }

  return <input
    ref={inputRef}
    type="file"
    max={maxSize}
    accept={accepts}
    onChange={fileSelected}
  />;

}

