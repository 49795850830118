import { Coord, FXinfo } from '../../vibe/vibe';
import { store } from '../../data/store';
import * as logger from '../../utils/logger';
import { LocalVideo, useLocalVideo, useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { useState, useRef, useEffect, useCallback } from 'react';
import StreamPanelHUDlocal from '../StreamPanelHUDlocal/StreamPanelHUDlocal';
import { chimeMeetingJoined, streamPanelCoordsChanged, streamPanelFXreceived } from '../../relay/signalRelay';
import { localStorageKeys } from '../../data/vibeLocalStorage';
import { dialogIDs } from '../../data/consts';
import { FXevent } from '../../relay/p2pRelay';
import FXpanel from '../FXpanel/FXpanel';

const CN : string = 'StreamPanelLocal';
let renderCount : number = 0;

export default function StreamPanelLocal() {
  renderCount++;
  logger.log( CN + ' rendering - render : ' + renderCount );

  // hooks
  const { tileId, toggleVideo } = useLocalVideo();

  const [ hasSelectedSources, setHasSelectedSources ] = useState( localStorage.getItem( localStorageKeys.hasSelectedSources ) );
  const [ isMeetingReady, setIsMeetingReady ] = useState( false );
  const [ isAway, setIsAway ] = useState( store.isAway );
  const [ fxInfo, setFXinfo ] = useState( null as FXinfo | null );
  const audioVideo = useAudioVideo();

  console.log( CN + ' tileId :', tileId );

  // refs
  const streamPanelDivRef = useRef( null as any );

  // callbacks
  const startLocalVideo = async () => {
    logger.log( CN + '.startLocalVideo' );

    if( audioVideo ) {
      try{

        // the numbers below are defaults except for the 30 fps where default is 15
        audioVideo.chooseVideoInputQuality( 960, 540, 30, 1400 );

        if( hasSelectedSources ){
          await kickoff();
        } else {
          store.dialogToDisplay = dialogIDs.dialogStreamSources;
          store.dialogToDisplayUpdated.addOnce( kickoff );
        }
        // const videoInputs = await audioVideo.listVideoInputDevices();
        // const audioInputs = await audioVideo.listAudioInputDevices();
        // await audioVideo.chooseVideoInputDevice( videoInputs[ 0 ] );
        // await audioVideo.chooseAudioInputDevice( audioInputs[ 0 ] );
      } catch( err ) {
        logger.warn( CN + '.startLocalVideo - FAILED.' );
        logger.error( err );
      }
    } else {
      logger.error( 'ERROR : there is no audioVideo - startLocalVideo has failed.' );
    }
  };

  const receivedFX = useCallback( ( fxEvent : FXevent ) => {
    console.log( CN + '.receivedFX' );
    if( fxEvent?.to?.includes( store.patron.patronID ) ){
      setFXinfo( fxEvent?.fxInfo as FXinfo );
    }
  }, [] );

  async function kickoff() {
    logger.log( CN + '.kickoff' );

    localStorage.setItem( localStorageKeys.hasSelectedSources, 'true' );
    audioVideo?.start();
    audioVideo?.startLocalVideoTile();
    await toggleVideo();
  }

  function meetingIsReady() {
    setIsMeetingReady( true )
  }

  useEffect( () => {
    chimeMeetingJoined.addOnce( meetingIsReady );
    store.isAwayUpdated.add( setIsAway );
    streamPanelFXreceived.add( receivedFX );
    return cleanup;
  }, [] );

  useEffect( () => {
    if( isMeetingReady ) {
      logger.log( 'calling startLocalVideo' );
      startLocalVideo()
        .then( () => {
          logger.log( 'startLocalVideo is successful' );
        } )
        .catch( err => logger.error( err ) );
    }
  }, [ isMeetingReady ] );

  return <div
    ref={ streamPanelDivRef }
    id={ `panel-stream-local` }
    className={ `panel-stream local` }
    data-coord={ Coord.a5 }
    data-patronid={ store.patron.patronID }
    key={ store.patron.patronID }
    draggable={ true }
    onDragEnter={ e => {
      ( e.target as HTMLElement ).classList.add( 'targeted' );
      e.preventDefault();
    } }
    onDragOver={ e => {
      ( e.target as HTMLElement ).classList.add( 'targeted' );
      e.preventDefault();
    } }
    onDragLeave={ e => {
      ( e.target as HTMLElement ).classList.remove( 'targeted' );
    } }
    onDragStart={ e => {
      dragStarted( e.target );
    } }
    onDrop={ dropped }
  >
    <LocalVideo />
    { isAway &&
      <video
        className="away-vid"
        autoPlay={ true }
        playsInline={ true }
        muted={ true }
        controls={ false }
        loop={ true }
        src={ store?.patron?.profile?.awayMedia?.url || 'https://appcloud9.com/vid/magic_fire.mp4' }
      />
    }
    <StreamPanelHUDlocal/>
    { fxInfo &&
      <FXpanel
        fxInfo={ fxInfo }
        setFXinfo={ setFXinfo }
      />
    }
  </div>;

  function dragStarted( streamPanel ) : void {
    streamPanelDivRef.current.classList.add( 'dragging' );
    store.sessionData = {
      streamPanelDragged : streamPanel
    };
  }

  function dragEnded() {
    const panelBeingDragged : HTMLDivElement = document.querySelector( '.dragging' ) as HTMLDivElement;
    panelBeingDragged && panelBeingDragged.classList.remove( 'dragging' );
    const dropTargets = document.querySelectorAll( '.drop-target' );
    const streamPanels = document.querySelectorAll( '.panel-stream' );
    dropTargets.forEach( dropTarget => dropTarget.classList.remove( 'targeted' ) );
    streamPanels.forEach( streamPanel => streamPanel.classList.remove( 'targeted' ) );
    streamPanelCoordsChanged.dispatch();
  }

  function dropped( e ) : void {
    let streamPanel;
    let target = e.target;
    while( !streamPanel ) {
      if( target.classList.contains( 'panel-stream' ) ) {
        streamPanel = target;
      } else {
        if( target.parentNode === null ) {
          e.preventDefault();
          dragEnded();
          return;
        }
        target = target.parentNode;
      }
    }
    const streamPanelDragged = store.sessionData.streamPanelDragged;
    const movedToCoord = streamPanel.getAttribute( 'data-coord' );
    const movedFromCoord = streamPanelDragged.getAttribute( 'data-coord' );
    streamPanel.setAttribute( 'data-coord', movedFromCoord );
    streamPanelDragged.setAttribute( 'data-coord', movedToCoord );
    dragEnded();
  }

  function cleanup() {
    console.log( CN + '.cleanup' );

    chimeMeetingJoined.remove( meetingIsReady );
    store.isAwayUpdated.remove( setIsAway );
    streamPanelFXreceived.remove( receivedFX );
  }

}
