import * as logger from '../utils/logger';
import * as auth from './auth';
import { store } from '../data/store';
import { Polycast, Status, TableInfo } from '../vibe/vibe';
import { polycastJoin } from '../api/api';
import { getCurrentPath, setPath } from '../routes/routes';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeApp, getApp } from 'firebase/app';
import { getFirestore, doc, onSnapshot, DocumentSnapshot } from "firebase/firestore";


const CN : string = 'fbase';

// consider an object with docType, unsub, and docID if multiple at one time needed
const docUnsubs = {
  patron : null as Function | any,
  polycast : null as Function | any
}

export enum docTypes {
  patron = 'patron',
  polycast = 'polycast',
  producer = 'producer',
  club = 'club',
  vibeEvent = 'vibeEvent'
}

// TODO : understand apiKey best practices
const appOptions = {
  apiKey : "AIzaSyCbXSTDQsW_qe0-seGOzdDkozQoKmcss-w",
  appId : "1:444471483971:web:fb093bf689d37a75b5bef6",
  authDomain : "didigitaldanceclub.firebaseapp.com",
  databaseURL : "https://didigitaldanceclub.firebaseio.com",
  locationId : "us-central",
  measurementId : "G-C15JD3GRYL",
  messagingSenderId : "444471483971",
  projectId : "didigitaldanceclub",
  storageBucket : "didigitaldanceclub.appspot.com"
};

let hasStarted : boolean = false;

export function start() : void {
  logger.log( CN + '.start' );

  if( !hasStarted ) {
    hasStarted = true;
    try {
      initializeApp( appOptions );
      // emulation if local
      window.location.hostname === 'localhost' && connectFunctionsEmulator( getFunctions( getApp() ), 'localhost', 5001 );
      logger.log( '🔥🔥🔥 Firebase 🔥🔥🔥 SDK loaded.' );
      auth.init();
    } catch( e ) {
      logger.error( e );
      logger.warn( 'Error loading the Firebase SDK, see above ^^^.' );
    }
  } else {
    logger.log( 'already started' );
  }
}

export function enterPolycast( tableInfo : TableInfo ) : any {
  console.log( CN + '.enterPolycast' );
  console.log( 'tableInfo :', tableInfo );

  if( !tableInfo ) return logger.warn( '\tno tableInfo' );

  const currentPath = getCurrentPath();
  console.log( 'currentPath :', currentPath );

  switch( true ) {
    case tableInfo.tableStatus !== Status.open :
    case tableInfo.seatStatus !== Status.approved :
      logger.warn( 'Either tableStatus or seatStatus do not meet requirements.' );
      break;
    case !tableInfo.eventURL || currentPath === tableInfo.eventURL :
      go();
      break;
    default :
      store.vibeEventUpdated.addOnce( go );
      setPath( tableInfo.eventURL );
  }

  function go() {
    console.log( CN + '.enterPolycast.go' );
    console.log( 'store.vibeEvent :', store.vibeEvent );

    polycastJoin( {
      patronID : store.user.uid,
      tableID : tableInfo.tableID
    } )
      .then( ( polycast : Polycast )  => {
        console.log( 'polycast : ', JSON.stringify( polycast, null, 2 ) );
        if( !polycast || !polycast.polycastID ) {
          logger.warn( 'polycastJoin failed  polycast returned :', polycast );
        } else {
          store.polycast = polycast;
          subscribeToDoc( docTypes.polycast, polycast.polycastID );
        }
      } )
      .catch( err => {
        logger.error( err );
      } );
  }
}

export function subscribeToDoc( docType : docTypes, docID : string, storeKey : string = '', shouldResubscribe : boolean = false ) : void {
  logger.log( `subscribeToDoc called with : \n\tdocType : ${ docType }\n\twith docID : ${ docID }` );

  try {
    const key : string = storeKey ? storeKey : docType;
    // http://localhost:5000/club/kqjLFsi7bQMkazHpdQj1
    if( typeof docUnsubs[ key ] !== 'function' || shouldResubscribe ) {
      docUnsubs[ key ] = onSnapshot( doc( getFirestore( getApp() ), docType + 's', docID ), ( docSnap : DocumentSnapshot ) => {
        // handle subsequent async cases
        if( docSnap.exists() ) {
          store[ key ] = docSnap.data();
        } else {
          console.warn( `subscribeToDoc found that document\n\tdocType : ${ docType }\n\twith docID : ${ docID }\n\tdoes not exist.` );
          store[ key ] = null;
        }
      } );
    } else {
      logger.warn( `already subscribed to firestore document\n\ttype : ${ docType }\n\tkey : ${ key }` );
    }
  } catch( err ) {
    logger.warn( CN + '.subscribeToDoc encountered an error!' );
    logger.error( err );
  }

}

export function unsubscribeFromDoc( storeKey : string ) : void {
  if( typeof docUnsubs[ storeKey ] === 'function' ){
    docUnsubs[ storeKey ]();
    docUnsubs[ storeKey ] = null;
    store[ storeKey ] = null;
  }
}

export function unsubscribeFromAll() : void {
  for( let docType in docUnsubs ){
    unsubscribeFromDoc( docType as docTypes );
  }
}
