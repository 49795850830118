import { store } from '../../data/store';

import './DropTarget.less';
import * as logger from '../../utils/logger';
import { streamPanelCoordsChanged } from '../../relay/signalRelay';

export default function DropTarget( {
  id,
  coord
} ) {


  function dragEnded() {
    const panelBeingDragged : HTMLDivElement = document.querySelector( '.dragging' ) as HTMLDivElement;
    panelBeingDragged && panelBeingDragged.classList.remove( 'dragging' );
    const dropTargets = document.querySelectorAll( '.drop-target' );
    const streamPanels = document.querySelectorAll( '.panel-stream' );
    dropTargets.forEach( dropTarget => dropTarget.classList.remove( 'targeted' ) );
    streamPanels.forEach( streamPanel => streamPanel.classList.remove( 'targeted' ) );
    streamPanelCoordsChanged.dispatch();
  }

  return (
    <div
      id={id}
      className="drop-target"
      data-coord={coord}
      onDragEnter={ e => {
        ( e.target as HTMLElement ).classList.add( 'targeted' );
        e.preventDefault();
      } }
      onDragOver={ e => {
        ( e.target as HTMLElement ).classList.add( 'targeted' );
        e.preventDefault();
      } }
      onDragLeave={ e => {
        ( e.target as HTMLElement ).classList.remove( 'targeted' );
      } }
      onDrop={ e => {
        store.sessionData.streamPanelDragged.setAttribute( 'data-coord', ( e.target as HTMLElement ).getAttribute( 'data-coord' ) as string );
        dragEnded();
      } }
    />
  );
}

