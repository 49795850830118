import { useEffect, useRef } from 'react';
import { limits } from '../../vibe/vibe';
import { ChatMsg, RelayPayload, RelayTypes, useP2Prelay } from '../../relay/p2pRelay';
import * as logger from '../../utils/logger';
import { store } from '../../data/store';
import ChatMessage from '../ChatMessage/ChatMessage';
import './ChatPanelMini.less';
import Input from '../Input/Input';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

const CN = 'ChatPanelMini';

const msgs : string[] = [
  'Hello beautiful people!!',
  'Feeling these beats - down to my soul!!',
  'viiiiiiiiibalicious - yes please!',
  'I can\'t feel my lips...',
  'Is it just me or does this feel like Friday night right now??',
  'JUST WHAT THE DOCTOR ORDERED Rx Rx Rx Rx Rx!',
  'welcome home shining stars - let\'s shake this Earth ~~~',
  'F U C K  Y E S',
  'I am picking up your vibrations down to the atom - keep it coming into the niiiiiiight...',
  'Hey dirtybird!! So glad you are flocking here and now!  SHAKE IT.!.!.!',
  'starCore imma try some of your fly moves tonight - stay tuned [ hopes he pulls it off! ]',
  'That looks like such a beautiful place!!  Wish I was there dancing with you right now - gorgeous!!',
  'I dig your ink - that sleeve is right on!',
  'No one do the flow like yo do with the mojo!',
  'Welcome to my mojo dojo.'
];

let index : number = -1;

function getNextMsg() {
  index++;
  if( index >= msgs.length ) index = 0;
  return msgs[ index ];
}

interface props {
  chatMsgs : ChatMsg[],
  classNames : string,
  patronID : string,
  fauxName? : string
}

export default function ChatPanelMini( {
  chatMsgs,
  classNames,
  patronID,
  fauxName = ''
} : props ) {

  logger.log( CN + ' rendering...' );

  // refs
  const chatPanelRef = useRef( null as HTMLDivElement | null );

  const{ relayPayload } = useP2Prelay( useAudioVideo() );

  useEffect( () => {
    if( !fauxName ) return;

    const payload : RelayPayload = {
      type : RelayTypes.ChatMsg,
      data : {
        from : patronID,
        fromName : fauxName,
        to : [ patronID ],
        msg : getNextMsg()
      } as ChatMsg
    };
    window.setTimeout( () => relayPayload( payload ), ( Math.random() * 20000) + 1000 );

  }, [] );

  useEffect( () => {
    const allowance : number = 5;
    const chatPanelDiv = chatPanelRef.current as HTMLDivElement;
    const lastMsg = chatPanelDiv.lastChild as HTMLDivElement;
    const lastMsgHeight : number = lastMsg ? lastMsg.clientHeight : 0;

    const shouldScroll : boolean = chatPanelDiv.scrollHeight - chatPanelDiv.clientHeight - chatPanelDiv.scrollTop <= lastMsgHeight + allowance;

    if( shouldScroll && chatPanelDiv ){
      chatPanelDiv.scrollTop = chatPanelDiv.scrollHeight;
    }
  } );

  return <div
    className={`chat-panel-mini ${ classNames }`.trim() }
    onTransitionEnd={ focusIfDisplayed }
  >
    <div
      ref={chatPanelRef}
      className="chat-panel"
    >
      { getFilteredMsgs( chatMsgs ) }
    </div>
    <div className="footer">
      <Input
        classNames={ 'chat' }
        fieldName={'msg'}
        maxLength={ limits.chatMsg }
        placeholder={ store.stringTable.sendMessage }
        defaultValue={ null }
        onKeyDown={ onKeyDown }
      />
    </div>
  </div>;

  function focusIfDisplayed( e ) {
    const div : HTMLDivElement = e.target as HTMLDivElement;

    if( !div ) return;

    switch( true as boolean ) {
      case !div :
        return;
      case div.clientHeight > 0 && div.clientWidth > 0 :
        const input = div.querySelector( 'input' );
        input && input.focus();
        break;
    }
  }

  function onKeyDown( e ) : void {
    switch( true ) {
      case e.key.toLowerCase() !== 'enter' :
      case !e.target.value.trim() :
        return;
      default :
        const msg = e.target.value.trim();
        e.target.value = '';
        const payload : RelayPayload = {
          type : RelayTypes.ChatMsg,
          data : {
            from : store.patron.patronID,
            fromName : store.patron.name,
            to : [ patronID ],
            msg : msg
          } as ChatMsg
        };
        relayPayload( payload );
    }
  }

  function getFilteredMsgs( msgs ) : any{
    return msgs.map( ( chatMsg : ChatMsg, i : number ) => {
      switch( true as boolean ) {
        case !chatMsg || !chatMsg.to :
          return null;
        case chatMsg.from === store.patron.patronID && chatMsg.to[ 0 ] === patronID :
        case chatMsg.to[ 0 ] === store.patron.patronID && chatMsg.from === patronID :
            return <ChatMessage key={i} isMini={ true } chatMsg={ chatMsg } />
      }
    } );
  }
}
