import { useEffect, useRef } from 'react';
import { nodeListToArray } from '../../utils/utils';
import * as logger from '../../utils/logger';

import './Carousel.less';

interface props {
  tileElements : any[],
  onLastItem : Function,
  onFirstItem : Function
}

export default function Carousel( {
  tileElements = [],
  onLastItem,
  onFirstItem
} : props ) {

  const inTransition = useRef( false );
  const stageRef = useRef( null as any );

  useEffect( () => {
    if( !stageRef.current ) return;

    const items = nodeListToArray( stageRef.current.children );

    items.find( ( item, i : number ) => {
      item && item.classList.add( 'tile' );
      switch( i ) {
        case 0 :
          item && item.classList.add( 'stage-left' );
          break;
        case 1 :
          item && item.classList.add( 'stage-center' );
          break;
        case 2 :
          item && item.classList.add( 'stage-right' );
          break;
        case 3 :
          item && item.classList.add( 'offstage-right' );
          break;
        default :
          item && item.classList.add( 'offstage-left' );
      }

    } );
    resetCarousel();
  }, [ tileElements ] )

  return <div className="carousel">
    <div className="btn-arrow left"
      onClick={ transitionRight }
    />
    <div className="btn-arrow right"
      onClick={ transitionLeft }
    />
    <div className="stage"
      ref={ stageRef }
      onClick={ stageClicked }
    >
      { getTiles() }
    </div>

  </div>

  function getTiles() {
    return tileElements.map( tileElement => <div className={'tile'}>{ tileElement }</div>)
  }

  function stageClicked( e ) {
    switch( true ) {
      case !e.target :
      case !e.target.classList.contains( 'tile' ) :
        break;
      case e.target.classList.contains( 'stage-left' ) :
        transitionRight();
        break;
      case e.target.classList.contains( 'stage-right' ) :
        transitionLeft();
        break;
      case e.target.classList.contains( 'stage-center' ) :
        centerTileClicked( e.target );
        break;
    }
  }

  function centerTileClicked( centerTile ) {
    logger.log( 'centerTileClicked' );
    logger.log( '\tcenterTile :', centerTile );
  }

  function transitionLeft() : void {
    if( inTransition.current ) return;
    inTransition.current = true;

    const offstageTiles = nodeListToArray( stageRef.current.querySelectorAll( '.tile.offstage-left' ) );
    offstageTiles.find( tile => {
      tile.className = 'no-transition tile offstage-right';
    } );

    const stageLeft = stageRef.current.querySelector( '.stage-left' );
    const stageCenter = stageRef.current.querySelector( '.stage-center' );
    const stageRight = stageRef.current.querySelector( '.stage-right' );
    const offStage = getTileToTheRight( stageRight );

    stageLeft && stageLeft.addEventListener( 'transitionend', resetCarousel );

    stageLeft && ( stageLeft.className = 'tile offstage-left' );
    stageCenter && ( stageCenter.className = 'tile stage-left' );
    stageRight && ( stageRight.className = 'tile stage-center' );
    offStage && ( offStage.className = 'tile stage-right' );
  }

  function transitionRight() : void {
    if( inTransition.current ) return;
    inTransition.current = true;

    const offstageTiles = nodeListToArray( stageRef.current.querySelectorAll( '.tile.offstage-right' ) );
    offstageTiles.find( tile => {
      tile.className = 'no-transition tile offstage-left';
    } );

    const stageLeft = stageRef.current.querySelector( '.stage-left' );
    const stageCenter = stageRef.current.querySelector( '.stage-center' );
    const stageRight = stageRef.current.querySelector( '.stage-right' );
    const offStage = getTileToTheLeft( stageLeft );

    stageRight && stageRight.addEventListener( 'transitionend', resetCarousel );

    stageLeft && ( stageLeft.className = 'tile stage-center' );
    stageCenter && ( stageCenter.className = 'tile stage-right' );
    stageRight && ( stageRight.className = 'tile offstage-right' );
    offStage && ( offStage.className = 'tile stage-left' );
  }

  function getTileToTheRight( refTile ){
    let nextIndex = -1;
    const tiles = nodeListToArray( stageRef.current.querySelectorAll( '.tile' ) );
    tiles.find( ( tile, i ) =>{
      if( tile === refTile ){
        nextIndex = i + 1;
        return true;
      }
    } );
    return nextIndex >= tiles.length ? tiles[ 0 ] : tiles[ nextIndex ];
  }

  function getTileToTheLeft( refTile ){
    let nextIndex = -1;
    const tiles = nodeListToArray( stageRef.current.querySelectorAll( '.tile' ) );
    tiles.find( ( tile, i ) =>{
      if( tile === refTile ){
        nextIndex = i - 1;
        return true;
      }
    } );
    return nextIndex < 0 ? tiles[ tiles.length - 1 ] : tiles[ nextIndex ];
  }

  function resetCarousel() {
    let offstageTiles = nodeListToArray( stageRef.current.querySelectorAll( '.tile.offstage-right' ) );
    offstageTiles.find( tile => {
      tile.removeEventListener( 'transitionend', resetCarousel );
      tile.className = 'no-transition tile offstage-right';
    } );

    offstageTiles = nodeListToArray( stageRef.current.querySelectorAll( '.tile.offstage-left' ) );
    offstageTiles.find( tile => {
      tile.removeEventListener( 'transitionend', resetCarousel );
      tile.className = 'no-transition tile offstage-left';
    } );

    inTransition.current = false;
  }

}
