import "./DialogTipTheStar.less";
import DialogHeader from '../DialogHeader/DialogHeader';
import Input, { InputTypes } from '../Input/Input';
import ButtonVibe from '../ButtonVibe/ButtonVibe';
import { useState, ChangeEvent, FocusEvent } from 'react';
import { mojoSpend } from '../../api/api';
import { MojoTxn, Roles } from '../../vibe/vibe';
import { store } from '../../data/store';
// import * as logger from '../../utils/logger';
import { useP2Prelay, RelayPayload, RelayTypes, TipEvent } from '../../relay/p2pRelay';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { dialogIDs } from '../../data/consts';

const CN : string = "DialogTipTheStar";

export default function DialogTipTheStar() {

  /*
   * TODO : tipping _animation_ will only work once a meeting has started, q?
   */

  const [ tipAmount, setTipAmount ] = useState( 1 );

  const { relaySignals, relayPayload } = useP2Prelay( useAudioVideo() );

  return <div className="dialog-tip-the-star">
    <DialogHeader title={`Tip the DJ!`}/>
    <div className="body">
      <div className="tipper">
        <label>tip</label>
        <Input
          fieldName={ 'tipAmount' }
          type={ InputTypes.number }
          min={ 2 }
          max={ store.patron.mojoBalance }
          step={ 1 }
          defaultValue={ tipAmount }
          placeholder={ "1" }
          onChange={ tipAmountChanged }
          onBlur={ tipAmountBlurred }
        />
        <label>mojo</label>
      </div>
    </div>
    <div className="footer">
      <ButtonVibe label={'Tip Now'} onClick={ tipTheStar }/>
    </div>
  </div>;

  function tipAmountBlurred( e : FocusEvent<HTMLInputElement> ) : void {
    const input = e.target;
    if( parseInt( input.value, 10 ) > store.patron.mojoBalance ){
      input.value = store.patron.mojoBalance;
    }
  }

  function tipAmountChanged( e : ChangeEvent<HTMLInputElement> ) : void {
    const newTipAmount : number = Math.round( parseFloat( e.target.value || '1' ) );
    setTipAmount( newTipAmount <= 19 ? newTipAmount : 19 );
  }

  function tipTheStar() : void {
    console.log( CN + '.tipTheStar' );
    console.log( 'tipAmount :', tipAmount );

    store.dialogToDisplay = dialogIDs.spinner;

    const payload : RelayPayload = {
      type : RelayTypes.TipEvent,
      data : {
        from : store.patron.patronID,
        to : [],
        tipAmount : tipAmount
      } as TipEvent
    };

    const mojoTxn : MojoTxn = {
      mojoTxnID : '',
      eventID : store.vibeEvent && store.vibeEvent.eventID ? store.vibeEvent.eventID : 'alphaEvent',
      date : Date.now(),
      source : {
        role : Roles.patron,
        id : store.patron.patronID
      },
      target : {
        role : Roles.star,
        id : store.vibeEvent && store.vibeEvent.star && store.vibeEvent.star.starID ? store.vibeEvent.star.starID : 'alphaStar'
      },
      mojoIDs : [],
      cashValue : 0,
      mojoCount : tipAmount
    };

    mojoSpend( mojoTxn )
      .then( result => {
        relayPayload( payload );
        store.dialogToDisplay = '';
      } )
      .catch( err => {
        console.error( err );
        store.dialogToDisplay = '';
      } );
  }
}
