import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as logger from './js/utils/logger';
import * as responsive from './js/utils/responsive';
import { store } from './js/data/store';
import * as fbase from './js/fbase/fbase';
import { whenDOMready, getEnv, startSyncRefresh } from './js/utils/utils';
import * as route from './js/routes/routes';
import { populateStringTable } from './js/loc/loc';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as ecomm from './js/ecomm/ecomm';

import AppVibe from './js/components/AppVibe/AppVibe';

import './css/app.less';
import { ThemeProvider } from 'styled-components';
import { darkTheme, MeetingProvider } from 'amazon-chime-sdk-component-library-react';
import { LogLevel } from 'amazon-chime-sdk-js';
import { getFile } from './js/utils/utils';

// private
const CN : string = '[index.tsx]';

const stripePromise = loadStripe('pk_test_l8uI6sbjZGUkyNuhrP2ctjuC0020lDVNaI');

function startApp() {
  ReactDOM.render(
    <StrictMode>
      <Elements stripe={ stripePromise }>
        <ThemeProvider theme={ darkTheme }>
          <MeetingProvider logLevel={ LogLevel.WARN /*INFO*/ }>
            <AppVibe/>
          </MeetingProvider>
        </ThemeProvider>
      </Elements>
    </StrictMode>,
    document.getElementById('root')
  );
}

function domReady() {
  logger.log( CN + '.domReady' );

  if( getEnv() === 'dev' ){
    startSyncRefresh();
  }

  getFile( 'buildtime.txt', ( buildtime ) => {
    logger.tracer( `BUILD : ${ buildtime }` );
  } );

  populateStringTable( 'en' );
  fbase.start();
  store.appModeUpdated.addOnce( startApp );
  responsive.start( breakpoint => store.breakpoint = breakpoint );
  route.start();
  ecomm.start();
}

// entry point
whenDOMready( domReady );

