import { store } from '../../data/store';
import DialogHeader from '../DialogHeader/DialogHeader';

import './DialogTableSettings.less';

const CN : string = 'DialogTableSettings';

export default function DialogTableSettings() {
  return (
    <form className="dialog-table-settings">
      <DialogHeader onCloseClick={ cancel }/>
      <div className="body">

      </div>
      <div className="footer">
        <button
          className="btn-cancel"
          onClick={ cancel }
        >{store.stringTable.cancel}</button>
        <button
          className="btn-save"
          onClick={ save }
        >{store.stringTable.save}</button>
      </div>
    </form>
  );

  function cancel() {
    store.dialogToDisplay = '';
  }

  function save() {

  }
}

