import * as logger from '../../utils/logger';
import FileUploader from '../FileUploader/FileUploader';
import Input from '../Input/Input';
import InputTags from '../InputTags/InputTags';
import SocialBar, {socialBarTypes} from '../SocialBar/SocialBar';
import {defaultStorage, dialogIDs, fileFilters} from '../../data/consts';
import {blurDelayWrapper, deepCloneUsingJSON, getSlicedArray, getSlicedString} from '../../utils/utils';
import { getFile} from '../../fbase/fbaseUtils';
import { limits, Patron, Profile} from '../../vibe/vibe';
import {patronUpdate} from '../../api/api';
import {store} from '../../data/store';
import {useRef, FocusEvent, Fragment, useState} from 'react';

import SpinnerModal from '../Spinners/SpinnerModal';

import './PatronProfilePanel.less';

interface props {
    patron : Patron
}

enum fields {
    coverImg = 'coverImg',
    profileImg = 'profileImg',
    name = 'name',
    tagLine = 'tagLine',
    blurb = 'blurb',
    tags = 'tags'
}

const emptyPatron : Patron = {
    patronID: '',
    accountID: '',
    mojoBalance: 0,
    name: '',
    profile : {
        coverImg : '',
        profileImg : '',
        tagLine : '',
        blurb : '',
        linkURLs : {}
    }
};

const CN: string = 'PatronProfilePanel';

export default function PatronProfilePanel( { patron } : props ) {
    // hooks
    const [coverImg, setCoverImg] = useState('');
    const [profileImg, setProfileImg] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingCover, setIsEditingCover] = useState(false);
    const [isEditingPortfolioImg, setIsEditingPortfolioImg] = useState(false);
    const [errorMsgs, setErrorMsgs] = useState({
        [fields.coverImg]: '',
        [fields.profileImg]: '',
        [fields.name]: '',
        [fields.tagLine]: '',
        [fields.blurb]: '',
        [fields.tags]: ''
    });

    if ( !store.sessionData.patron || !store.sessionData.patron.patronID ) {
        clonePatronToSessionData();
    }

    const storedPatron = store.sessionData.patron as Patron;

    const cloudCoverImg = storedPatron && storedPatron.profile && storedPatron.profile.coverImg ?
      unescape( storedPatron.profile.coverImg.split( '?' )[ 0 ].split( '/' )[ 1 ].toString() ) : 'noImage';

    const cloudProfileImg = storedPatron && storedPatron.profile && storedPatron.profile.profileImg ?
        unescape( storedPatron.profile.profileImg.split( '?' )[ 0 ].split( '/' )[ 1 ].toString() ) : 'noImage';

    if (storedPatron.profile && storedPatron.profile.coverImg && cloudCoverImg !== coverImg ) {
        getFile(storedPatron.profile.coverImg)
            .then(url => {
                setCoverImg(url);
            })
            .catch(err => logger.error(err));
    }

    if (storedPatron.profile && storedPatron.profile.profileImg && cloudProfileImg !== profileImg) {
        getFile(storedPatron.profile.profileImg)
            .then(url => {
                setProfileImg(url);
            })
            .catch(err => logger.error(err));
    }

    const btnProfileRef: any = useRef( null );
    const btnCoverRef: any = useRef( null );

    //user exits then you want to reset all of the data, and reupdate sessionData.myClub to the one from the database
    function reset(): void {
        store.hasUnsavedData = false;
        clonePatronToSessionData();
        setIsEditing(false);
    }

    function clonePatronToSessionData(): any {
        store.sessionData = {
            patron: {
                ...deepCloneUsingJSON( patron ? patron : emptyPatron )
            }
        };
    }

    if( !store.sessionData.patron.patronID ) return <SpinnerModal/>;

    return (
        <div className='panel-profile-patron'>
            { getProfilePanel() }
            { getSchedulePanel() }
        </div>
    );

//region helper functions
    function resetErrorMsg(fieldName: fields): void {
        setErrorMsgs({
            ...errorMsgs,
            [fieldName]: ''
        });
    }

    function inputValueChanged(e: any) {
        const patronSnapshot = store.sessionData.patron;
        const propName: fields = e.target.getAttribute('data-prop') as fields;

        switch (propName) {
            case fields.name :
                store.sessionData = {
                    patron: {
                        ...store.sessionData.patron,
                        name: e.target.value
                    } as Patron
                };
                break;
            default :
                store.sessionData = {
                    patron: {
                        ...patronSnapshot,
                        profile: {
                            ...patronSnapshot.profile,
                            [propName]: e.target.value
                        }
                    } as Patron
                };
        }

        resetErrorMsg(propName);
    }

    function onTagsChanged(newTags: string[]): void {
        const patronSnapshot = store.sessionData.patron;

        newTags.find((tag: string, i: number) => {
            newTags[i] = tag.trim();
        });
        store.sessionData = {
            patron: {
                ...patronSnapshot,
                tags: newTags,
                profile: {
                    ...patronSnapshot.profile
                }
            } as Patron
        };
    }

    function getErrorMsgs(e: FocusEvent<HTMLInputElement>) {
        const propName: fields = e.target.getAttribute('data-prop') as fields;

        let isCoverImgValid: boolean = true;
        let isProfileImgValid: boolean = true;

        const isDisplayNameValid: boolean = (
            ((fields.name.length <= 50)
                && (fields.name.length >= 1)
                && (fields.name.indexOf(' ') <= 0))
        );

        let isTagLineValid: boolean = true;
        if (fields.tagLine.length >= 300) {
            isTagLineValid = false;
        }

        let isBioValid: boolean = true;
        if (fields.blurb.length >= 300) {
            isBioValid = false;
        }

        let areTagsValid: boolean = true;

        if (fields.tags.length >= 10) {
            areTagsValid = false;
        }

        const newErrorMessages = {
            coverImg: isCoverImgValid ? '' : 'file must be less than 5MB and a PNG, JPG or GIF',
            profileImg: isProfileImgValid ? '' : 'file must be less than 5MB and a PNG, JPG or GIF',
            name: isDisplayNameValid ? '' : 'display name must be less than 50 characters with no whitespace',
            tagLine: isTagLineValid ? '' : 'tag line is limited to 300 chars',
            blurb: isBioValid ? '' : 'bio is limited to 300 chars',
            tags: areTagsValid ? '' : 'comma separated, max of five'
        };
        setErrorMsgs({...errorMsgs, [fields[propName]]: newErrorMessages[propName]});
    }

    function newCoverImgReceived({file, path}) {
        if (!path) return logger.warn(CN + '.newCoverImgReceived received an invalid path : ', path);

        storedPatron.profile = storedPatron.profile || {};
        storedPatron.profile.coverImg = path;
        getFile(path)
            .then(url => {
                setCoverImg(url);
            })
            .catch(err => logger.error(err));
        savePatronProfile();
    }

    function removeCoverImg(){
        storedPatron.profile = storedPatron.profile || {};
        const path = storedPatron.profile.coverImg || '';
        storedPatron.profile.coverImg = defaultStorage.patronCoverImg;
        savePatronProfile();
        setIsEditingCover(false);
    }

    function newProfileImgReceived({file, path}) {
        if (!path) return logger.warn(CN + '.newProfileImgReceived received an invalid path : ', path);

        storedPatron.profile = storedPatron.profile || {};
        storedPatron.profile.profileImg = path;
        getFile(path)
            .then(url => {
                setProfileImg(url);
            })
            .catch(err => logger.error(err));
        setIsEditingPortfolioImg( false );
        savePatronProfile();
    }

    function removeProfileImg(){
        storedPatron.profile = storedPatron.profile || {};
        const path = storedPatron.profile.profileImg || '';
        storedPatron.profile.profileImg = defaultStorage.patronProfileImg;
        savePatronProfile();
        setIsEditingPortfolioImg(false);
    }

    function savePatronProfile() {
        const patronSnapshot = deepCloneUsingJSON(store.sessionData.patron);
        const profile = patronSnapshot.profile;

        // normalize and clamp
        patronSnapshot.name = getSlicedString(patronSnapshot.name, limits.name);
        patronSnapshot.tags = getSlicedArray(patronSnapshot.tags, limits.tags);
        profile.coverImg = getSlicedString(profile.coverImg, limits.maxString);
        profile.profileImg = getSlicedString(profile.profileImg, limits.maxString);
        profile.tagLine = getSlicedString(profile.tagLine, limits.tagLine);
        profile.blurb = getSlicedString(profile.blurb, limits.blurb);
        profile.linkURLs = {
            instagram : profile.linkURLs?.instagram || '',
            facebook : profile.linkURLs?.facebook || '',
            soundcloud : profile.linkURLs?.soundcloud || '',
            spotify : profile.linkURLs?.spotify || '',
            twitch : profile.linkURLs?.twitch || '',
            twitter : profile.linkURLs?.twitter || '',
            youtube : profile.linkURLs?.youtube || '',
            web : profile.linkURLs?.web || '',
        };

        store.dialogToDisplay = dialogIDs.spinner;

        patronUpdate(patronSnapshot)
            .then( ( updatedPatron : Patron ) => {
                store.dialogToDisplay = '';
                store.hasUnsavedData = false;
                clonePatronToSessionData();
                store.sessionData = {
                    patron : {
                        ...deepCloneUsingJSON( updatedPatron )
                    }
                };

                store.patron = updatedPatron;
                setIsEditing( false );
            })
            .catch(err => {
                store.dialogToDisplay = '';
                logger.error(err);
            });
    }

    function cancel() {
        reset();
    }

    function getProfilePanel() {
        // @ts-ignore
        return <div className='profile-panel'>
            <h3 className={'left'}>{isEditing ? 'Edit Patron' : 'Edit Patron'}</h3>
            <div
                className='panel-img-cover'
                style={{
                    backgroundImage: coverImg ? `url(${coverImg})` : ''
                }}>
                <button
                    className='btn-edit-cover'
                    onBlur={blurDelayWrapper(() => setIsEditingCover(false))}
                    onClick={() => {
                        setIsEditingCover(!isEditingCover)
                    }}
                >
                    Edit Cover
                </button>
                <FileUploader
                    accepts={fileFilters.allWebImages}
                    btn={btnCoverRef.current}
                    onFileSelected={newCoverImgReceived}
                    folderName={"cover_imgs"}
                />
                <div className={`menu menu-img-cover${isEditingCover ? ' displayed' : ''}`}>
                    <div
                        ref={btnCoverRef}
                        className='menu-item upload'
                        tabIndex={0}>Upload New Cover
                    </div>
                    <div
                        className='menu-item trash'
                        onClick={removeCoverImg}
                        tabIndex={0}>Remove Cover Photo
                    </div>
                </div>
                {!isEditing &&
                <button
                    className='btn-edit-profile'
                    onClick={() => {
                        store.hasUnsavedData = true;
                        setIsEditing(!isEditing);
                    }}>
                    Edit Profile
                </button>
                }
            </div>
            <div
                className='profile-img'
                style={{backgroundImage: profileImg ? `url(${profileImg})` : ''}}>
                { /* TODO : the backgroundImage above will be dynamic */}
                <button
                    className='btn-edit-profile-img'
                    onClick={() => {
                        setIsEditingPortfolioImg(!isEditingPortfolioImg);
                    }}
                    tabIndex={0}/>

                <div className={`menu menu-img-profile ${ isEditingPortfolioImg ? ' displayed' : '' }`}>
                    <div
                        ref={btnProfileRef}
                        className='menu-item upload'
                        tabIndex={0}>Upload New Photo
                    </div>
                    <FileUploader
                      accepts={fileFilters.allWebImages}
                      btn={btnProfileRef.current}
                      onFileSelected={newProfileImgReceived}
                      folderName={"profile_imgs"}
                    />
                    <div
                        className='menu-item trash'
                        onClick={removeProfileImg}
                        tabIndex={0}>Remove Profile Photo
                    </div>
                </div>

            </div>
            {!isEditing && <h2>{storedPatron.name || 'Your Name'}</h2>}
            {isEditing &&
            <Input
                fieldName={fields.name}
                placeholder={'your name'}
                maxLength={50}
                onChange={inputValueChanged}
                onBlur={getErrorMsgs}
                errorMsgs={errorMsgs}
                snapshot={storedPatron}/>
            }
            {!isEditing && <h4>{(storedPatron.profile as Profile).tagLine || 'your tag line'}</h4>}
            {isEditing &&
            <Input
                fieldName={fields.tagLine}
                placeholder={'your philosophy in one line'}
                maxLength={50}
                onChange={inputValueChanged}
                onBlur={getErrorMsgs}
                errorMsgs={errorMsgs}
                snapshot={storedPatron.profile}/>
            }
            {!isEditing && <h3>{(storedPatron.profile as Profile).blurb || 'who are you?'}</h3>}
            {isEditing &&
            <Fragment>
              <textarea
                  defaultValue={(storedPatron.profile as Profile).blurb}
                  data-prop={'blurb'}
                  maxLength={300}
                  className={'input-bio'}
                  placeholder={'tell us all something about you'}
                  onChange={inputValueChanged}
                  tabIndex={0}
                  rows={3}/>
                <p className={'left'}>max of 300 characters</p>
            </Fragment>
            }
            <SocialBar
                type={socialBarTypes.patron}
                isEditing={isEditing}
            />
            {isEditing &&
            <InputTags
                tags={storedPatron.tags || []}
                onTagsChanged={onTagsChanged}
            />
            }
            {isEditing &&
            <div className='footer'>
                <button
                    id={'btn-cancel'}
                    className={'btn-cancel'}
                    onClick={cancel}>Cancel
                </button>
                <button
                    id={'btn-save'}
                    className={'btn-save'}
                    onClick={savePatronProfile}>Save Club Profile
                </button>
            </div>
            }
        </div>;
    }

    function getSchedulePanel() {
        return <div className='schedule-panel'>
            <h2>Upcoming</h2>
            <div className='grid-calendar'>
                <button
                    className='btn btn-calendar-today'>
                    Today
                </button>
                <div className='grid-buttons'>
                    <div className={'grid-buttons2'}>
                        <button
                            className='btn-calendar-left-arrow'/>
                        <button
                            className='btn-calendar-right-arrow'/>
                        <button
                            className='btn-calendar-calendar'/>
                        <p>Jan 4, 2021 - Jan 10, 2021</p>
                    </div>
                </div>
                <div className='grid-time-zone'>PST</div>
                <div className='grid-time-times'>time line</div>
                <div className='row-mon'>
                    Mon
                </div>
                <div className='row-tues'>
                    Tues
                </div>
                <div className='row-wed'>
                    Wed
                </div>
                <div className='row-thurs'>
                    Thurs
                </div>
                <div className='row-fri'>
                    Fri
                </div>
                <div className='row-sat'>
                    Sat
                </div>
                <div className='row-sun'>
                    Sun
                </div>
            </div>
        </div>;
    }

//endregion
}
