import { store } from "../../data/store";
import { Table } from "../../vibe/vibe";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ChangeEvent, FocusEvent, MouseEvent, useState } from "react";
import Input, { InputTypes } from "../Input/Input";
import InputCheckbox from "../InputCheckbox/InputCheckbox";
import { getURLsafe } from "../../utils/utils";
import DialogHeader from "../DialogHeader/DialogHeader";
import InputTags from "../InputTags/InputTags";

import "./DialogCreateTable.less";
import InvitePanel from '../InvitePanel/InvitePanel';
import ButtonVibe from '../ButtonVibe/ButtonVibe';

enum fields {
  name = "name",
  desc = "desc",
  urlSafeName = "urlSafeName",
  canExtendTime = "canExtendTime",
  tags = "tags",
  startTime = "startTime",
  endTime = "endTime",
  donation = "donation",
  duration = "duration"
}

let isURLsafeEdited : boolean = false;

const CN : string = "DialogCreateTable";

export default function DialogCreateTable() {
  const [ duration, setDuration ] = useState( 2 );
  const [ donation, setDonation ] = useState( 0 );
  const [ tags, setTags ] = useState( [] as string[] );
  const [ autoExtends, setAutoExtends ] = useState( true );
  const [ errorMsgs, setErrorMsgs ] = useState( {
    [ fields.name ] : "",
    [ fields.desc ] : "",
    [ fields.urlSafeName ] : ""
  } );

  const now : Date = new Date();
  const mins = now.getMinutes();
  const quarterHours = Math.round( mins / 15 );
  if( quarterHours === 4 ) {
    now.setHours( now.getHours() + 1 );
  }
  const rounded = ( quarterHours * 15 ) % 60;
  now.setMinutes( rounded );

  const later : Date = new Date( now );
  later.setHours( later.getHours() + 2 );

  const [ startTime, setStartTime ] = useState( now as Date | any );
  const [ endTime, setEndTime ] = useState( later as Date | any );

  const snapshot = {
    ...store.sessionData.dialogCreateTable
  };

  return ( <div className="dialog-create-table">
    <DialogHeader title={`Create New Table`}/>
    <div className="body">
      <Input
        fieldName={ fields.name }
        placeholder={ store.stringTable.tableName }
        isRequired={ true }
        onChange={ inputValueChanged }
        onBlur={ getErrorMsgs }
        errorMsgs={ errorMsgs }
        snapshot={ snapshot }
      />
    <Input
      fieldName={ fields.desc }
      placeholder={ store.stringTable.tableDesc }
      maxLength={ 256 }
      onChange={ inputValueChanged }
      onBlur={ getErrorMsgs }
      errorMsgs={ errorMsgs }
      snapshot={ snapshot }
    />
    <Input
      fieldName={ fields.urlSafeName }
      placeholder={ store.stringTable.tableURLsafeName }
      isRequired={ true }
      onChange={ inputValueChanged }
      onBlur={ getErrorMsgs }
      errorMsgs={ errorMsgs }
      snapshot={ snapshot }
    />
    <InputTags
      tags={ [] }
      onTagsChanged={ ( newTags : string[] ) => {
        setTags( newTags );
      } }
    />
      <div className="date-pickers">
        <MuiPickersUtilsProvider utils={ DateFnsUtils }>
          <DateTimePicker
            minDate={ now }
            disablePast={ true }
            name={ "startTime" }
            label="starts"
            value={ startTime }
            onChange={ ( newStartTime ) => {
              const newEndTime = new Date( newStartTime as Date );
              newEndTime.setHours( newEndTime.getHours() + duration );
              setEndTime( newEndTime );
              setStartTime( newStartTime );
            } }
            minutesStep={ 15 }
          />
          <DateTimePicker
            disablePast={ true }
            minDate={ startTime }
            name={ "endTime" }
            label="ends"
            value={ endTime }
            onChange={ ( newEndTime ) => {
              setDurationFrom( startTime, newEndTime );
              setEndTime( newEndTime );
            } }
            minutesStep={ 15 }
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="duration">
        <Input
          fieldName={ fields.duration }
          type={ InputTypes.number }
          min={ 2 }
          max={ 48 }
          step={ 1 }
          defaultValue={ duration }
          placeholder={ "2" }
          onChange={ durationChanged }
        />
        <InputCheckbox
          label={ store.stringTable.timeCanExtend }
          onChange={  e => setAutoExtends( e.target.checked ) }
          isChecked={ autoExtends }
        />
      </div>
      <div className="donation">
        <Input
          fieldName={ fields.donation }
          type={ InputTypes.number }
          min={ 0 }
          max={ 1000 }
          step={ 1 }
          placeholder={ donation.toString() }
          onChange={ ( e ) => setDonation( parseInt( e.target.value, 10 ) ) }
        />
      </div>
      <div className="invites">
        <InvitePanel/>
      </div>
    </div>
    <div className="footer">
      <ButtonVibe
        label={ store.stringTable.createTable }
        onClick={ createButtonClicked }/>
    </div>
  </div> );

  function createButtonClicked( e : MouseEvent<HTMLButtonElement> ) {
    console.log( CN + ".createButtonClicked" );

    e.preventDefault();

    /*

    api.tableCreate( store.sessionData.dialogCreateTable )
      .then( data => {
        logger.log( data );
        store.dialogToDisplay = '';
      } )
      .catch( err => {
        logger.error( err );
      } );

     */
  }

  function resetErrorMsg( fieldName : fields ) : void {
    setErrorMsgs( {
      ...errorMsgs,
      [ fieldName ] : ""
    } );
  }

  function inputValueChanged( e : ChangeEvent<HTMLInputElement> ) : void {
    const propName : fields = e.target.getAttribute( "data-prop" ) as fields;
    let sessionData = store.sessionData.dialogCreateTable || {};
    let urlSafeName : string = "";

    // set the flag - has user directly edited urlSafeName?
    if( propName === fields.urlSafeName ) isURLsafeEdited = true;

    // auto-update urlSafeName if it hasn't been edited directly
    if( propName === fields.name && !isURLsafeEdited ) {
      urlSafeName = getURLsafe( e.target.value );
      sessionData = {
        ...sessionData,
        [ fields.urlSafeName ] : urlSafeName
      };

      const urlSafeInput : HTMLInputElement = window.document.querySelector( `[data-prop="${ fields.urlSafeName }"]` ) as HTMLInputElement;
      if( urlSafeInput ) urlSafeInput.value = urlSafeName;
    }

    if( propName === fields.startTime || propName === fields.endTime ) {
      store.sessionData = {
        dialogCreateTable : {
          ...sessionData,
          [ propName ] : new Date( e.target.value ).getTime() / 1000
        } as Table
      };
    } else {
      store.sessionData = {
        dialogCreateTable : {
          ...sessionData,
          [ propName ] : e.target.value !== "directive-use-checked" ? e.target.value : e.target.checked
        } as Table
      };
    }

    resetErrorMsg( propName );
  }

  function getErrorMsgs( e : FocusEvent<HTMLInputElement> ) {
    const propName : fields = e.target.getAttribute( "data-prop" ) as fields;

    const isNameValid : boolean = store.sessionData.dialogCreateTable &&
      store.sessionData.dialogCreateTable.name &&
      store.sessionData.dialogCreateTable.name.length <= 50;

    let isDescValid : boolean = true;
    if( store.sessionData.dialogCreateTable &&
      store.sessionData.dialogCreateTable.desc &&
      store.sessionData.dialogCreateTable.desc.length > 256 ) {
      isDescValid = false;
    }

    const isURLsafeNameValid : boolean = store.sessionData.dialogCreateTable && store.sessionData.dialogCreateTable.urlSafeName && store.sessionData.dialogCreateTable.urlSafeName.length <= 50 && store.sessionData.dialogCreateTable.urlSafeName === getURLsafe( store.sessionData.dialogCreateTable.urlSafeName );

    const newErrorMessages = {
      name : isNameValid ? "" : store.stringTable.errorMsgName,
      desc : isDescValid ? "" : store.stringTable.errorMsgDesc,
      urlSafeName : isURLsafeNameValid ? "" : store.stringTable.errorMsgURLsafe
    };
    setErrorMsgs( {
      ...errorMsgs,
      [ fields[ propName ] ] : newErrorMessages[ propName ]
    } );
  }

  function setDurationFrom( start, end ) {
    setDuration( Math.ceil( Math.abs( start - end ) / 36e5 ) );
  }

  function durationChanged( e : ChangeEvent<HTMLInputElement> ) : void {
    const newDuration : number = Math.round( parseFloat( e.target.value ) );
    const newEndTime = new Date( startTime as Date );
    newEndTime.setHours( newEndTime.getHours() + newDuration );

    setDuration( newDuration > 48 ? 48 : newDuration );
    setEndTime( newEndTime );
  }
}
