import DialogHeader from '../DialogHeader/DialogHeader';
import ButtonVibe from '../ButtonVibe/ButtonVibe';
import InputText from '../InputText/InputText';
import { useState, MouseEvent } from 'react';

import './DialogCreateEvent.less';
import InputTags from '../InputTags/InputTags';
import { eventCreate } from '../../api/api';


const CN : string = "DialogCreateEvent";

enum fields {
  name = 'name',
  desc = 'desc',
  tags = 'tags',
  lineup = 'lineup',
  clubID = 'clubID'
}

export default function DialogCreateEvent() {

  const [ state, setState ] = useState( {
    eventID : '',
    clubID : '',
    name : '',
    urlSafeName : '',
    desc : '',
    tags : [],
    startTime : 0,
    endTime : 0,
    stageIDs : [],
    lineup : [],      // Performance
    profile : {},     // Profile,
    venue : {},       // Venue
    star : {},        // Star
    eventStream : {}  // Stream
  } );

  return <div className="dialog-create-event">
    <DialogHeader title={`Create New Event`}/>
    <div className="body">
      <InputText
        fieldName={ fields.name }
        onChange={ e => state.name = e.target.value }
        label={ fields.name }
      />
      <InputText
        fieldName={ fields.clubID }
        onChange={ e => state.clubID = e.target.value }
        label={ fields.clubID }
      />
      <InputText
        fieldName={ fields.desc }
        onChange={ e => state.desc = e.target.value }
        label={ `description` }
      />
      <InputTags
        limit={ -1 }
        isLowercase={ false }
        label={ 'lineup : starIDs' }
        onTagsChanged={ newLineup => state.lineup = newLineup }
      />
      <InputTags
        limit={ 6 }
        onTagsChanged={ newTags => state.tags = newTags }
      />
    </div>
    <div className="footer">
      <ButtonVibe
        label={ 'Create Event' }
        onClick={ createButtonClicked }/>
    </div>
  </div>

  function createButtonClicked( e : MouseEvent<HTMLButtonElement> ) {
    console.log( CN + ".createButtonClicked" );

    e.preventDefault();
    console.log( 'state :', JSON.stringify( state, null, 2 ) );

    eventCreate( state )
      .then( response => {
        console.log( response );
      } )
      .catch( err => console.error( err ) );
  }

}
