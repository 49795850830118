import { Fragment, useState, useRef, useEffect } from 'react';
import { Coord, PresentationModes, Polycast } from '../../vibe/vibe';
import { store } from '../../data/store';
import { dialogIDs } from '../../data/consts';
import * as logger from '../../utils/logger';
import { useLocalVideo } from 'amazon-chime-sdk-component-library-react';
import { polycastSetMode } from '../../api/api';
import { PolycastModeChange, MimeTypes } from '../../vibe/vibe';

import './StreamPanelHUDlocal.less';

const CN : string = 'StreamPanelHUDlocal';

export default function StreamPanelHUDlocal() {

  // hooks
  const [ isAway, setIsAway ] = useState( false );
  const { toggleVideo } = useLocalVideo();

  const hudRef = useRef( null );

  useEffect( () => {
    store.isAwayUpdated.add( setIsAway );
    return () => {
      store.isAwayUpdated.remove( setIsAway );
    }
  }, [] );

  return (
    <div
      ref={ hudRef }
      className={ 'stream-panel-hud local' }>
      { getBtns() }
    </div>
  );

  // TODO : this is a quick for-demo function
  function setPolycastMode( mode : PresentationModes ) : void {
    console.log( CN + '.setPolycastMode' );

    const modeChange : PolycastModeChange = {
      patronID : store.patron.patronID,
      polycastID : store.polycast.polycastID,
      newMode : mode,
      isAway : mode !== PresentationModes.streamVideo
    };

    if( mode !== PresentationModes.streamVideo ){
      modeChange.mediaInfo = {
        mimeType : MimeTypes.mp4,
        mediaID : 'magic_fire',
          url : 'https://appcloud9.com/vid/magic_fire.mp4'
      }
    }

    polycastSetMode( modeChange )
      .then( ( updatedPolycast : Polycast ) => {
        store.polycast = updatedPolycast;
      } )
      .catch( err => logger.error( err ) );
  }

  function getBtns() {
    return <Fragment>
      <button
        data-coord={ Coord.a3 }
        className={ isAway ? 'icon-vid-camera off' : 'icon-vid-camera' }
        onClick={() => {
          store.isAway = !store.isAway;
          toggleVideo()
            .then( () => {
              logger.log( 'localVideo toggled' );
            } )
            .catch( err => logger.error( err ) );

          setPolycastMode( store.isAway ? PresentationModes.mediaVideo : PresentationModes.streamVideo );
        }}/>
      <button
        data-coord={Coord.e3}
        className={ `icon-cogwheel` }
        onClick={() => {
          store.dialogToDisplay = dialogIDs.dialogStreamSources;
        } }/>
    </Fragment>
  }
}

