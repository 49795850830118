import * as signals from 'signals';
import * as logger from '../utils/logger';
import { SVG, Svg, Path } from '@svgdotjs/svg.js';

const CN : string = 'utils';

export interface xyPoints {
  x : number,
  y : number
}

/**
 * Generate a no-op function.
 * @returns {function}
 */
export function noOp() : void {}

export function getSignal( name : string, logLevel = 1 ) : signals.Signal {
  let signal : signals.Signal = new signals.Signal();
  if( logLevel > 0 ) {
    signal.add( ( ...args ) : void => {
      logger.log( 'signal dispatched : ' + name );
      if( logLevel === 2 ) logger.log( '\twith args : ', { ...args } );
    } );
  }

  return signal;
}

export function getJSON( url : string, cb : Function ) : void {
  const req = new XMLHttpRequest();
  req.overrideMimeType( 'application/json' );
  req.onreadystatechange = () => {
    try {
      switch( true ) {
        case req.readyState !== 4 :
          return;
        case req.status !== 200 :
          throw new Error( req.statusText || `HTTP STATUS : ${ req.status }` );
        default :
          let res;
          try {
            res = JSON.parse( req.responseText );
          } catch( err ) {
            cb( err, null );
          }
          cb( null, res );
      }
    } catch( err ) {
      cb( err, null );
    }
  };
  req.open( 'GET', url, true );
  req.send( null );
}

export function toTitleCase( str : string ) : string {
  return str.replace(
    /\w\S*/g,
    function( txt : string ) {
      return txt.charAt( 0 ).toUpperCase() + txt.substr( 1 ).toLowerCase();
    }
  );
}

export function trimWhiteSpace( str : string ) : string {
  return str.replace( /^\s+|\s+$/g, '' );
}

export function addIframe( id : string, url : string, parentNode : HTMLElement, props : any = {} ) : HTMLIFrameElement {
  const iframe = document.createElement( 'iframe' ) as HTMLIFrameElement;
  iframe.id = id;
  iframe.src = url;

  Object.keys( props ).find( ( key ) => {
    iframe.setAttribute( key, props[ key ] );
    return false;
  } );

  parentNode.appendChild( iframe );
  return iframe;
}

export function removeElementByID( id : string, shouldDestroy : boolean = false ) : HTMLElement {
  const element = document.getElementById( id ) as HTMLElement;
  return removeElement( element, shouldDestroy );
}

export function removeElement( element : any, shouldDestroy : boolean = false ) : HTMLElement {
  console.log( CN + '.removeElement' );

  if( element && element.parentNode && element.parentNode.contains( element ) ) {
    console.log( '\telement :', element );
    try{
      element.parentNode.removeChild( element );
    }catch( err ){
      logger.warn( 'removeElement failed to removeChild - see error below' );
      logger.error( err );
    }
  } else {
    logger.warn( 'unable to removeElement from DOM' );
  }

  if( shouldDestroy && element ) {
    element.innerHTML = '';
    deleteElement( element );
  }

  return element;
}

export function deleteElement( element : HTMLElement ) : any {
  if( !element ) return console.error( 'no element to delete.' );

  const dustbin = document.getElementById( 'dustbin' ) as HTMLElement;
  if( !dustbin ) return console.error( 'no dustbin found' );

  dustbin.appendChild( element );
  dustbin.innerHTML = '';
}

export function deleteElementBySelector( selector : string, dustbinSelector = '#dustbin' ) : void {
  const elem = document.querySelector( selector ) as HTMLElement;
  const dustbin = document.querySelector( dustbinSelector ) as HTMLElement;

  switch( true ) {
    case dustbin === null :
      logger.warn( 'There is no element with the selector \'' + dustbinSelector + '\' - cannot remove element with selector :', selector );
      break;
    case elem === null :
      logger.warn( 'No element matched the selector : ', selector );
      break;
    default :
      dustbin.appendChild( elem );
      dustbin.innerHTML = '';
  }
}

export function addCSSlink( url : string ) : HTMLLinkElement {
  let link : HTMLLinkElement = document.createElement( 'link' ) as HTMLLinkElement;
  link.rel = 'stylesheet';
  link.href = url;
  document.head.appendChild( link );
  return link;
}

export function getKeyByEvent( e : KeyboardEvent ) : string {
  // falling back to keyCode for backwards compatibility. See https://caniuse.com/#feat=keyboardevent-code.
  if( e.code ) return e.code;
  switch( e.keyCode ) {
    case 8:
      return 'Backspace';
    case 46:
      return 'Delete';
    case 13 :
      return 'Enter';
    case 9 :
      return 'Tab';
  }
  return '';
}

export function addScriptTagTo(
  scriptURL : string,
  parentSelector : string = '',
  onLoad : any = null,
  isModule : boolean = false,
  isAsync : boolean = false,
  isDeferred : boolean = false ) : HTMLScriptElement {
  let script : HTMLScriptElement = document.createElement( 'script' ) as HTMLScriptElement;
  script.src = scriptURL;
  script.async = isAsync;
  script.defer = isDeferred;
  if( isModule ) {
    script.type = 'module';
  }
  if( typeof onLoad === 'function' ) {
    script.addEventListener( 'load', onLoad, false );
  }
  let parentNode = parentSelector ? document.querySelector( parentSelector ) : document.querySelector( 'body' );
  parentNode = parentNode ? parentNode : document.querySelector( 'body' );
  if( parentNode !== null ) {
    parentNode.appendChild( script );
  }
  return script;
}

export function display( element : HTMLElement ) : void {
  if( element && element.classList ) {
    element.classList.add( 'displayed' );
  }
}

export function dismiss( element : HTMLElement ) : void {
  if( element && element.classList ) {
    element.classList.remove( 'displayed' );
  }
}

export function toggleDisplay( element : HTMLElement ) : void {
  if( element && element.classList ) {
    element.classList.toggle( 'displayed' );
  }
}

/**
 * @function blurDelayWrapper
 * A `blur` event handler can result in click events being lost
 *
 * @param {Function} handler event handler to be delayed
 * @param {Number} delay in milliseconds
 */
export function blurDelayWrapper( handler : Function, delay : number = 150 ) {
  return e => {
    // see https://reactjs.org/docs/events.html#event-pooling
    e.persist();
    setTimeout( () => handler( e ), delay );
  };
}

export function getParams() : any {
  return window.location.search.slice( 1 ).split( '&' ).reduce( ( acc, cur ) => {
    acc[ cur.split( '=' )[ 0 ].toLowerCase() ] = cur.split( '=' )[ 1 ];
    return acc;
  }, {} );
}

export function getUTCtimeZoneString() : string {
  const utcOffset : number = new Date().getTimezoneOffset() / 60 * -1;
  return utcOffset > 0 ? `UTC+${ utcOffset }` : `UTC${ utcOffset }`;
}

export function getGUID() : string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, ( c ) => {
    let r = ( Math.random() * 16 ) | 0,
        v = c === 'x' ? r : ( r & 0x3 ) | 0x8;
    return v.toString( 16 );
  } );
}

export function getUTCtimeString( date : Date | null = null, hrsOffset : number = 0 ) : string {
  date = date === null ? new Date( Date.now() ) : date;
  const dateWithOffset : Date = new Date( date );
  dateWithOffset.setHours( dateWithOffset.getHours() + hrsOffset );
  return `${ dateWithOffset.toLocaleString( 'en-US' ) }  ${ getUTCtimeZoneString() }`;
}

export function whenDOMready( func : Function ) : void {
  switch( ( document.readyState + '' as string ) ) {
    case "complete"  :
    case "loaded"  :
    case "interactive"  :
      func();
      break;
    default :
      window.addEventListener( 'DOMContentLoaded', e => func() );
  }
}

export function getEnv() : string | null {
  const metaTagEnv = document.querySelector( 'meta[name=env]' );
  if( metaTagEnv !== null ) {
    return metaTagEnv.getAttribute( 'content' );
  }
  return '';
}

export function getFile( url : string, callback : Function, shouldLogError : boolean = true ) {
  const request = new XMLHttpRequest();
  request.onreadystatechange = () => {
    switch( true ) {
      case request.readyState !== 4 :
        break;
      case request.status === 200 || request.status === 0 :
        callback( request.responseText );
        break;
      default :
        callback( 'error' );
        if( shouldLogError ) {
          logger.error( 'utils.getFile failed to get file.' );
        }
    }
  };
  request.open( 'GET', url, true );
  request.send( null );
}

export function startSyncRefresh( fileToCheck : string = 'buildtime.txt', interval : number = 2000 ) : void {
  if( getEnv() !== 'dev' ) return logger.warn( 'startSyncRefresh not running - not dev environment' );
  let dateAndTime : string = '';

  function fileReceived( str ) {
    switch( true ) {
      case str === 'error' :
        logger.warn( 'probably about to reload...' );
        break;
      case dateAndTime === '' :
        dateAndTime = str;
        break;
      case dateAndTime != str :
        window.clearInterval( intervalID );
        window.location.reload();
        break;
    }
  }

  let intervalID : number = window.setInterval( () => getFile( fileToCheck, fileReceived, false ), interval );
}

export function trueTypeOf( testObject ) : string {
  // @ts-ignore
  return ( ( {} ).toString.call( testObject ).match( /\s([a-zA-Z]+)/ )[ 1 ].toLowerCase() ) as string;
}

export function getCSSvar( varName : string ) : any {
  return window.getComputedStyle( window.document.documentElement ).getPropertyValue( varName );
}

export function getURLsafe( str : string = 'urlsafe' ) : string {
  // ToDo: There must be a cleaner way to do this regex?
  return str.toLowerCase()
    .replace( /'/g, '' )
    .replace( /&/g, ' and ' )
    .replace( /[$]/g, 's' )
    .replace( /[^a-z0-9_-]/gi, '-' )
    .replace( /-{2,}/g, '-' );
}

export function getSlicedArray( array : any[] = [], limit : number = 5 ) : any[] {
  return array.slice( 0, limit );
}

export function getSlicedString( str : string = '', limit : number = 50 ) : string {
  return str.slice( 0, limit );
}

export function deepCloneUsingJSON( obj : any, shouldConvertUndefined : boolean = true ) : any {
  let clone : any = null;
  const replacer = ( key, value ) => typeof value === 'undefined' ? null : value;
  try {
    clone = shouldConvertUndefined ?
      JSON.parse( JSON.stringify( obj, replacer ) ) :
      JSON.parse( JSON.stringify( obj ) );
  } catch( err ) {
    console.error( err );
  }
  return clone;
}

export function nodeListToArray( nodeList : any ) : any[] {
  return nodeList ? Array.prototype.slice.call( nodeList ) : [];
}

export function getRandBetween( min, max ) {
  return Math.floor( Math.random() * (max - min) + min );
}

export function addMotionPathSVG( rectFrom : DOMRect, rectTo : DOMRect, rectParent : DOMRect, parentSelector : string ) : any {
  // console.log( CN + '.addMotionPathSVG' );
  const guid = getGUID();
  const id = `motion-path-${ guid }`;
  const arcID = `arc-${ guid }`;

  const draw : Svg = SVG()
    .addTo( parentSelector )
    .id( id )
    .viewbox( 0, 0, rectParent.width, rectParent.height );

  const rectFromCenter : xyPoints = {
    x : rectFrom.left + ( rectFrom.width / 2 ),
    y : rectFrom.top + ( rectFrom.height / 2 )
  };
  const rectToCenter : xyPoints = {
    x : rectTo.left + ( rectTo.width / 2 ),
    y : rectTo.top + ( rectTo.height / 2 )
  };
  const rectToHead : xyPoints = {
    x : rectTo.left + ( rectTo.width / 2 ),
    y : 0
  };
  const rectToTail : xyPoints = {
    x : rectTo.left + ( rectTo.width / 2 ),
    y : 0
  };

  // is TO in top row?
  if( rectParent.top === rectTo.top ) {
    rectToTail.y = rectTo.bottom; // is top row, point up to center
    rectToHead.y = rectTo.top;
  } else {
    rectToTail.y = rectTo.top;    // all other rows, point down to center
    rectToHead.y = rectTo.bottom;
  }

  const cmds = `
    M ${ rectFromCenter.x },${ rectFromCenter.y }
    Q ${ rectToTail.x },${ rectToTail.y }  ${ rectToCenter.x },${ rectToCenter.y }`;

  const path : Path = draw.path( cmds )
    .id( arcID )
    .fill( 'none' )
    .stroke( {
      // TODO : add the alpha back in
      color : '#FF000000',
      width : 1,
      linecap : 'round',
      linejoin : 'round'
    } );

  return { removeSVG : () => draw.remove(), arcSelector : `#${ arcID }` };
}

export function getParentStreamPanel( elem : HTMLElement | null ) : HTMLElement | null {
  while( elem ) {
    if( elem.classList.contains( 'panel-stream' ) && elem.classList.contains( 'remote' ) ) return elem as HTMLElement;
    elem = elem.parentElement as HTMLElement;
  }
  return null;
}

export function makeTopmost( elem : HTMLElement | null ) : void {
  if( !elem ) return;
  const topmost = elem.parentNode ? elem.parentNode.querySelector( '.topmost' ) : null;
  topmost && topmost.classList.remove( 'topmost' );
  elem.classList.add( 'topmost' );
}
