import { Fragment, useState, useRef, useEffect } from 'react';
import { Coord } from '../../vibe/vibe';
import { getSignal } from '../../utils/utils';
import * as logger from '../../utils/logger';
import Slider from '../Slider/Slider';
import { store } from '../../data/store';
import { dialogIDs } from '../../data/consts';

import './StreamPanelHUDstar.less';

const CN : string = 'StreamPanelHUDstar';

interface props {
  playerRef : any
}

export default function StreamPanelHUDstar( {
  playerRef
} : props ) {

  // remote
  const [ signalSlider, setSignalSlider ] = useState( getSignal( 'signalSlider', 0 ) );
  const [ isMuted, setIsMuted ] = useState( false );
  const [ isFullscreen, setIsFullscreen ] = useState( false );

  const hudRef = useRef( null );

  useEffect( () => {
    signalSlider.dispatch( 0.5 );

    return cleanup;
  }, [] );

  return (
    <div
      ref={ hudRef }
      className={ 'stream-panel-hud' }
    >
      { getBtns() }
    </div>
  );

  function setMute( shouldMute : boolean, fromSlider : boolean = false ) {
    if( shouldMute === isMuted ) return;

    const player = playerRef.current;

    player.setMuted( shouldMute );

    setIsMuted( shouldMute );

    if( !fromSlider ) shouldMute ? signalSlider.dispatch( 0 ) : signalSlider.dispatch( player.getVolume() );
  }

  function toggleFullScreen() : void {
    if( isFullscreen ) {
      window.document.exitFullscreen()
        .then( data => {
          logger.log( 'exited fullscreen' );
          setIsFullscreen( false );
        } )
        .catch( err => {
          logger.error( err );
        } )
    } else {
      ( playerRef.current.getHTMLVideoElement().parentNode as HTMLDivElement ).requestFullscreen()
        .then( data => {
          logger.log( 'entered fullscreen' );
          setIsFullscreen( true );
        } )
        .catch( err => {
          logger.error( err );
        } )
    }
  }

  function getBtns() {
    return <Fragment>
      <button
        data-coord={Coord.a1}
        className={`icon-heart`}
        onClick={ () => store.dialogToDisplay = dialogIDs.dialogTipTheStar }
      />
      <button
        data-coord={Coord.b3}
        className={isMuted ? 'icon-speaker off' : 'icon-speaker'}
        onClick={() => setMute( !isMuted )}/>
      <Slider
        parentRef={ hudRef as any }
        signalLevelExternal={signalSlider}
        height={60}
        onChange={ ( newLevel : number ) => {
          playerRef.current.setVolume( newLevel );
          setMute( !newLevel, true );
        } }
        classNames={`${Coord.b3} above`}
        coord={Coord.b3}
      />
      <button
        data-coord={Coord.e3}
        className={ isFullscreen ? 'icon-fullscreen off' : 'icon-fullscreen' }
        onClick={ toggleFullScreen }
      />
    </Fragment>;
  }

  function cleanup() {

  }
}

