import React from 'react';
import * as api from '../../api/api'
import { store } from '../../data/store'
import * as logger from '../../utils/logger'
import { gotoCustomerPortal } from '../../ecomm/ecomm';
import { Account, Patron } from '../../vibe/vibe';

import './AccountPanel.less';


interface props {
  patron : Patron
}

export default function AccountPanel( { patron } : props ) {
  async function readAccount() : Promise<any> {
    try{
      const acct : Account = await api.accountRead( { userID : store.patron.uid } )
      logger.log( '\taccount :', acct );
    } catch( err : any ){
      logger.error( err );
    }
  }

  if( !patron ) logger.log( 'AccountPanel has no patron : ', patron );
  return (
    <div className="panel-account">
      <h3>Account Panel</h3>
      <button onClick={readAccount}>Add Account</button>
      <button
        onClick={ gotoCustomerPortal }
      >Show Purchase History</button>
    </div>
  );
}
