import { useEffect, ReactChildren } from 'react';
import { createPortal } from 'react-dom';

import './Portal.less';

interface props {
  children : any,
  className? : string
}

export default function Portal( {
  children,
  className = ''
} : props ){
  const portals = document.getElementById( 'portals' ) as HTMLDivElement;
  const portal = document.createElement( 'div' );
  if( className ) portal.className = `portal${ className ? ` ${ className }` : '' }`;

  useEffect( () => {
    portals.appendChild( portal );
    return cleanup;
  }, [ portal, portals ] );

  function cleanup() {
    portals.removeChild( portal );
  }

  return createPortal(children, portal)
};
