import DialogCreateTable from '../DialogCreateTable/DialogCreateTable';
import DialogSignIn from '../DialogSignIn/DialogSignIn';
import DialogAddPayment from '../DialogAddPayment/DialogAddPayment';
import DialogStreamSources from '../DialogStreamSources/DialogStreamSources';
import DialogPurchaseMojo from '../DialogPurchaseMojo/DialogPurchaseMojo';
import SpinnerModal from '../Spinners/SpinnerModal';
import { dialogIDs } from '../../data/consts';

import './OverlayPanel.less';
import { store } from '../../data/store';
import DialogTableSettings from '../DialogTableSettings/DialogTableSettings';
import DialogTableWaitlist from '../DialogTableWaitlist/DialogTableWaitlist';
import DialogTableInvite from '../DialogTableInvite/DialogTableInvite';
import DialogTipTheStar from '../DialogTipTheStar/DialogTipTheStar';
import DialogCreateNewStar from '../DialogCreateNewStar/DialogCreateNewStar';
import DialogCreateEvent from '../DialogCreateEvent/DialogCreateEvent';
import DialogVerCode from '../DialogVerCode/DialogVerCode';
import DialogOrientation from '../DialogOrientation/DialogOrientation';

export default function OverlayPanel( { dialogToDisplay } : { dialogToDisplay: dialogIDs } ) {

  function getDialog() : any {
    switch( dialogToDisplay ){
      case dialogIDs.dialogCreateTable :
        return <DialogCreateTable />;
      case dialogIDs.dialogAddPayment :
        return <DialogAddPayment />;
      case dialogIDs.dialogStreamSources :
        return <DialogStreamSources />;
      case dialogIDs.dialogPurchaseMojo :
        return <DialogPurchaseMojo />;
      case dialogIDs.spinner :
        return <SpinnerModal />
      case dialogIDs.dialogTableSettings :
        return <DialogTableSettings />
      case dialogIDs.dialogTableWaitlist :
        return <DialogTableWaitlist />;
      case dialogIDs.dialogTableInvite :
        return <DialogTableInvite />;
      case dialogIDs.dialogSignIn :
        return <DialogSignIn />;
      case dialogIDs.dialogTipTheStar :
        return <DialogTipTheStar />;
      case dialogIDs.dialogCreateNewStar :
        return <DialogCreateNewStar />;
      case dialogIDs.dialogCreateEvent :
        return <DialogCreateEvent />;
      case dialogIDs.dialogVerCode :
        return <DialogVerCode />;
      case dialogIDs.dialogOrientation :
        return <DialogOrientation />;
    }
  }

  const isDark : boolean = dialogToDisplay === dialogIDs.spinner && !store.dialogToDisplay;

  return (
    <div className={ `panel-overlay${ isDark ? ' dark' : '' }${ dialogToDisplay === dialogIDs.spinner ? ' spinner' : '' }` }>
      { getDialog() }
    </div>
  );
}

