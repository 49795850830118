import { Coord, Patron, Attendee, PresentationModes, FXinfo } from '../../vibe/vibe';
import { ChatMsg, RelayTypes, useP2Prelay, FXevent } from '../../relay/p2pRelay';
import { store } from '../../data/store';
import * as logger from '../../utils/logger';
import { RemoteVideo, useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { useState, useRef, useEffect, useCallback } from 'react';
import StreamPanelHUDremote from '../StreamPanelHUDremote/StreamPanelHUDremote';
import FXpanel from '../FXpanel/FXpanel';
import ChatPanelMini from '../ChatPanelMini/ChatPanelMini';
import StreamPanelOverlay from '../StreamPanelOverlay/StreamPanelOverlay';
import { getColorByKey } from '../../utils/clrs';
import { polycastTouched, streamPanelCoordsChanged, streamPanelFXreceived } from '../../relay/signalRelay';
import { gsap, Power4 } from 'gsap';
import SendMenu from '../SendMenu/SendMenu';
import { getSignal, makeTopmost, getParentStreamPanel, dismiss } from '../../utils/utils';

const CN : string = 'StreamPanelRemote';

interface props {
  providedAttendee : Attendee,
  tileID : number,
  coord : Coord
}

let renderCount : number = 0;

/*
const placeboAttendee : Attendee = {
  AttendeeId : '',
  ExternalUserId : '',
  patron : {
    patronID : '',
    accountID : '',
    mojoBalance : 0
  }
}
 */

export default function StreamPanelRemote( {
  providedAttendee,
  tileID,
  coord
} : props ) {
  renderCount++;

  // logger.log( CN + ' rendering - ' + renderCount );

  providedAttendee || logger.warn( 'WARNING : we have an undefined providedAttendee - hopefully using the stored one...' );

  const getPatronName = () : string => attendee?.patron?.name || 'patron';

  // hooks
  const [ attendee, setAttendee ] = useState( { ...providedAttendee } );
  const [ chatMsgs, setChatMsgs ] = useState( [] as ChatMsg[] );
  const [ isChatDisplayed, setIsChatDisplayed ] = useState( false );
  const [ isSendMenuDisplayed, setIsSendMenuDisplayed ] = useState( false );
  const [ msgSignal, setMsgSignal ] = useState( getSignal( `msgSignal_${ attendee?.patron?.patronID }`, 2 ) );
  const [ clr, setClr ] = useState( getColorByKey( attendee?.patron?.patronID || '', 'StreamPanel' ) );
  const [ tweens, setTweens ] = useState( [] as any[] );
  const [ fxInfo, setFXinfo ] = useState( null as FXinfo | null );

  const { relaySignals } = useP2Prelay( useAudioVideo() );

  // refs
  const streamPanelDivRef = useRef( null as any );
  const tweenRef = useRef( null as gsap.core.Tween | null );
  const menuRef = useRef( null as any );

  // callbacks
  const receivedFX = useCallback( ( fxEvent : FXevent ) => {
    console.log( CN + '.receivedFX' );
    const patronID = attendee?.patron?.patronID || 'noPatron';
    if( fxEvent?.to?.includes( patronID ) ){
      setFXinfo( fxEvent?.fxInfo as FXinfo );
    }
  }, [] );

  const updateChatHistory = ( chatMsg : ChatMsg ) => {
    if( isForThisChatPanel( chatMsg ) ) {
      chatMsgs.push( chatMsg );
      setChatMsgs( [ ...chatMsgs ] );
      makeTopmost( streamPanelDivRef.current );
      !isChatDisplayed && msgSignal.dispatch( chatMsg );
    }
  }

  const displayNameplate = useCallback( () => {
    const nameplate : HTMLElement | undefined = streamPanelDivRef?.current?.querySelector( '.ch-nameplate' );
    if( !nameplate ) return logger.warn( 'no nameplate to display...' );

    const outro : gsap.core.Tween | undefined = tweens[ 0 ];

    if( outro !== undefined ) {
      outro.pause();
      gsap.to( nameplate, 0.25, {
        opacity : 1,
        height : 39,
        onComplete : () => {
          outro.restart( true );
        }
      } );
    } else {
      tweens[ 0 ] = gsap.to( nameplate, 1, {
        delay : 3,
        height : 0,
        opacity : 0,
      } );
    }
  }, [] );

  useEffect( () => {
    relaySignals[ RelayTypes.ChatMsg ].add( updateChatHistory );
    streamPanelFXreceived.add( receivedFX );
    displayNameplate();
    polycastTouched.add( displayNameplate );
    return cleanup;
  }, [] );

  return <div
    ref={ streamPanelDivRef }
    id={ `panel-stream-${ attendee?.patron?.patronID }` }
    className={ `panel-stream remote ${ clr }${ isSendMenuDisplayed || isChatDisplayed ? ' focused' : '' }` }
    data-coord={ coord }
    data-patronid={ attendee?.patron?.patronID }
    data-tileid={ tileID }
    draggable={ !isSendMenuDisplayed }
    onMouseDown={ e => makeTopmost( getParentStreamPanel( e.target as HTMLElement ) ) }
    onDragEnter={ e => {
      ( e.target as HTMLElement ).classList.add( 'targeted' );
      e.preventDefault();
    } }
    onDragOver={ e => {
      ( e.target as HTMLElement ).classList.add( 'targeted' );
      e.preventDefault();
    } }
    onDragLeave={ e => {
      ( e.target as HTMLElement ).classList.remove( 'targeted' );
    } }
    onDragStart={ e => {
      dragStarted( e.target );
    } }
    onDrop={ dropped }
  >
    { providedAttendee.mode !== PresentationModes.mediaVideo &&
      <RemoteVideo
        tileId={ tileID }
        name={ getPatronName() }
        className={ 'remote-video-container'}
      />
    }
    { providedAttendee.mode === PresentationModes.mediaVideo &&
      <div className="remote-video-container">
        <video
          className="ch-video"
          autoPlay={ true }
          playsInline={ true }
          muted={ true }
          src={ attendee?.patron?.profile?.awayMedia?.url || 'https://appcloud9.com/vid/magic_fire.mp4' }
          loop={ true }
        />
        <header className="ch-nameplate" >
          <p className="ch-text">{ attendee?.patron?.name }</p>
        </header>
      </div>
    }
    { isSendMenuDisplayed &&
      <SendMenu
        patronID={ attendee?.patron?.patronID }
        setIsSendMenuDisplayed={ setIsSendMenuDisplayed }
      />
    }
    <StreamPanelHUDremote
      setStreamPanelIsChatDisplayed={ setIsChatDisplayed }
      setIsSendMenuDisplayed={ setIsSendMenuDisplayed }
      streamPanelDivRef={ streamPanelDivRef }
      isSendMenuDisplayed={ isSendMenuDisplayed }
    />
    { fxInfo &&
      <FXpanel
        fxInfo={ fxInfo }
        setFXinfo={ setFXinfo }
      />
    }
    <StreamPanelOverlay
      isChatPanelDisplayed={ isChatDisplayed }
      msgSignal={ msgSignal }
      clr={ clr }
    />
    <ChatPanelMini
      chatMsgs={ chatMsgs }
      classNames={ isChatDisplayed ? 'displayed' : '' }
      patronID={ attendee?.patron?.patronID || '' }
    />
  </div>;

  function menuBlurred( e ) {
    const menu : HTMLDivElement = menuRef.current as HTMLDivElement;

    switch( true as boolean ) {
      case !menu :
      case e.nativeEvent.relatedTarget === menu || menu.contains( e.nativeEvent.relatedTarget ) :
        return;
      default :
        dismiss( document.querySelector( '.user-menu' ) as HTMLDivElement );
    }
  }

  function isForThisChatPanel( chatMsg : ChatMsg ) : boolean {
    return ( chatMsg.from === attendee?.patron?.patronID && chatMsg.to[ 0 ] === store?.patron?.patronID ) ||
      ( chatMsg.from === store?.patron?.patronID && chatMsg.to[ 0 ] === attendee?.patron?.patronID );
  }

  function dragStarted( streamPanel ) : void {
    streamPanelDivRef.current.classList.add( 'dragging' );
    store.sessionData = {
      streamPanelDragged : streamPanel
    };
  }

  function dragEnded() {
    const panelBeingDragged : HTMLDivElement = document.querySelector( '.dragging' ) as HTMLDivElement;
    panelBeingDragged && panelBeingDragged.classList.remove( 'dragging' );
    const dropTargets = document.querySelectorAll( '.drop-target' );
    const streamPanels = document.querySelectorAll( '.panel-stream' );
    dropTargets.forEach( dropTarget => dropTarget.classList.remove( 'targeted' ) );
    streamPanels.forEach( streamPanel => streamPanel.classList.remove( 'targeted' ) );
    streamPanelCoordsChanged.dispatch();
  }

  function dropped( e ) : void {
    let streamPanel;
    let target = e.target;
    while( !streamPanel ) {
      if( target.classList.contains( 'panel-stream' ) ) {
        streamPanel = target;
      } else {
        if( target.parentNode === null ) {
          e.preventDefault();
          dragEnded();
          return;
        }
        target = target.parentNode;
      }
    }
    const streamPanelDragged = store.sessionData.streamPanelDragged;
    const movedToCoord = streamPanel.getAttribute( 'data-coord' );
    const movedFromCoord = streamPanelDragged.getAttribute( 'data-coord' );
    streamPanel.setAttribute( 'data-coord', movedFromCoord );
    streamPanelDragged.setAttribute( 'data-coord', movedToCoord );
    dragEnded();
  }

  function cleanup() {
    relaySignals[ RelayTypes.ChatMsg ].remove( updateChatHistory );
    polycastTouched.remove( displayNameplate );
    streamPanelFXreceived.remove( receivedFX );
  }

}
