import { store } from '../../data/store';
import { useState } from 'react';
import { purchaseItemByPriceID } from '../../ecomm/ecomm';

import './DialogOrientation.less';
import DialogHeader from '../DialogHeader/DialogHeader';
import ButtonVibe from '../ButtonVibe/ButtonVibe';

export default function DialogOrientation() {

  return <>
    <div className="dialog-orientation">
      <DialogHeader title={`VibeWith.me Alpha Orientation`}/>
      <div className="body">
        <h3>Alpha</h3>
        <p>We are in the early stages and so you might see a few rough edges.</p>
        <p>If anything gets too wacky then please <strong>refresh the page</strong> and rejoin the event.</p>
        <h3>Camera &amp; Mic</h3>
        <p>In order to join in a party you will be asked to <strong>allow access</strong> to your camera and mic.</p>
        <h3>Feedback</h3>
        <p>We are very interested in your thoughts and ideas. Please share any thoughts <strong>in the chat</strong>.</p>
        <h3>Vibe With Kindness</h3>
        <p>Please treat everyone here with <strong>respect and kindness</strong>.</p>
        <p>We are looking forward to your good vibes!</p>
      </div>
      <div className="footer">
        <ButtonVibe
          label={ 'OK' }
          onClick={ btnClicked }/>
      </div>
    </div>
  </>;

  function btnClicked() : void {
    store.dialogToDisplay = '';
  }

}
