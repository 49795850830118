import './ChatMessage.less';
import { ChatMsg } from '../../relay/p2pRelay';
import { store } from '../../data/store';
import { getColorByKey } from '../../utils/clrs';
import { useEffect, useRef } from 'react';

interface props {
  chatMsg : ChatMsg
  isMini? : boolean
}

const CN : string = 'ChatMessage';

export default function ChatMessage( {
  chatMsg,
  isMini = false
} : props ) {

  const heightLimit : number = 50;
  const divRef : any = useRef( null );

  const isFromMe = chatMsg.from === store.patron.patronID;
  const isFromVibe = chatMsg.from === 'vibetron';

  useEffect( () => {
    const rect : DOMRect = divRef.current.getBoundingClientRect();
    if( rect.height > heightLimit && !isMini ) divRef.current.classList.add( 'tall' );
  }, [] );

  return <div
    ref={ divRef }
    className={ `chat-msg${ getClassName() }` }>
    <div className={ `from-badge${ getClr() }` }>
      { getName() }
    </div>
    <div className='msg'>
      <span className={ 'invisi-txt' }>
        { ` ${ getName( true ) } ` }
      </span>
      { chatMsg.msg }
    </div>
  </div>;

  function getClassName() : string {
    switch( true ) {
      case isFromMe :
        return ' me';
      case isFromVibe :
        return ' vibetron';
      default :
        return '';
    }
  }

  function getClr() : string {
    switch( true ) {
      case isFromMe :
        return '';
      case isFromVibe :
        return '';
      default :
        return ' ' + getColorByKey( chatMsg.from, 'StreamPanel' );
    }
  }

  function getName( isForInvisiTxt : boolean = false ) : string {
    switch( true ) {
      case isFromVibe :
        return isForInvisiTxt ? 'Vi' : '';
      case !!chatMsg.fromName :
        return chatMsg.fromName;
      default :
        return '?';
    }
  }
}
