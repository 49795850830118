import { store } from '../../data/store';
import { useCallback, useState, useRef, useEffect } from 'react';
import { Polycast } from '../../vibe/vibe';
import * as logger from '../../utils/logger';

import TopBar from '../TopBar/TopBar';
import ActivityMenu from '../ActivityMenu/ActivityMenu';
import ActivityPanel from '../ActivityPanel/ActivityPanel';
// import CookieConsent from '../CookieConsent/CookieConsent';
import ChatPanel from '../ChatPanel/ChatPanel';

import { useP2Prelay, RelayTypes, SoundEvent } from '../../relay/p2pRelay';
import { deleteElement, nodeListToArray } from '../../utils/utils';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

import './AppVibe.less';

const CN : string = 'AppVibe';

export default function AppVibe() {
  // const CN : string = 'AppVibe';

  // hooks
  const [ isChatEnabled, setIsChatEnabled ] = useState( store.isChatEnabled );
  const [ isChatDisplayed, setIsChatDisplayed ] = useState( store.isChatDisplayed );
  const [ appMode, setAppMode ] = useState( store.appMode );
  const [ user, setUser ] = useState( store.user );
  const [ patron, setPatron ] = useState( store.patron );
  //const [ needsCookieConsent, setNeedsCookieConsent ] = useState( store.needsCookieConsent );
  const [ hasPolycast, setHasPolycast ] = useState( !!store.polycast );

  const { relaySignals } = useP2Prelay( useAudioVideo() );

  // callbacks
  const isChatEnabledUpdated = useCallback( setIsChatEnabled, [] );
  const isChatDisplayedUpdated = useCallback( setIsChatDisplayed, [] );
  const appModeUpdated = useCallback( setAppMode, [] );
  const userUpdated = useCallback( setUser, [] );
  // const needsCookieConsentUpdated = useCallback( setNeedsCookieConsent, [] );
  const patronUpdated = useCallback( setPatron, [] );
  const polycastUpdated = useCallback( ( polycast : Polycast ) => {
    const hasPolycastNow : boolean = !!polycast;
    if( hasPolycast !== hasPolycastNow ) {
      setHasPolycast( !hasPolycast );
    }
  }, [ hasPolycast ] );

  const playSnd = useCallback( async ( sndEvent : SoundEvent ) => {

    try {
      const audio : HTMLAudioElement = document.createElement( 'audio' );
      audio.muted = false;
      audio.autoplay = true;
      audio.loop = false;
      audio.volume = 0.4;
      audio.className = sndEvent.soundID;

      // small crossfade and then delete old audio(s) of the same soundID
      const existingAudios : HTMLAudioElement[] = nodeListToArray( document.querySelectorAll( `.${ sndEvent.soundID }` ) );
      if( existingAudios.length ) {
        window.setTimeout( () => {
          existingAudios.find( ( existingAudio : HTMLAudioElement ) => {
            existingAudio.pause();
            deleteElement( existingAudio );
          } );
        }, 300 );
      }

      appRef.current.appendChild( audio );
      audio.addEventListener( 'ended', () => audioEnded( audio ) );
      audio.src = sndEvent.url;
      await audio.play();

      logger.log( `audio playing ${ sndEvent.url }` );

    } catch( err : any ) {
      logger.error( err );
    }

    function audioEnded( audio ) {
      audio.removeEventListener( 'ended', audioEnded );
      deleteElement( audio );
    }
  }, [] );


  // signals remove
  store.isChatEnabledUpdated.remove( isChatEnabledUpdated );
  store.appModeUpdated.remove( appModeUpdated );
  store.isChatDisplayedUpdated.remove( isChatDisplayedUpdated );
  // store.needsCookieConsentUpdated.remove( needsCookieConsentUpdated );
  store.patronUpdated.remove( patronUpdated );
  store.polycastUpdated.remove( polycastUpdated );
  store.userUpdated.remove( userUpdated );

  // signals add
  store.appModeUpdated.add( appModeUpdated );
  store.isChatEnabledUpdated.add( isChatEnabledUpdated );
  store.isChatDisplayedUpdated.add( isChatDisplayedUpdated );
  // store.needsCookieConsentUpdated.add( needsCookieConsentUpdated );
  store.patronUpdated.add( patronUpdated );
  store.polycastUpdated.add( polycastUpdated );
  store.userUpdated.add( userUpdated );

  const appRef = useRef( null as any );
  const intervalID = useRef( 0 );

  const makeNonScrollable = useCallback( () => {
    appRef?.current.classList.remove( 'scrollable' );
  }, [] );

  const startScrollCheck = useCallback( ()  => {
    const interval : number = 200;
    window.clearInterval( intervalID.current );
    intervalID.current = window.setInterval( makeNonScrollable, interval );
  }, [] );

  useEffect( () => {
    relaySignals[ RelayTypes.SoundEvent ].add( playSnd );
    // https://stackoverflow.com/questions/8378243/catch-scrolling-event-on-overflowhidden-element
    // https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
    // TODO : mousewheel is the wrong event - likely need to track event elsewhere and signal
    if( appRef.current ) {
      appRef.current.addEventListener( 'mousewheel', () => {
        appRef.current.classList.add( 'scrollable' );
        startScrollCheck();
      } );
    } else {
      logger.warn( `failed to addEventListener for 'mousewheel' event.` );
    }

    return cleanup;
  }, [] );

  /* TODO : rethink cookie consent
    { needsCookieConsent &&
      <CookieConsent/>
    }
   */

  return <div
    ref={ appRef }
    className='app'
  >
    <TopBar user={ user } patron={ patron }/>
    <ActivityMenu
      appMode={ appMode }
      patron={ patron }
      hasPolycast={ hasPolycast }
    />
    <div
      className={ `main${ isChatEnabled && isChatDisplayed ? ' chat' : ''}` }
    >
      <ActivityPanel
        appMode={ appMode }
        user={ user }
        patron={ patron }
      />
    </div>
    { isChatEnabled && <ChatPanel isChatDisplayed={ isChatDisplayed }/> }
    <div id="dustbin"/>
  </div>;

  function cleanup() {
    console.log( CN + '.cleanup' );
    relaySignals[ RelayTypes.SoundEvent ].remove( playSnd );

  }
}
