import { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { noOp, blurDelayWrapper } from '../../utils/utils';
import { FocusEvent } from 'react';

import './InputText.less';

interface props {
  fieldName : string,
  onChange : { ( e : ChangeEvent<HTMLInputElement> ) },
  label : string,
  id? : any,
  onFocus? : { ( e : FocusEvent<HTMLInputElement> ) },
  onBlur? : { ( e : FocusEvent<HTMLInputElement> ) },
  isMultiline? : boolean,
  maxLength? : number,
  size? : 'small' | 'medium' | undefined,
  required? : boolean,
  disabled? : boolean,
  classNames? : string,
  errorMsg? : string,
  defaultValue? : string,
  type? : InputTextTypes,
  autoComplete? : AutoCompletes
}

export enum InputTextTypes {
  password = 'password',
  text = 'text',
  phone = 'tel'
}

export enum AutoCompletes {
  off = 'off',
  isOn = 'on',
  name = 'name',
  nameGiven = 'given-name',
  nameFamily = 'family-name',
  email = 'email',
  username = 'username',
  passwordNew = 'new-password',
  passwordCurrent = 'current-password',
  tel = 'tel'
}

export default function InputText( {
  fieldName = '',
  onChange = noOp,
  onBlur = noOp,
  onFocus = noOp,
  label = '',
  required = false,
  disabled = false,
  isMultiline = false,
  size = undefined,
  id = null,
  errorMsg = '',
  defaultValue = '',
  type = InputTextTypes.text,
  autoComplete = AutoCompletes.off,
  classNames = ''
} : props ) {

  return <TextField
    autoComplete={ autoComplete }
    defaultValue={ defaultValue }
    className={ `input-text-vibe${ errorMsg ? ' vibe-error' : '' }` }
    error={ !!errorMsg }
    helperText={ errorMsg }
    id={ id }
    data-prop={ fieldName }
    size={ size }
    variant={ 'outlined' }
    label={ label }
    required={ required }
    disabled={ disabled }
    onChange={ onChange }
    onFocus={ onFocus }
    onBlur={ blurDelayWrapper( onBlur ) }
    multiline={ isMultiline }
    type={ type }
  />
}
