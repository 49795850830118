import { store } from '../../data/store';
import * as logger from '../../utils/logger';
import DialogHeader from '../DialogHeader/DialogHeader';

import './DialogTableInvite.less';

const CN : string = 'DialogTableInvite';

export default function DialogTableInvite() {
  return (
    <form className="dialog-table-invite">
      <DialogHeader onCloseClick={ () => store.dialogToDisplay = '' }/>
      <div className="body">

      </div>
      <div className="footer">
        <button
          className="btn-block"
          onClick={ block }
        >block</button>
        <button
          className="btn-invite"
          onClick={ invite }
        >invite</button>
      </div>
    </form>
  );

  function block() {
    logger.log( CN + '.block' );

  }

  function invite() {
    logger.log( CN + '.invite' );

  }
}

