import { Fragment, useState } from 'react';
import { blurDelayWrapper } from '../../utils/utils';
import Input, { autoCaps, autoCorrection } from '../Input/Input';
import { store } from '../../data/store';
import { socialKeys, socialPlatforms, socialURLs, LinkURLs } from '../../vibe/vibe';

import './SocialBar.less';

// TODO : get rid of this

export enum socialBarTypes {
  club = 'club',
  patron = 'patron',
  producer = 'producer',
  star = 'star'
}

interface props {
  type : socialBarTypes,
  isEditing : boolean,
  providedLinkURLs? : LinkURLs
}

const CN : string = 'SocialBar';

export default function SocialBar(
  {
    type,
    isEditing,
    providedLinkURLs
  } : props ) {

  console.log( 'providedLinkURLs :', providedLinkURLs );

  let linkURLs : LinkURLs = providedLinkURLs as LinkURLs;

  if( !linkURLs ) {
    linkURLs = store.sessionData[ type ]?.profile?.linkURLs ? store.sessionData[ type ].profile.linkURLs : {};
  }

  // hooks
  const [ socialKey, setSocialKey ] = useState( socialPlatforms.instagram );

  return <div className="social-bar">
    <Fragment>
      <div className={ `bar-social${ isEditing ? ' is-editable' : '' }` }>
        { !isEditing && getLinkIcons() }
        { isEditing && getEditableLinkIcons() }
      </div>
      { isEditing &&
      <Input
        id={ socialKey }
        key={ socialKey }
        fieldName={ socialKey }
        placeholder={ `enter a ${socialKey} url` }
        onChange={ e => {
          linkURLs[ socialKey ] = e.target.value;
        } }
        onBlur={ blurDelayWrapper( inputBlurred ) }
        snapshot={ linkURLs }
        maxLength={ 256 }
        autoCapitalize={ autoCaps.none }
        autoCorrect={ autoCorrection.off }
        spellCheck={ false }
      />
      }
    </Fragment>
  </div>;

  function getLinkIcons() {
    console.log( CN + '.getLinkIcons' );
    console.log( 'socialKeys :', socialKeys );

    return <Fragment>
            { socialKeys.map( key => {
              console.log( 'linkURLs[ key ] :', linkURLs[ key ] );
              if( linkURLs[ key ] ) {
                return <a
                  key={key}
                  className={`social-link ${key}`}
                  href={ linkURLs[ key ] }
                  target="_blank"
                  rel="noreferrer"
                  tabIndex={0}
                />
              }
              return null;
            } ) }
          </Fragment>;
  }

  function getEditableLinkIcons() {
    return <Fragment>
      { socialKeys.map( key => <a
          key={key}
          className={`social-link ${key} ${ socialKey === key ? 'being-edited' : '' }`}
          href={ linkURLs[ key ] || null }
          tabIndex={ 0 }
          onClick={ e => startEditing( e, key ) }
          onFocus={ e => startEditing( e, key ) }
          onBlur={ e => saveLink() }
        >{key}</a> ) }
    </Fragment>;
  }

  function startEditing( e, key ) : void {
    e.preventDefault();
    setSocialKey( key );
  }

  function saveLink() : void {
    console.log( CN + '.saveLink' );

    const input : HTMLInputElement = document.getElementById( socialKey ) as HTMLInputElement;
    if( !input ) return;
    const link : string = input.value || '';
    let normalizedLink : string = link ? socialURLs[ socialKey ] : '';
    let relativeURL : string;
    let urlArray : string[];

    switch( true ) {
      case link === '' :
        // noop
        break;
      case link.indexOf( socialPlatforms[ socialKey ] ) !== -1 :
        relativeURL = link.split( socialPlatforms[ socialKey ] )[ 1 ];
        urlArray = relativeURL.split( '/' );
        urlArray.shift();
        normalizedLink += urlArray.join( '/' );
        break;
      default :
        normalizedLink += link;
    }
    linkURLs[ socialKey ] = normalizedLink;
  }

  function inputBlurred( e ) {
    switch( true ) {
      case !e.relatedTarget :
      case e.relatedTarget.parentNode.className !== 'bar-social' :
        saveLink();
        setSocialKey( socialPlatforms.none );
    }
  }
}
