import { useCallback, useState, MouseEvent, FocusEvent } from 'react';
import { store } from '../../data/store'
import * as route from '../../routes/routes'
import UserMenu from '../UserMenu/UserMenu'
import { dialogIDs, AppModes } from '../../data/consts';
import { Patron } from '../../vibe/vibe';
import { mojoCreate } from '../../api/api';
import firebase from 'firebase/compat/app';

import './TopBar.less'

interface props {
  user : firebase.User,
  patron : Patron
}

const CN : string = 'TopBar';

export default function TopBar( { user, patron } : props ) {
  // hooks
  const [ isDropDownDisplayed, setIsDropdownDisplayed ] = useState( false );
  const [ appTitle, setAppTitle ] = useState( store.appTitle );

  // callbacks
  const appTitleUpdated = useCallback( newTitle => setAppTitle( newTitle ), [] );

  // signals remove
  store.appTitleUpdated.remove( appTitleUpdated );

  // signals add
  store.appTitleUpdated.add( appTitleUpdated );

  return (
      <div className="bar-top" tabIndex={ 0 }>
        <div
          className="btn-ddc"
          onClick={ e => {
            route.setPath();
          } }
        />
        <div className="app-title">{ appTitle }</div>
        { patron &&
          <div
            tabIndex={ 0 }
            className="currency"
            onClick={ ( e : MouseEvent<HTMLDivElement> ) => {
              if( ( e.target as HTMLDivElement ).classList.contains( 'btn-coin' ) ){
                setIsDropdownDisplayed( !isDropDownDisplayed );
              }
            } }
            onBlur={ ( e : FocusEvent<HTMLDivElement> ) => {
              console.log( 'e.nativeEvent.relatedTarget :', e.nativeEvent.relatedTarget );
              if( !elementContains( 'currency', e.nativeEvent.relatedTarget as HTMLElement ) ){
                setIsDropdownDisplayed( false );
              }
            } }
          >
            <div
              tabIndex={ 0 }
              className="balance">{ patron.mojoBalance || 0 }</div>
            <button
              tabIndex={ 0 }
              className="btn btn-coin"/>
            { isDropDownDisplayed && <div className="menu">
              { store.appMode === AppModes.EVENT &&
                <div
                  className="item-menu btn-love"
                  tabIndex={ 0 }
                  onClick={ tipTheDJ }
                >Tip the DJ</div>
              }
              <div
                className={`item-menu icon-mojo${ ( patron.mojoBalance || 0 ) >= 10 ? ' disabled' : '' }`}
                tabIndex={ 0 }
                onClick={ reUpMojo }
              >Get 10 mojo
              </div>
            </div>
            }
          </div>
        }
        <UserMenu patron={ patron }/>
      </div>

  );

  function tipTheDJ() : void {
    console.log( CN + '.tipTheDJ' );
    setIsDropdownDisplayed( false );
    store.dialogToDisplay = dialogIDs.dialogTipTheStar;
  }

  function reUpMojo() : void {
    console.log( CN + '.reUpMojo' );
    setIsDropdownDisplayed( false );
    mojoCreate( { patronID : store.patron.patronID } )
      .then( result => {
        console.log( 'result :', result );

      } )
      .catch( err => {
        console.error( err );
      } );

  }

  // TODO : add this to utils
  function elementContains( parentClassName : string, element : HTMLElement ) : boolean {
    let containsElem : boolean = false;
    let parent : HTMLElement = element;

    while( parent ) {
      if( parent.classList.contains( parentClassName ) ) return true;
      parent = parent.parentElement as HTMLElement;
    }

    return containsElem;
  }
}

