import { useState, useCallback } from 'react';
import { VibeEvent, TableInfo } from '../../vibe/vibe';
import { enterPolycast } from '../../fbase/fbase';
import { store } from '../../data/store';

import ButtonVibe from '../ButtonVibe/ButtonVibe';
import EventViewPanel from '../EventViewPanel/EventViewPanel';

import { isUserValid } from '../../fbase/auth';
import { getCloudData } from '../../api/api';

import './EventPanel.less';

interface props {
  hasPolycast : boolean
}

// const CN : string = 'EventPanel';
let hasEnteredTable : boolean = false;

export default function EventPanel( {
  hasPolycast = false
} : props ) {

  // hooks
  const [ vibeEvent, setVibeEvent ] = useState( store.vibeEvent as VibeEvent );
  const [ timeRemaining, setTimeRemaining ] = useState<any>( null );

  // callbacks
  const vibeEventUpdated = useCallback( setVibeEvent, [] );

  // signals remove
  store.vibeEventUpdated.remove( vibeEventUpdated );

  // signals add
  store.vibeEventUpdated.add( vibeEventUpdated );

  return (
    <div className={`panel-event${ hasPolycast ? ' has-polycast' : '' }`}>
        { ( vibeEvent && !hasPolycast ) && <>
            <EventViewPanel
              vibeEvent={ vibeEvent }
            />
            <div className="footer">
              <ButtonVibe
              label={ `Start Vibing` }
              onClick={ enterEventTable }
              />
            </div>
          { /*
            <div className="timer">
              { !!timeRemaining ? timeRemaining : getTimeRemaining() }
            </div>
          */}
          </>
        }
    </div>
  );

  function enterEventTable() : void {
    if( !hasEnteredTable ) {
      hasEnteredTable = true;
      /** TODO : takes the first for now - need to make this smarter
       *  will be the free view - one sunny day
       */
      const tableInfo : TableInfo = store.patron.tables[ 0 ];
      if( tableInfo ) {
        enterPolycast( tableInfo );
      } else {
        console.warn( 'No event table found - not launching.' );
      }
    }
  }

/*
  function getTimeRemaining() : null {
    if( isUserValid() ) {
      getCloudData( {
        patronID : store.patron?.patronID || '',
        url : 'https://worldtimeapi.org/api/timezone/America/Los_Angeles'
      } )
        .then( data => setTimeRemaining( JSON.stringify( data, null, 2 ) ) )
        .catch( err => console.error( err ) );
    } else {
      window.setTimeout( getTimeRemaining, 1000 );
    }
    return null;
  }
*/
}
