import MenuItem, { MenuItemInfo } from '../MenuItem/MenuItem';

import './Menu.less';

interface props {
  menuItemInfos : MenuItemInfo[],
  classNames? : string
}

export default function Menu( {
  menuItemInfos,
  classNames = ''
} : props ) {

  return <div className={ `menu ${ classNames }`.trim() }>
    { getMenuItems() }
  </div>;

  function getMenuItems() {
    return menuItemInfos.map( ( menuItemInfo : MenuItemInfo ) =>
      <MenuItem
        label={ menuItemInfo.label }
        icon={ menuItemInfo.icon }
        onClick={ menuItemInfo.onClick }
      />
    )
  }
}
