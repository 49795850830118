// import { store } from '../../data/store';
// import * as logger from '../../utils/logger';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import './DialogAddPayment.less';

const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async ( event ) => {
    event.preventDefault();

    if( !stripe || !elements ) {
      return;
    }

    // Promise<{paymentMethod?: PaymentMethod; error?: StripeError}>
    const payload = await stripe.createPaymentMethod( {
      type : "card",
      card : elements.getElement( CardElement ) as any
    } );

    // check console for details of PaymentMethod
    // test card: 4242424242424242, VISA, any 3 digits, any future date
    console.log( "[PaymentMethod]", payload );

  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Card details
        <CardElement
          options={{
            style : {
              base : {
                fontSize : '16px',
                color : "#424770",
                letterSpacing : "0.025em",
                fontFamily : "Source Code Pro, monospace",
                "::placeholder" : {
                  color : "#AAB7C4"
                }
              },
              invalid : {
                color : "#9E2146"
              }
            }
          }}
          onReady={() => {
            console.log( "CardElement [ready]" );
          }}
          onChange={( event ) => {
            console.log( "CardElement [change]", event );
          }}
          onBlur={() => {
            console.log( "CardElement [blur]" );
          }}
          onFocus={() => {
            console.log( "CardElement [focus]" );
          }}
        />
      </label>
      <button
        type="submit"
        disabled={!stripe}>
        Save
      </button>
    </form>
  );
};

export default function DialogAddPayment() {
  return (
    <div className="payment-box">
      <CardForm/>
    </div>
  );
}
