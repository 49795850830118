import { store } from '../../data/store';
import './DialogStreamSources.less';
import { useState } from 'react';
import { MicSelection, CameraSelection } from 'amazon-chime-sdk-component-library-react';
import DialogHeader from '../DialogHeader/DialogHeader';

export default function DialogStreamSources() {

  const [ streamDevices, setStreamDevices ] = useState( store.streamDevices );
  !streamDevices.length && store.streamDevicesUpdated.addOnce( setStreamDevices );

  return <div className="dialog-stream-sources">
    <DialogHeader title={`Streaming Sources`}/>
    <div className="body">
      <CameraSelection label={`Choose Camera`}/>
      <MicSelection label={`Choose Mic`}/>
    </div>
  </div>;
}

