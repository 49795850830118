import { store } from '../data/store'

export function populateStringTable( lang : string = 'en' ) : void {
  switch( lang ) {
    case 'en' :
    case 'fr' :
      store[ 'stringTable' ] = stringTables[ lang ];
      break;
  }
}

// TODO : we will keep the different language's stringTable in a DB at some point
const stringTables = {
  en : {
    hostAtable : 'Host a Table',
    createTable : 'Create Table',
    timeCanExtend : 'Time Auto-Extends',
    tableName : 'table name',
    tableDesc : 'table description',
    tableURLsafeName : 'table URL safe name',
    tableTags : 'table tags (comma delimited)',
    enterAurl : 'enter a URL',
    startTime : 'start time',
    endTime : 'end time',
    errorMsgName : 'name must be less than 50 characters',
    errorMsgDesc : 'description must be less than 256 characters',
    errorMsgURLsafe : 'must not have spaces, special characters, or be longer than 50 chars',
    cancel : 'Cancel',
    save : 'Save',
    sendMessage : 'send message',
    suggestedDonation : 'suggested donation'
  }
}
