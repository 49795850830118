import * as logger from './logger';

const CN : string = 'responsive';

let isStarted : boolean = false;
let cb : Function;
let timeoutID : number;

const delay : number = 25;

const breakPointRanges = {
  SMALL : [ 0, 767 ],
  MEDIUM : [ 768, 1099 ],
  LARGE : [ 1100, Infinity ]
};

export enum Breakpoints {
  SMALL  = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE  = 'LARGE'
}

export let breakpoint : string = Breakpoints.MEDIUM as string;

export function isMobile() : boolean {
  return isIOS() || isAndroid();
}

export function isIOS() : boolean {
  return /(ipad|iphone|ipod)/gi.test( navigator.userAgent );
}

export function isAndroid() : boolean {
  return /Android/i.test( navigator.userAgent );
}

export function start( callback : Function ) : void {
  logger.log( CN + '.start' );

  if( !isStarted ) {
    isStarted = true;
    cb = callback;
    window.addEventListener( 'resize', () => {
      window.clearTimeout( timeoutID );
      timeoutID = window.setTimeout( windowResized, delay );
    } );

    window.addEventListener( 'orientationchange', windowResized );
  }

  windowResized();
}

function windowResized() : void {
  const width = window.innerWidth;

  switch( true ) {
    case width >= breakPointRanges.SMALL[ 0 ] && width <= breakPointRanges.SMALL[ 1 ] :
      breakpoint = Breakpoints.SMALL;
      break;
    case width >= breakPointRanges.MEDIUM[ 0 ] && width <= breakPointRanges.MEDIUM[ 1 ] :
      breakpoint = Breakpoints.MEDIUM;
      break;
    case width >= breakPointRanges.LARGE[ 0 ] && width <= breakPointRanges.LARGE[ 1 ] :
      breakpoint = Breakpoints.LARGE;
      break;
    default :
      breakpoint = Breakpoints.LARGE;
  }
  cb( breakpoint );
}

