/**
 ___ ___ _______ ______ _______       ______ _______ _______ _______ _______ _______
 |   |   |_     _|   __ \  ___|      |      |       |   |   |   |   |       |    |  |
 |   |   |_|   |_|   __ <  ___|      |   ---|   -   |       |       |   -   |       |
 \_____/|_______|______/_______|     |______|_______|__|_|__|__|_|__|_______|__|____|

 ** NOTE : THIS FILE IS SHARED BETWEEN CLOUD AND FRONT END - REGULARLY LATEST WILL OVERWRITE THE OTHER
 **        DON'T EDIT BOTH VERSIONS WITHOUT FIRST RUNNING 'updateVibeCommon' NPM SCRIPT
 **        THIS SCRIPT RUNS BEFORE BUILDING EITHER FUNCTIONS OR FRONT END AND UPON COMMITTING TO GIT
 **/

export const DOMAINS_FRONT_END : string[] = [ 'https://vibewith.me', 'http://localhost:5000', 'http://localhost:4333' ];
export const vibetron : string = 'vibetron';
export const limits = {
  tags : 5,
  linkURLs : 9,
  blurb : 300,
  name : 50,
  tagLine : 120,
  maxString : 1000,
  chatMsg : 256
};

export interface RTMPchannel {
  channel : {
    arn : string,
    authorized : boolean,
    ingestEndpoint : string,
    latencyMode : string,
    name? : string,
    playbackUrl : string,
    recordingConfigurationArn? : string,
    tags? : {
      string : string
    },
    type : string
  },
  streamKey : {
    arn : string,
    channelArn : string,
    tags? : {
      string : string
    },
    value? : string
  }
}

export interface KeyValue {
  Key : string,
  Value : string
}

export interface Meeting {
  ExternalMeetingId : string,
  MediaPlacement : {
    AudioFallbackUrl : string,
    AudioHostUrl : string,
    EventIngestionUrl : string,
    ScreenDataUrl : string,
    ScreenSharingUrl : string,
    ScreenViewingUrl : string,
    SignalingUrl : string,
    TurnControlUrl : string
  },
  MediaRegion : string,
  MeetingId : string
}

export interface Attendee {
  AttendeeId : string,
  ExternalUserId : string,
  Tags? : KeyValue[],
  JoinToken? : string,
  patron? : Patron,
  mode : PresentationModes
}

export enum PresentationModes {
  streamVoice = 'streamVoice',         // stream : audio only
  streamVideo = 'streamVideo',         // stream : audio and video
  streamScreen = 'streamScreen',       // stream : audio and screen sharing
  mediaVideo = 'mediaVideo',           // file : video
  mediaAudio = 'mediaAudio',           // file : audio
  mediaImage = 'mediaImage',           // file : image
  none = 'none'                        // no presentation / representation (aside from interactivity)
}

export enum StreamTokenTypes {         // twin of webrtc.AntMediaTokenRoles
  publish = 'publish',
  play = 'play'
}



export enum Roles {
  guest       = 'guest',
  producer    = 'producer',
  host        = 'host',
  moderator   = 'moderator',
  patron      = 'patron',
  star        = 'star',
  coproducer  = 'coproducer',
  cohost      = 'cohost',
  vibe        = 'vibe',
  onstage     = 'onstage',
  event       = 'event',
  table       = 'table'
}
/*
 * producer and co-producer can change an event's star (currently on stage)
 */

export enum Status {
  invited      = 'invited',
  accepted     = 'accepted',
  requested    = 'requested',
  approved     = 'approved',
  pending      = 'pending',
  blocked      = 'blocked',
  streaming    = 'streaming',           // seated at the table - active StreamPanel in the Polycast
  watching     = 'watching',            // you can see the polycast, but aren't seated (creeper!)
  onHold       = 'onHold',              // you have a seat at the table, but you VOD interstitial is playing/displayed
  paused       = 'paused',              // pause video and mute audio (either remote controlled or self controlled)
  muted        = 'muted',               // mute audio (either remote controlled or self controlled)
  broadcasting = 'broadcasting',        // the patron stream which is currently representing the table - selected by the table host and consented to by the patron
  open         = 'open',
  closed       = 'closed',
  online       = 'ready',               // the star is ready to go onstage
  onstage      = 'onstage',             // the star is performing onstage
  offline      = 'offline'              // not ready to perform
}

interface NetPayload {
  idToken? : string
}

/*
 * A Profile is for display - not for searching
 */
export interface Profile {
  coverImg? : string,
  profileImg? : string,
  tagLine? : string,
  blurb? : string,
  linkURLs? : LinkURLs,
  awayMedia? : MediaInfo
}

export interface LinkURLs {
  instagram? : string,
  facebook? : string,
  soundcloud? : string,
  spotify? : string,
  twitch? : string,
  twitter? : string,
  youtube? : string,
  web? : string
}

export interface Account {
  accountID : string,
  personas : Persona[],
  mojoIDs : string[],
  email : string,
  phoneNumber : string,
  rtmpChannels? : RTMPchannel[],
  nameFirst? : string,
  nameLast? : string,
  stripeAcctID? : string,
  media : MediaInfo[],
  inviteCode? : string,
  invitedEmails? : string[],
  inviteLimit? : number
}

export enum MimeTypes {
  svg       = 'image/svg+xml',
  apng      = 'image/apng',
  mp4       = 'video/mp4'
}

enum ProductTypes {
  mediaInfo = 'mediaInfo',
  ticketInfo = 'ticketInfo'
}

export interface TicketInfo {
  eventID : string,

}

export interface SubscriptionInfo {
  clubID? : string,
  hostID? : string
}

export enum Filters {
  white = 'white',
  red = 'red',
  yellow = 'yellow',
  green = 'green',
  purple = 'purple'
}

export interface ProductInfo {
  productID : string,
  costInMojo : number,
  retailerAccountID : string,
  productType : ProductTypes,
  mediaInfo? : MediaInfo,
  ticketInfo? : TicketInfo,
  fxInfo? : FXinfo
}

export interface FXinfo {
  fxID : string,
  filter? : Filters,            // not stored in db - used at runtime
  mediaInfo? : MediaInfo
}

/**
 * MediaInfo
 * Used for FX, VOD, AwayVideo, Poster, etc...
 */
export interface MediaInfo {
  mediaID : string,
  url : string,                // TODO : get rid of this - only here for Alpha
  mimeType : MimeTypes,
  storagePath? : string,
  name? : string,
  tags? : string[],
  duration? : number,
  supportedFilters? : Filters[],
  thumb? : {
    url : string,
    mimeType : MimeTypes,
    storagePath? : string
  }
}

export interface Club extends NetPayload {
  clubID : string,
  producerID : string,         // same as patronID and accountID
  name? : string,
  urlSafeName? : string,
  eventIDs? : string[],        // upcoming eventIDs next week
  stageIDs? : string[],
  tags? : string[],
  subscriptionID? : string,    // key of stripe product for All Access Pass to club's stream(s)
  productIDs? : string[],      // stripe product IDs for sale by the club
  rating? : number,
  profile? : Profile,
  venue? : Venue
}

export interface Performance {
  performanceID : string,
  starID : string,
  isActive : boolean,
  status : Status.offline | Status.online | Status.onstage,
  starName? : string,
  startTime : number,
  endTime? : number,
  timeStarted? : number,
  timeEnded? : number,
  tags? : string[],
  streamID? : string,
  eventID? : string,
  stageID? : string
}

export interface Address {
  street1 : string,
  street2? : string,
  city : string,
  county : string,
  state : string,
  zip : string,
  country : string,
  latlon? : number[]
}

export interface Venue {
  name : string,
  phone? : string,
  address? : Address,
  tags? : string[],
  rating? : number,
  url? : string,
  email? : string,
  images? : string[]
}

export interface VibeEvent {
  eventID : string,
  name? : string,
  urlSafeName? : string,
  desc? : string,
  tags? : string[],
  subscriptionID? : string,
  productIDs? : string[],
  startTime? : number,
  endTime? : number,
  stageIDs? : string[],
  lineup? : Performance[],
  profile? : Profile,
  venue? : Venue,
  star : Star,                   // the star 'onstage' right now
  // payoutRules? : PayoutRuleSet
  streamSources : StreamSource[],
  eventStream : Stream,                   // composited stream for the event - absence means that the event is not live
  broadcastStreams : Stream[]             // informational for overlays in the <BroadcastPanel/>
}

export interface StreamSource {
  name : string,
  url : string
}

export interface UserRegData extends NetPayload {
  userID : string,
  userName : string,
  firstName : string,
  lastName : string,
  phone : string,
  email : string,
  password : string,
  passwordNew : string,
  passwordVerify : string,
  inviteCode? : string
}

export interface MojoTxn extends NetPayload {
  mojoTxnID : string,
  eventID : string,
  date : number,
  source : Persona,
  target : Persona,
  mojoIDs : string[],
  cashValue : number,
  mojoCount : number                // new addition (6/10/2021) see below
  productID? : string,              // product that was purchased
  productTarget? : Persona          // who (if anyone) was gifted?
}

/*
 * mojoCount :
 * • frontend sends mojoCount to backend to communicate how many mojo are being spent / transferred
 * • backend :
 *   • verifies this number of mojo are in the source account
 *   • pops that many mojo off of the stack - FIFO
 *   • calculates the cashValue from those mojo
 *   • populates mojoIDs from those mojo
 */

export interface Mojo {
  mojoID : string,
  owner : Persona,
  cashValue : number      // USD value of _this_ mojo once purchased (same value that cashing out mojo would yield)
}

export interface Patron extends NetPayload {
  patronID: string,
  accountID: string,
  mojoBalance : number,
  eventHistory?: string[],    // eventIDs - tail - last 100
  name?: string,
  urlSafeName? : string,
  tags?: string[],
  tables?: TableInfo[],       // current and future - updated for each patron when table(s) are updated
  rating?: number,
  personas?: Persona[],
  profile?: Profile
}

export interface TableInfo {
  tableID : string,
  eventURL : string,                // if an empty string then no event (for now)
  name? : string,
  startTime? : number,
  endTime? : number,
  seatStatus? : Status.invited |
    Status.accepted |
    Status.requested |
    Status.approved |
    Status.pending,
  tableStatus? :  Status.open |
    Status.closed
}

export interface Table {
  tableID : string,
  people : Persona[],
  name? : string,
  desc? : string,
  startTime? : number,
  endTime? : number,
  canExtendTime? : boolean,
  plannedVenues? : Venue[],
  authorizedEventIDs? : string[],
  tags? : string[],
  urlSafeName? : string,
  productIDs? : string[],
  status? :  Status.open | Status.closed,
  event? : VibeEvent,
  activeEventID? : string
}

export interface Producer {
  producerID : string,
  accountID : string,
  clubIDs? : string[]
  name? : string,
  tags? : string[],
  rating? : number
}

export interface PolycastConfig {
  polycastConfigID : string,
  loadOrder : string[],
  grid : string,
  name : string
}

export interface PolycastModeChange extends NetPayload {
  patronID : string,
  polycastID : string,
  newMode : string,
  isAway? : boolean,
  mediaInfo? : MediaInfo
}

export interface PolycastJoinRequest extends NetPayload {
  patronID : string,
  tableID : string
}

export interface Polycast {
  polycastID : string,               // polycastID === tableID === AWS: ExternalMeetingId
  myAttendeeInfo? : Attendee,
  chime : {
    meeting? : Meeting,
    attendees : Attendee[]
  },
  stageStream? : Stream,             // probably going away? - fallback for performance stream as follows : stageStream > streams[n].role.star > star.profile.awayVideo
  name? : string,
  desc? : string,
  startTime? : number,
  endTime? : number,
  canExtendTime? : boolean,
  currentVenue? : Venue,
  authorizedEventIDs? : string[],    // events that the table has paid be at
  currentEventID? : string,          // populate ActivityMenu and on exit
  tags? : string[],
  urlSafeName? : string,             // https://vibewith.me/table/{tableID}
  productIDs? : string[],
  tableMojo? : any[],
  rating? : number
}

export interface Persona {
  role : Roles,
  id : string,                     // the id to the database document of role X
  status? : Status,
  name? : string,
  rating? : number,
  tags? : string[]
}

export interface Stream extends Persona {
  streamID : string,
  coord? : Coord
}

export interface Star extends NetPayload{
  starID : string,
  accountID : string,
  name? : string,
  urlSafeName? : string,
  tags? : string[],
  profile? : Profile,
  rating? : number,
  eventIDs? : string[]          // upcoming next 10
}

export interface Stage {
  streamID : string,
  profile : Profile,
  venue : Venue
}

export enum Coord {
  a1          = 'a1',
  a2          = 'a2',
  a3          = 'a3',
  a4          = 'a4',
  a5          = 'a5',
  b1          = 'b1',
  b2          = 'b2',
  b3          = 'b3',
  b4          = 'b4',
  b5          = 'b5',
  c1          = 'c1',
  c2          = 'c2',
  c3          = 'c3',
  c4          = 'c4',
  c5          = 'c5',
  d1          = 'd1',
  d2          = 'd2',
  d3          = 'd3',
  d4          = 'd4',
  d5          = 'd5',
  e1          = 'e1',
  e2          = 'e2',
  e3          = 'e3',
  e4          = 'e4',
  e5          = 'e5',
  spectator   = 'spectator',
  stageBL     = 'stage-bl',
  stageBR     = 'stage-br',
  stageCenter = 'stage-center',
  stageTL     = 'stage-tl',
  stageTR     = 'stage-tr',
  stageFull   = 'stage-full',
  none        = 'none'
}

export enum socialPlatforms {
  instagram = 'instagram',
  facebook = 'facebook',
  soundcloud = 'soundcloud',
  spotify = 'spotify',
  twitch = 'twitch',
  twitter = 'twitter',
  youtube = 'youtube',
  web = 'web',
  none = ''
}

export const socialKeys : socialPlatforms[] = [
  socialPlatforms.instagram,
  socialPlatforms.facebook,
  socialPlatforms.soundcloud,
  socialPlatforms.spotify,
  socialPlatforms.twitch,
  socialPlatforms.twitter,
  socialPlatforms.youtube,
  socialPlatforms.web
];

export enum socialURLs {
  instagram = 'https://instagram.com/',
  facebook = 'https://facebook.com/',
  soundcloud = 'https://soundcloud.com/',
  spotify = 'https://spotify.com/',
  twitch = 'https://twitch.tv/',
  twitter = 'https://twitter.com/',
  youtube = 'https://youtube.com/'
}
