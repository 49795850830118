import { store } from '../../data/store'
import { useCallback, useEffect, useRef, useState } from 'react';
// import { rtcSignals, sendData, sendLocalData } from '../../rtc/rtc';
import { limits } from '../../vibe/vibe';
import { ChatMsg, RelayPayload, RelayTypes, useP2Prelay } from '../../relay/p2pRelay';
import Input from '../Input/Input';
import ChatMessage from '../ChatMessage/ChatMessage';
import * as logger from '../../utils/logger';

import './ChatPanel.less';
import { starterChatHistory } from '../../data/consts';
import { gsap } from 'gsap';
import { polycastTouched } from '../../relay/signalRelay';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

const CN = 'ChatPanel';

interface props {
  isChatDisplayed : boolean
}

let canTransition : boolean = true;

export default function ChatPanel( {
  isChatDisplayed = false
} : props ) {

  // hooks
  const [ chatMsgs, setChatMsgs ] = useState( starterChatHistory as ChatMsg[] );
  const [ hasUserScrolled, setHasUserScrolled ] = useState( false );
  const [ tweens, setTweens ] = useState( [] as any[] );

  // refs
  const divRef = useRef( null as any );
  const chatPanelRef = useRef( null as any );

  const { relaySignals, relayPayload } = useP2Prelay( useAudioVideo() );

  const displayBtn = useCallback( () => {
    if( !canTransition ) return;

    const btn : HTMLButtonElement | undefined = divRef?.current?.querySelector( '.btn-chat' );
    if( !btn ) return logger.warn( 'no .btn-chat to display...' );

    const outro : gsap.core.Tween | undefined = tweens[ 0 ];

    if( outro !== undefined ) {
      outro.pause();
      gsap.to( btn, 0.25, {
        opacity : 1,
        width : 45,
        onComplete : () => {
          outro.restart( true );
        }
      } );
    } else {
      tweens[ 0 ] = gsap.to( btn, 1, {
        delay : 3,
        width : 0,
        opacity : 0
      } );
    }
  }, [] );

  const dismissBtn = useCallback( ( e ) => {
    canTransition = false;

    gsap.to( e.target, 0.25, {
      width : 0,
      opacity : 0,
      onComplete : () => {
        canTransition = true;
      }
    } );
  }, [] );

  // TODO : useEffect to scroll ( if !hasUserScrolled ) to bottom when new msg comes in
  useEffect( () => {
    if( !hasUserScrolled ) {
      const chatPanel = chatPanelRef.current;
      if( chatPanel ) {
        // @ts-ignore
        chatPanel.scrollTop = chatPanel.scrollHeight;
      }
    }
  }, [ hasUserScrolled ] );

  const updateChatHistory = ( chatMsg : ChatMsg ) => {
    chatMsgs.push( chatMsg );
    setChatMsgs( [ ...chatMsgs ] );
  }

  useEffect( () => {
    displayBtn();
    relaySignals[ RelayTypes.ChatMsg ].add( updateChatHistory );
    polycastTouched.add( displayBtn );

    return cleanup;
  }, [] );

  return (
    <div
      ref={ divRef }
      className={ `panel-chat${ isChatDisplayed ? ' displayed' : '' }` }
      onMouseOver={ () => polycastTouched.dispatch() }
    >
      <button
        className="btn-chat"
        onClick={ ( e ) => {
          store.isChatDisplayed && dismissBtn( e );
          store.isChatDisplayed = !store.isChatDisplayed;
        } }
      />
      <div
        ref={ chatPanelRef }
        className="chat-panel"
        onScroll={ scrolled }
      >
        { getMsgs() }
      </div>
      <div className="footer">
        <Input
          fieldName={'msg'}
          maxLength={ limits.chatMsg }
          placeholder={ store.stringTable.sendMessage }
          defaultValue={ null }
          onKeyDown={ onKeyDown }
        />
      </div>
    </div>
  );

  function onKeyDown( e ) : void {
    switch( true ) {
      case e.key.toLowerCase() !== 'enter' :
      case !e.target.value.trim() :
        return;
      default :
        const msg = e.target.value.trim();
        e.target.value = '';
        const payload : RelayPayload = {
          type : RelayTypes.ChatMsg,
          data : {
            from : store.patron.patronID,
            fromName : store.patron.name || ' ',
            to : [],
            msg : msg
          } as ChatMsg
        };
        relayPayload( payload );
    }
  }

  function getMsgs(){
    return chatMsgs.map( ( chatMsg : ChatMsg, i : number ) => <ChatMessage key={ i } chatMsg={ chatMsg } /> )
  }

  function scrolled( e : any ) : void {
    if( !e.target || !e.target.classList.contains( 'chat-panel') ) return;

    const chatPanel = e.target as HTMLDivElement;

    if( chatPanel.scrollHeight - chatPanel.scrollTop === chatPanel.clientHeight ) {
      logger.warn( 'calling setHasUserScrolled( false )' );
      setHasUserScrolled( false );
    } else {
      logger.warn( 'calling setHasUserScrolled( true )' );
      setHasUserScrolled( true );
    }
  }

  function cleanup() {
    logger.log( CN + '.cleanup' );
    relaySignals[ RelayTypes.ChatMsg ].remove( updateChatHistory );
    polycastTouched.remove( displayBtn );
  }
}
