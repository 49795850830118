import * as logger from '../../utils/logger';
import { store } from '../../data/store';
import { FocusEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import SocialBar, { socialBarTypes } from '../SocialBar/SocialBar';
import { Club, limits, Patron, Persona, Producer, Profile, Roles, Table } from '../../vibe/vibe';
import { setPath } from '../../routes/routes';
import Input from '../Input/Input';
import InputTags from '../InputTags/InputTags';
import { clubCreate, clubUpdate } from '../../api/api';
import { defaultStorage, dialogIDs, fileFilters } from '../../data/consts';
import { blurDelayWrapper, deepCloneUsingJSON, getSlicedArray, getSlicedString } from '../../utils/utils';
import { docTypes, subscribeToDoc, unsubscribeFromDoc } from '../../fbase/fbase';
import SpinnerModal from '../Spinners/SpinnerModal';
import { getFile } from "../../fbase/fbaseUtils";
import FileUploader from "../FileUploader/FileUploader";

import './ClubProfilePanel.less';
import { LinkURLs } from '../../../../functions/src/vibe/vibe';

// TODO : move all hard coded UX strings to loc.stringTable
//import Calendar from 'react-calendar'

enum fields {
    coverImg   = 'coverImg',
    profileImg = 'profileImg',
    name       = 'name',
    tagLine    = 'tagLine',
    blurb      = 'blurb',
    tags       = 'tags'
}

interface props {
    patron : Patron
}

const emptyClub : Club = {
    clubID : '',
    producerID : '',
    name : '',
    tags : [],
    profile : {
        coverImg : 'defaults/default_cover.png',
        profileImg : 'defaults/default_profile.png',
        tagLine : '',
        blurb : '',
        linkURLs : {}
    }
};

const CN : string = 'ClubProfilePanel';

let isFirstRender : boolean = true;

export default function ClubProfilePanel( { patron } : props ) {
    console.log( CN + '.ClubProfilePanel' );


    // hooks
    const [ coverImg, setCoverImg ] = useState( '' );
    const [ profileImg, setProfileImg ] = useState( '' );
    // hmmmm
    const [ club, setClub ] = useState( isFirstRender ? null : store.club );
    const [ isEditing, setIsEditing ] = useState( false );
    const [ isEditingCover, setIsEditingCover ] = useState( false );
    const [ isEditingPortfolioImg, setIsEditingPortfolioImg ] = useState( false );
    const [ userHasClub, setUserHasClub ] = useState( patron && patron.personas && !!patron.personas.find( ( persona : Persona ) => persona.role === Roles.producer ) );
    const [ errorMsgs, setErrorMsgs ] = useState( {
        [ fields.coverImg ] : '',
        [ fields.profileImg ] : '',
        [ fields.name ] : '',
        [ fields.tagLine ] : '',
        [ fields.blurb ] : '',
        [ fields.tags ] : ''
    } );

    if( !store.sessionData.club ) {
        cloneClubToSessionData();
    }

    if( isFirstRender ){
        isFirstRender = false;

        store.club = null;
        unsubscribeFromDoc( docTypes.club );
    }

    const storedClub = store.sessionData.club as Club;

    const cloudCoverImg = storedClub && storedClub.profile && storedClub.profile.coverImg ?
      unescape( storedClub.profile.coverImg.split( '?' )[ 0 ].split( '/' )[ 1 ].toString() ) : 'noImage';

    const cloudProfileImg = storedClub && storedClub.profile && storedClub.profile.profileImg ?
      unescape( storedClub.profile.profileImg.split( '?' )[ 0 ].split( '/' )[ 1 ].toString() ) : 'noImage';

    if( storedClub.profile && storedClub.profile.coverImg && cloudCoverImg !== coverImg ) {
        getFile( storedClub.profile.coverImg )
          .then( url => {
              setCoverImg( url );
          } )
          .catch( err => logger.error( err ) );
    }

    if( storedClub.profile && storedClub.profile.profileImg && cloudProfileImg !== profileImg ) {
        getFile( storedClub.profile.profileImg )
          .then( url => {
              setProfileImg( url );
          } )
          .catch( err => logger.error( err ) );
    }

    const btnProfileRef : any = useRef( null );
    const btnCoverRef : any = useRef( null );

    // only runs after the *first* render due to an empty dependency array passes as the second param
    useEffect( () => {
        if( userHasClub && !store.club ) {
            subscribeToDoc( docTypes.producer, store.patron.patronID );
        }
        // when the component is unmounted the reset function will be called
        return reset;
    }, [] );

    //user exits then you want to reset all of the data, and reupdate sessionData.club to the one from the database
    function reset( onlyData : boolean = false ) : void {
        store.hasUnsavedData = false;
        cloneClubToSessionData();
        if( !onlyData ) removeSignals();
        setIsEditing( false );
    }

    // callbacks
    const patronUpdated = useCallback( ( newPatron : Patron ) : any => {
        newPatron.personas = newPatron.personas || [];
        setUserHasClub( newPatron && !!newPatron.personas.find( ( persona : Persona ) => persona.role === Roles.producer ) );
    }, [] );

    const producerUpdated = useCallback( ( newProducer : Producer ) : any => {
        if( !newProducer ) return logger.error( 'invalid producer received :', newProducer );

        newProducer.clubIDs = newProducer.clubIDs || [];
        if( !newProducer.clubIDs[ 0 ] ) return logger.error( 'producer has no clubIDs!?!?' );
        subscribeToDoc( docTypes.club, newProducer.clubIDs[ 0 ], docTypes.club, true );
    }, [] );

    const clubUpdated = useCallback( ( newClub : Club ) => {
        cloneClubToSessionData();
        setIsEditing( false );
        setUserHasClub( true );
        setClub( newClub );
    }, [] );

    // signals remove
    removeSignals();

    // signals add
    addSignals();

    function removeSignals() {
        store.clubUpdated.remove( clubUpdated );
        store.producerUpdated.remove( producerUpdated );
        store.patronUpdated.remove( patronUpdated );
    }

    function addSignals() {
        store.clubUpdated.add( clubUpdated );
        store.producerUpdated.add( producerUpdated );
        store.patronUpdated.add( patronUpdated );
    }

    function cloneClubToSessionData() {
        const clubClone = store.club ? deepCloneUsingJSON( store.club ) : deepCloneUsingJSON( emptyClub );
        store.sessionData = {
            club : {
                ...clubClone
            }
        };
    }

    if( !patron ) {
        setPath();
        return null;
    }

    const noClubYet : boolean = !!userHasClub && !club;

    // TODO: if( noClubYet ) start a timer, clear timer in clubUpdated, if timer ends - go home

    return (
        <div className='panel-profile-club'>
            {!userHasClub && !isEditing && getInitialPanel()}
            {(userHasClub || isEditing) && getProfilePanel()}
            {noClubYet && <SpinnerModal/>}
            {userHasClub && !!club && getSchedulePanel()}
        </div>
    );

    function resetErrorMsg(fieldName: fields): void {
        setErrorMsgs({
            ...errorMsgs,
            [fieldName]: ''
        });
    }

    function inputValueChanged(e: any) {
        const clubSnapshot = store.sessionData.club;
        const propName: fields = e.target.getAttribute('data-prop') as fields;

        switch (propName) {
            case fields.name :
                store.sessionData = {
                    club: {
                        ...store.sessionData.club,
                        name: e.target.value
                    } as Club
                };
                break;
            default :
                store.sessionData = {
                    club: {
                        ...clubSnapshot,
                        profile: {
                            ...clubSnapshot.profile,
                            [propName]: e.target.value
                        }
                    } as Club
                };
        }

        resetErrorMsg(propName);
    }

    function onTagsChanged(newTags: string[]): void {
        const clubSnapshot = store.sessionData.club;

        newTags.find((tag: string, i: number) => {
            newTags[i] = tag.trim();
        });
        store.sessionData = {
            club: {
                ...clubSnapshot,
                tags: newTags,
                profile: {
                    ...clubSnapshot.profile,
                }
            } as Club
        };
    }

    function getErrorMsgs(e: FocusEvent<HTMLInputElement>) {
        const propName: fields = e.target.getAttribute('data-prop') as fields;

        let isCoverImgValid: boolean = true;
        let isProfileImgValid: boolean = true;

        const isDisplayNameValid: boolean = (
            ((fields.name.length <= 50)
                && (fields.name.length >= 1)
                && (fields.name.indexOf(' ') <= 0))
        );

        let isTagLineValid: boolean = true;
        if (fields.tagLine.length >= 300) {
            isTagLineValid = false;
        }

        let isBioValid: boolean = true;
        if (fields.blurb.length >= 300) {
            isBioValid = false;
        }

        let areTagsValid: boolean = true;

        if (fields.tags.length >= 10) {
            areTagsValid = false;
        }

        const newErrorMessages = {
            coverImg: isCoverImgValid ? '' : 'file must be less than 5MB and a PNG, JPG or GIF',
            profileImg: isProfileImgValid ? '' : 'file must be less than 5MB and a PNG, JPG or GIF',
            name: isDisplayNameValid ? '' : 'display name must be less than 50 characters with no whitespace',
            tagLine: isTagLineValid ? '' : 'tag line is limited to 300 chars',
            blurb: isBioValid ? '' : 'bio is limited to 300 chars',
            tags: areTagsValid ? '' : 'comma separated, max of five'
        };
        setErrorMsgs({...errorMsgs, [fields[propName]]: newErrorMessages[propName]});
    }

    function newCoverImgReceived({file, path}) {
        if (!path) return logger.warn(CN + '.newCoverImgReceived received an invalid path : ', path);

        storedClub.profile = storedClub.profile || {};
        storedClub.profile.coverImg = path;
        getFile(path)
            .then(url => {
                setCoverImg(url);
            })
            .catch(err => logger.error(err));
        saveClubProfile();
    }

    function removeCoverImg() {
        storedClub.profile = storedClub.profile || {};
        const path = storedClub.profile.coverImg || '';
        storedClub.profile.coverImg = defaultStorage.patronCoverImg;
        saveClubProfile();
        setIsEditingCover(false);
    }

    function newProfileImgReceived({file, path}) {
        if (!path) return logger.warn(CN + '.newProfileImgReceived received an invalid path : ', path);

        storedClub.profile = storedClub.profile || {};
        storedClub.profile.profileImg = path;
        getFile(path)
            .then(url => {
                setProfileImg(url);
            })
            .catch(err => logger.error(err));
        saveClubProfile();
    }

    function removeProfileImg() {
        storedClub.profile = storedClub.profile || {};
        const path = storedClub.profile.profileImg || '';
        storedClub.profile.profileImg = defaultStorage.patronProfileImg;
        saveClubProfile();
        setIsEditingPortfolioImg(false);
    }

    function saveClubProfile() {
        const clubSnapshot = deepCloneUsingJSON( store.sessionData.club );
        const profile = clubSnapshot.profile;

        // normalize and clamp
        clubSnapshot.producerID = store.patron.patronID;
        clubSnapshot.name = getSlicedString( clubSnapshot.name, limits.name );
        clubSnapshot.tags = getSlicedArray( clubSnapshot.tags, limits.tags );
        profile.coverImg = getSlicedString( profile.coverImg, limits.maxString );
        profile.profileImg = getSlicedString( profile.profileImg, limits.maxString );
        profile.tagLine = getSlicedString( profile.tagLine, limits.tagLine );
        profile.blurb = getSlicedString( profile.blurb, limits.blurb );
        profile.linkURLs = {
            instagram : profile.linkURLs?.instagram || '',
            facebook : profile.linkURLs?.facebook || '',
            soundcloud : profile.linkURLs?.soundcloud || '',
            spotify : profile.linkURLs?.spotify || '',
            twitch : profile.linkURLs?.twitch || '',
            twitter : profile.linkURLs?.twitter || '',
            youtube : profile.linkURLs?.youtube || '',
            web : profile.linkURLs?.web || '',
        };

        if( userHasClub ) {
            store.dialogToDisplay = dialogIDs.spinner;
            clubUpdate( clubSnapshot )
              .then( ( updatedClub : Club ) => {
                  store.dialogToDisplay = '';
                  setIsEditing( false );
                  reset( true );
                  store.club = updatedClub;
              } )
              .catch( err => {
                  store.dialogToDisplay = '';
                  logger.error( err );
              } );
        } else {
            clubCreate( clubSnapshot )
              .then( ( newClub : Club ) => {
                  store.dialogToDisplay = '';
                  setIsEditing( false );
                  //userHasClub is false when reloaded
                  reset( true );
                  store.club = newClub;
              } )
              .catch( err => {
                  store.dialogToDisplay = '';
                  logger.error( err );
              } );
        }
    }

    function cancel() {
        reset();
    }

    function getProfilePanel() {
        return <div className='profile-panel'>
            <h3 className={ 'left' }>{ userHasClub ? 'Edit Club' : 'New Club' }</h3>
            <div
                className='panel-img-cover'
                style={{
                    backgroundImage: coverImg ? `url(${coverImg})` : ''
                }}>
                <button
                    className='btn-edit-cover'
                    onBlur={ blurDelayWrapper( () => setIsEditingCover( false ) ) }
                    onClick={() => setIsEditingCover(!isEditingCover)}>
                    Edit Cover
                </button>
                <FileUploader
                    accepts={fileFilters.allWebImages}
                    btn={btnCoverRef.current}
                    onFileSelected={newCoverImgReceived}
                    folderName={"cover_imgs"}
                />
                <div className={`menu menu-img-cover${isEditingCover ? ' displayed' : ''}`}>
                    <div
                        ref={btnCoverRef}
                        className='menu-item upload'
                        tabIndex={0}>Upload New Cover
                    </div>
                    <div
                        className='menu-item trash'
                        onClick={removeCoverImg}
                        tabIndex={0}>Remove Cover Photo
                    </div>
                </div>
                {!isEditing &&
                <button
                    className='btn-edit-profile'
                    onClick={ () => {
                        store.hasUnsavedData = true;
                        setIsEditing( !isEditing );
                    }}>
                    Edit Profile </button>
                }
            </div>
            <div
                className='profile-img'
                style={{backgroundImage: profileImg ? `url(${profileImg})` : ''}}>
                { /* the backgroundImage above will be dynamic */}
                <button
                    className='btn-edit-profile-img'
                    onClick={e => {
                        setIsEditingPortfolioImg(!isEditingPortfolioImg);
                    }}
                    onBlur={blurDelayWrapper(() => setIsEditingPortfolioImg(false))}
                    tabIndex={0}/>
                <div className={`menu menu-img-profile ${ isEditingPortfolioImg ? ' displayed' : '' }`}>
                    <div
                        ref={btnProfileRef}
                        className='menu-item upload'
                        tabIndex={0}>Upload New Photo
                    </div>
                   <FileUploader
                     btn={btnProfileRef.current}
                     accepts={fileFilters.allWebImages}
                     onFileSelected={newProfileImgReceived}
                     folderName={"profile_imgs"}
                   />
                    <div
                        className='menu-item trash'
                        onClick={removeProfileImg}
                        tabIndex={0}>Remove Profile Photo
                    </div>
                </div>
            </div>
            {!isEditing && <h2>{storedClub.name || 'Your Club Name'}</h2>}
            {isEditing &&
            <Input
                fieldName={fields.name}
                placeholder={'your club name'}
                maxLength={50}
                onChange={inputValueChanged}
                onBlur={getErrorMsgs}
                errorMsgs={errorMsgs}
                snapshot={storedClub}/>
            }
            {!isEditing && <h3>{(storedClub.profile as Profile).tagLine || 'your club tag line'}</h3>}
            {isEditing &&
            <Input
                fieldName={fields.tagLine}
                placeholder={'your club tagline'}
                maxLength={50}
                onChange={inputValueChanged}
                onBlur={getErrorMsgs}
                errorMsgs={errorMsgs}
                snapshot={storedClub.profile}/>
            }
            { !isEditing && <h4>{ ( storedClub.profile as Profile ).blurb || 'about your club' }</h4> }
            { isEditing &&
            <Fragment>
                <textarea
                    defaultValue={ ( storedClub.profile as Profile ).blurb }
                    data-prop={ 'blurb' }
                    maxLength={ 300 }
                    className={ 'input-bio'}
                    placeholder={ 'tell us all something about your club' }
                    onChange={ inputValueChanged }
                    tabIndex={ 0 }
                    rows={ 3 }
                />
                <p className={'left'}>max of 300 characters</p>
            </Fragment>
            }
            <SocialBar
                type={socialBarTypes.club}
                isEditing={isEditing}
            />
            {isEditing &&
            <InputTags tags={ storedClub.tags || [] } onTagsChanged={onTagsChanged}/>
            }
            {!isEditing &&
              <div className={"tags"}>
                  { ( storedClub.tags || [] ).map( tag => <div className="tag">{ tag.trim() }</div> ) }
              </div>
            }
            {isEditing &&
            <div className='footer'>
                <button
                    id={'btn-cancel'}
                    className={'btn-cancel'}
                    onClick={cancel}>Cancel
                </button>
                <button
                    id={'btn-save'}
                    className={'btn-save'}
                    onClick={saveClubProfile}>Save Club Profile
                </button>
            </div>
            }
        </div>;
    }

    function getSchedulePanel() {
        return null;
        /*
        return <div className='schedule-panel'>
            <h2>Upcoming</h2>
            <div className='grid-calendar'>
                <button
                    className='btn btn-calendar-today'>Today
                </button>
                <div className='grid-buttons'>
                    <div className={'grid-buttons2'}>
                        <button
                            className='btn-calendar-left-arrow'/>
                        <button
                            className='btn-calendar-right-arrow'/>
                        <button
                            className='btn-calendar-calendar'/>
                        <p>Jan 4, 2021 - Jan 10, 2021</p>
                    </div>
                </div>
                <div className='grid-time-zone'>PST</div>

                <div className='grid-time-times'>time line</div>

                <div className='row-mon'>
                    Mon
                </div>
                <div className='row-tues'>
                    Tues
                </div>
                <div className='row-wed'>
                    Wed
                </div>
                <div className='row-thurs'>
                    Thurs
                </div>
                <div className='row-fri'>
                    Fri
                </div>
                <div className='row-sat'>
                    Sat
                </div>
                <div className='row-sun'>
                    Sun
                </div>
            </div>
        </div>;

         */
    }

    function getInitialPanel() {
        return <Fragment>
            <h3>Are you a Producer?</h3>
            <div className='initial-panel'>
                <p>If you are going to create and host events you will need to create a club which will register you as
                    a
                    producer.</p>
                <button
                    className='btn-create-club'
                    onClick={() => {
                        store.hasUnsavedData = true;
                        setIsEditing(true);
                    }}>
                    Create a Club
                </button>
            </div>
        </Fragment>;
    }
}
