import { KeyboardEvent, useState, useEffect, useRef, ChangeEvent } from 'react';
import * as logger from '../../utils/logger';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import './InvitePanel.less';
import { Patron } from '../../vibe/vibe';

const CN : string = 'InvitePanel';

enum types {
  none = '',
  phone = 'phone',
  email = 'email',
  patron = 'patron'
}

interface invitee {
  type : types,
  phone? : string,
  email? : string,
  patron? : Patron
}

export default function InvitePanel() {
  const [ invitees, setInvitees ] = useState( [] as invitee[] );
  const [ patrons, setPatrons ] = useState( [] as Patron[] );

  const textareaRef = useRef( null as any );

  const useStyles = makeStyles( ( theme : Theme ) =>
    createStyles( {
      root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
      },
    } ),
  );

  const classes = useStyles();

  return <div className="panel-invite">
    { getInviteeTags() }
    <div className="txt-and-list">
      <textarea
        ref={ textareaRef }
        rows={ 1 }
        spellCheck="false"
        autoComplete="false"
        autoCapitalize="off"
        autoCorrect="off"
        tabIndex={ 0 }
        dir="ltr"
        aria-label="invite"
        role="combobox"
        aria-autocomplete="list"
        wrap="off"
        onChange={ ( e : ChangeEvent<HTMLTextAreaElement> ) => {
          const textarea : HTMLTextAreaElement = e.target as HTMLTextAreaElement;
          entryChanged( textarea.value );
        } }
        onKeyDown={ ( e : KeyboardEvent<HTMLTextAreaElement> ) => {
          const textarea : HTMLTextAreaElement = e.target as HTMLTextAreaElement;
          const keyName : string = e.key.toLowerCase();
          if( keyName === 'enter' ||
            keyName === 'tab' ||
            keyName === ',' ||
            keyName === ';'
          ) {
            e.preventDefault();
            addInvitee( textarea.value );
          }
        } }
      />
      { !!patrons.length &&
        <List dense className={classes.root}>
          { patrons.map( ( patron) => {
            return (
              <ListItem
                key={ patron.patronID }
                id={ patron.patronID }
                button
                onClick={ () => patronClicked( patron ) }
              >
                <ListItemAvatar>
                  <Avatar
                    alt={ `comment : fix the src` }
                    src={`${ patron.profile?.profileImg }`}
                  />
                </ListItemAvatar>
                <ListItemText id={ patron.patronID } primary={`${ patron.name }`} />
              </ListItem>
            );
          })}
        </List>
      }
    </div>
  </div>;

  function entryChanged( searchStr : string ) : void {
    if( getValueType( searchStr ) === types.patron ){
      console.log( 'search patrons DB...' );
    }
  }

  function getValueType( str : string ) : types {
    const phoneRegex : RegExp = /[0-9]|\(|\)|-|\+/gm;
    const indexAmp = str.indexOf( '@' );
    const indexDot = str.indexOf( '.' );

    switch( true ) {
      case !str :
        return types.none;
      case indexAmp > -1 && indexDot > -1 && indexAmp < indexDot :
        return types.email;
      case ( str.match( phoneRegex ) || [] ).length === str.length :
        return types.phone;
      default :
        return types.patron;
    }
  }

  function addInvitee( currentValue : string ) : void {
    console.log( CN + '.addInvitee' );
    console.log( 'currentValue :', currentValue );

    switch( getValueType( currentValue ) ) {
      case types.none :
        // noop
        break;
      case types.email :
        setInvitees( [ ...invitees, { type : types.email, email : currentValue } as invitee ] );
        break;
      case types.phone :
        setInvitees( [ ...invitees, { type : types.phone, phone : currentValue } as invitee ] );
        break;
      case types.patron :
        console.log( 'TODO : add current patron to invitees' );
        break;
      default :
        logger.warn( '¡unexpected case encountered!' );
    }
  }

  function patronClicked( patron : Patron ) {
    console.log( CN + '.patronClicked' );
    console.log( 'patron :', patron );

  }

  function getInviteeTags() {
    return invitees.map( ( invited : invitee ) => invited ? <div
      className="invitee-tag">
      { getTagLabel( invited ) }
    </div> : null );
  }

  function getTagLabel( invited : invitee ) : string {
    switch( true as boolean ) {
      case invited.type === types.email :
        return invited.email as string;
      case invited.type === types.phone :
        return invited.phone as string;
      case invited.type === types.patron :
        return ( invited.patron || { name : '' } ).name as string;
      default :
        return '';
    }
  }
}
