import { useState, ChangeEvent } from "react";
import { store } from '../../data/store';
import ButtonVibe from "../ButtonVibe/ButtonVibe";
import InputText from "../InputText/InputText";
import DialogHeader from "../DialogHeader/DialogHeader";

import "./DialogVerCode.less";

const CN : string = 'DialogVerCode';

export default function DialogVerCode() {

  // hooks

  const [ verCode, setVerCode ] = useState( '' );
  const [ errMsg, setErrMsg ] = useState( '' );

  return ( <div className="dialog-ver-code">
    <DialogHeader title={ 'SMS Verification Code' }/>
    <div className="body">
      <InputText
        fieldName={ 'ver-code' }
        onChange={ ( e : ChangeEvent<HTMLInputElement> ) => setVerCode( e.target.value ) }
        label={ 'code' }
        errorMsg={ errMsg }
      />
    </div>
    <div className="footer">
      <ButtonVibe
        key={ 'btn-verify' }
        label={ 'Verify' }
        onClick={ () => {
          if( !verCode ) {
            setErrMsg( 'please enter code' );
          } else {
            store.verCode = verCode;
            store.dialogToDisplay = '';
          }
        } }
        isDisabled={ !verCode }
      />
    </div>
  </div> );

}
