import { useEffect } from 'react';
import { MimeTypes, FXinfo } from '../../vibe/vibe';
// import * as logger from '../../utils/logger';

import './FXpanel.less';

interface props {
  fxInfo : FXinfo,
  setFXinfo : Function
}

const CN : string = 'FXpanel';

export default function FXpanel( {
  fxInfo,
  setFXinfo
} : props ) {
  const duration : number = fxInfo?.mediaInfo?.duration || 6;

  useEffect( () => {
    return cleanup;
  }, [] );

  return <div className="panel-fx">
    <object
      className={ `svg-in-object ${ fxInfo?.filter }` }
      type={ MimeTypes.svg }
      data={ fxInfo?.mediaInfo?.url }
      onLoad={ startTimeout }
    />
  </div>;

  function startTimeout() {
    window.setTimeout( () => setFXinfo( null ), duration * 1000 );
  }

  function cleanup() {

  }
}
