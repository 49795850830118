import styled, { keyframes } from 'styled-components';
import { getCSSvar } from '../../utils/utils';

const lineScalePulseOut = keyframes`
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
  }
`;

function delay(interval: string, count: number, index: number) {
  return `${((index * parseFloat(interval)) - (parseFloat(interval) * count)).toFixed(2)}s`;
}

const LoadContainer = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
`;

const line = styled.div`
  border-radius: 2px;
  height: 35px;
  margin: 2px;
  width: 4px;
  background-color: ${getCSSvar('--clr-brand')};
  animation-fill-mode: both;
  animation: ${lineScalePulseOut} 0.9s ${delay('0.2s', 3, 0)} infinite cubic-bezier(.85,.25,.37,.85);
  display: inline-block;
`;

const LineFirst = styled(line)`
  animation-delay: ${delay('0.2s', 3, 2)} !important;
`;

const LineFifth = styled(line)`
  animation-delay: ${delay('0.2s', 3, 2)} !important;
`;

const LineSecond = styled(line)`
  animation-delay: ${delay('0.2s', 3, 1)} !important;
`;

const LineFourth = styled(line)`
  animation-delay: ${delay('0.2s', 3, 1)} !important;
`;

const LineThird = styled(line)``;

const commonStyle = {
  margin: 'auto',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0
};

export default function SpinnerModal() {
  return (
    // @ts-ignore
    <LoadContainer style={commonStyle}>
      <LineFirst/>
      <LineSecond/>
      <LineThird/>
      <LineFourth/>
      <LineFifth/>
    </LoadContainer>
  );
}
