import { getSignal } from '../utils/utils';
import { AudioVideoFacade, DataMessage } from 'amazon-chime-sdk-js';
import * as logger from '../utils/logger';
import { FXinfo } from '../vibe/vibe';

const CN : string = 'relay';

//region enums
// akin to chime's 'topic'
export enum RelayTypes {
  ChatMsg    = 'ChatMsg',
  TipEvent   = 'TipEvent',
  SoundEvent = 'SoundEvent',
  FXevent    = 'FXevent'
}
//endregion

//region interfaces
export interface RelayPayload {
  type : RelayTypes,
  data : ChatMsg | TipEvent | SoundEvent | FXevent
}

interface PayloadData {
  from : string,
  to : string[]
}

export interface ChatMsg extends PayloadData {
  msg : string,
  fromName : string
}

export interface TipEvent extends PayloadData {
  tipAmount : number
}

export interface SoundEvent extends PayloadData {
  soundID : string,
  url : string
}

export interface FXevent extends PayloadData {
  fxID : string,
  filter : string,
  fxInfo? : FXinfo
}


//endregion

//region public consts
export const relaySignals = {
  [ RelayTypes.ChatMsg ] : getSignal( 'receivedChatMsg', 2 ),
  [ RelayTypes.TipEvent ] : getSignal( 'receivedTipEvent', 2 ),
  [ RelayTypes.SoundEvent ] : getSignal( 'receivedSoundEvent', 2 ),
  [ RelayTypes.FXevent ] : getSignal( 'receivedFXevent', 2 )
}
//endregion

//region public functions
export function sendPayload( audioVideo : AudioVideoFacade | null, payload : RelayPayload ) : void {
  console.log( CN + '.sendPayload' );
  console.log( 'payload :', payload );

  if( !payload ) return logger.error( 'invalid payload - not sending.' );

  if( relaySignals[ payload.type ] ) {
    if( !audioVideo ) logger.warn( 'MISSING audioVideo!!' );
    audioVideo?.realtimeSendDataMessage?.( payload.type, JSON.stringify( payload.data ) );
    relaySignals[ payload.type ].dispatch( payload.data );
  } else {
    logger.error( `ERROR : bad payload.type encountered payload not being sent - dataMessage.topic : ${ payload.type }` );
  }
}
//endregion

let lastAudioVideo : AudioVideoFacade | null = null;

//region custom react hook
export function useP2Prelay( audioVideo : any ) {
  logger.log( CN + '.useP2Prelay' );

  if( lastAudioVideo !== audioVideo ) {
    for( let topic in RelayTypes ) {
      logger.log( `subscribing to topic : ${ topic }` );
      if( !audioVideo ) logger.warn( `oops - no audioVideo - not subscribing to topic : ${ topic }` );
      lastAudioVideo?.realtimeUnsubscribeFromReceiveDataMessage( topic );
      audioVideo?.realtimeSubscribeToReceiveDataMessage( topic, dataMessageReceived );
    }
    lastAudioVideo = audioVideo;
  }

  function relayPayload( payload ){
    sendPayload( audioVideo, payload );
  }

  return {
    relaySignals,
    relayPayload
  };
}
//endregion

//region private functions
function dataMessageReceived( dataMessage : DataMessage ) {
  console.log( CN + '.dataMessageReceived' );
  console.log( 'dataMessage :', dataMessage );

  if( relaySignals[ dataMessage.topic ] ) {
    relaySignals[ dataMessage.topic ].dispatch( dataMessage.json() );
  } else {
    logger.error( `ERROR : unknown dataMessage encountered - dataMessage.topic : ${ dataMessage.topic }` );
  }
}
//endregion
