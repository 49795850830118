import { store } from '../../data/store';
import { useState } from 'react';
import { purchaseItemByPriceID } from '../../ecomm/ecomm';

import './DialogPurchaseMojo.less';

export default function DialogPurchaseMojo() {
  const [ currentSlideIndex, setCurrentSlideIndex ] = useState( 0 );

  /*
    Next Steps :
      1. add a metadata field to all mojo bundle products in Stripe that signifies that this product is a mojo bundle
      2. read from our firestore db 'stripe_products' to get all products
      3. create an array of all mojo bundle products sorted by least expensive to most expensive
      4. use this new array to make this dialog data driven and dynamic (no hard coded values)
  */

  const slides = [ 'Spare Change', 'Party Bag', 'Player Pack', 'Make it Reign!' ];

  function previousSlide () {
    const lastIndex = slides.length - 1;
    const shouldResetIndex = currentSlideIndex === 0;
    const index =  shouldResetIndex ? lastIndex : currentSlideIndex - 1;

    setCurrentSlideIndex( index );
  }

  function nextSlide () {
    const lastIndex = slides.length - 1;
    const shouldResetIndex = currentSlideIndex === lastIndex;
    const index =  shouldResetIndex ? 0 : currentSlideIndex + 1;

    setCurrentSlideIndex( index );
  }

  function clicked() {
    switch ( currentSlideIndex ) {
      case 0:
        purchaseItemByPriceID( 'price_1IJRYFKptTjekPceQOQGLvz1', 1 );
        break;
      case 1:
        purchaseItemByPriceID( 'price_1IJRYpKptTjekPceYA9PnxTD', 1 );
        break;
      case 2:
        purchaseItemByPriceID( 'price_1IJRaKKptTjekPcetnxsp9oZ', 1 );
        break;
      case 3:
        purchaseItemByPriceID( 'price_1IJRauKptTjekPceXeZMfTzx', 1 );
        break;
      default:
        break;
    }
  }

  return (
    <div className="dialog-purchase-mojo">
       <button
        className="btn-close"
        onClick={() => store.dialogToDisplay = ''}/>
      <div onClick={ previousSlide } className="arrow icon-arrow-left">&#706;</div>
      <div onClick={ nextSlide } className="arrow icon-arrow-right">&#707;</div>
      {slides.map(( slide, index ) => {
        return (
          <div className={ index === currentSlideIndex ? 'slide active' : 'slide' } key={ index }>
            { index === currentSlideIndex && slide }
          </div>
        )
      })}
      <button
        className="btn-buy"
        onClick={ clicked }>Buy Mojo</button>
    </div>
  )
}
