import * as logger from '../utils/logger';
import { SettingsPanelModes, userConfirmations } from './consts';
import { Breakpoints } from '../utils/responsive';
import { Club, Patron, Polycast, Producer, VibeEvent } from '../vibe/vibe';
import { rtcDevice, rtcDeviceTypes } from '../rtc/typesRTC';
import { getSignal } from '../utils/utils';
import { KeyValue } from '../../../functions/src/vibe/vibe';

export const store = {
  // flags
  _flags : { debug : 0 },
  flagsUpdated : getSignal( 'flagsUpdated', 2 ),
  set flags( newFlags : any ) {
    store._flags = {
      ...store._flags,
      ...newFlags
    };
    delete store._flags[ '' ];
    store.flagsUpdated.dispatch( store._flags );
  },
  get flags() { return store._flags; },

  // testData
  _testData : {},
  testDataUpdated : getSignal( 'testDataUpdated', 2 ),
  set testData( newTestData : any ) {
    store._testData = newTestData;
    store.testDataUpdated.dispatch( store._testData );
  },
  get testData() { return store._testData; },

  // appMode
  _appMode : '',
  appModeUpdated : getSignal( 'appModeUpdated', 2 ),
  set appMode( newMode : string ) {
    store._appMode = newMode;
    store.appModeUpdated.dispatch( newMode );
  },
  get appMode() { return store._appMode; },

  // verCode
  _verCode : '' as string,
  verCodeUpdated : getSignal( 'verCodeUpdated', 2 ),
  set verCode( newCode : string ) {
    store._verCode = newCode;
    store.verCodeUpdated.dispatch( store._verCode );
  },
  get verCode() { return store._verCode; },

  // userConfirmations
  _userConfirmations : {
    // TODO : remove this true value - only for demo
    [ userConfirmations.costSoundFX ] : true
  } as any,
  userConfirmationsUpdated : getSignal( 'userConfirmationsUpdated', 2 ),
  set userConfirmations( keyValue : KeyValue ) {
    store._userConfirmations[ keyValue.Key ] = keyValue.Value;
    store.userConfirmationsUpdated.dispatch( keyValue, store._userConfirmations );
  },
  get userConfirmations() { return store._userConfirmations; },

  // needsCookieConsent
  _needsCookieConsent : true,
  needsCookieConsentUpdated : getSignal( 'needsCookieConsentUpdated', 2 ),
  set needsCookieConsent( bool : boolean ) {
    store._needsCookieConsent = bool;
    store.needsCookieConsentUpdated.dispatch( store._needsCookieConsent );
  },
  get needsCookieConsent() { return store._needsCookieConsent; },

  // streamDevices
  _streamDevices: [] as rtcDevice[],
  streamDevicesUpdated : getSignal( 'streamDevicesUpdated', 2 ),
  get streamDevices() {
    return store._streamDevices;
  },
  set streamDevices( newStreamDevices : rtcDevice[] ) {
    store._streamDevices = newStreamDevices || [];
    store.streamDevicesUpdated.dispatch( store._streamDevices );
  },

  get streamDevicesAudio() {
    return store._streamDevices.filter( ( device) => device.kind === rtcDeviceTypes.audioinput );
  },

  get streamDevicesVideo() {
    return store._streamDevices.filter( ( device) => device.kind === rtcDeviceTypes.videoinput );
  },

  // hasUnsavedData
  _hasUnsavedData : false,
  set hasUnsavedData( newBool ) {
    store._hasUnsavedData = newBool;
    if( store._hasUnsavedData ) {
      if( window.onbeforeunload === null ) {
        window.onbeforeunload = () => true;
      }
    } else {
      window.onbeforeunload = null;
    }

  },
  get hasUnsavedData() {
    return store._hasUnsavedData;
  },

  // isMicMuted
  _isMicMuted : false,
  isMicMutedUpdated : getSignal( 'isMicMutedUpdated', 2 ),
  set isMicMuted( newBool ) {
    store._isMicMuted = newBool;
    store.isMicMutedUpdated.dispatch( store._isMicMuted );
  },
  get isMicMuted() {
    return store._isMicMuted;
  },

  // isPolycastMuted
  _isPolycastMuted : false,
  isPolycastMutedUpdated : getSignal( 'isPolycastMutedUpdated', 2 ),
  set isPolycastMuted( newBool ) {
    store._isPolycastMuted = newBool;
    store.isPolycastMutedUpdated.dispatch( store._isPolycastMuted );
  },
  get isPolycastMuted() {
    return store._isPolycastMuted;
  },

  // isAway
  _isAway : false,
  isAwayUpdated : getSignal( 'isAwayUpdated', 2 ),
  set isAway( newBool : boolean ) {
    store._isAway = newBool;
    store.isAwayUpdated.dispatch( store._isAway );
  },
  get isAway() {
    return store._isAway;
  },

  // isChatEnabled
  _isChatEnabled : false,
  isChatEnabledUpdated : getSignal( 'isChatEnabledUpdated', 2 ),
  set isChatEnabled( newBool ) {
    store._isChatEnabled = newBool;
    store.isChatEnabledUpdated.dispatch( store._isChatEnabled );
  },
  get isChatEnabled() {
    return store._isChatEnabled;
  },

  // isChatDisplayed
  _isChatDisplayed : false,
  isChatDisplayedUpdated : getSignal( 'isChatDisplayedUpdated', 2 ),
  set isChatDisplayed( newBool ) {
    store._isChatDisplayed = newBool;
    store.isChatDisplayedUpdated.dispatch( store._isChatDisplayed );
  },
  get isChatDisplayed() {
    return store._isChatDisplayed;
  },

  // chatHistory
  /*
  // TODO : alpha : get rid of dummy chat
  _chatHistory : starterChatHistory as ChatMsg | ChatMsg[],
  chatHistoryUpdated : getSignal( 'chatHistoryUpdated', 2 ),
  set chatHistory( chatMsg ) {
    if( chatMsg === null ) {
      store._chatHistory = starterChatHistory;
    } else if( chatMsg ){
      ( store._chatHistory as ChatMsg[] ).push( chatMsg as ChatMsg );
    }
    store.chatHistoryUpdated.dispatch( store._chatHistory, store._chatHistory[ ( store._chatHistory as ChatMsg[] ).length - 1 ] );
  },
  get chatHistory() { return store._chatHistory },
   */

  // isLoading
  _isLoading: false,
  isLoadingUpdated : getSignal( 'isLoadingUpdated', 0 ),
  set isLoading( newBool: boolean ) {
    store._isLoading = newBool;
    store.isLoadingUpdated.dispatch( store._isLoading );
  },
  get isLoading() { return store._isLoading; },

  // vibeEvent
  _club: null as Club | any,
  clubUpdated : getSignal( 'clubUpdated', 2 ),
  set club( newClub : Club | any ) {
    store._club = newClub;
    store.clubUpdated.dispatch( store._club );
  },
  get club() { return store._club; },

  // producer
  _producer: null as any | Producer,
  producerUpdated : getSignal( 'producerUpdated', 2 ),
  set producer( newProducer : Producer ) {
    store._producer = newProducer;
    store.producerUpdated.dispatch( store._producer );
  },
  get producer() { return store._producer; },

  // user
  _user : null as any,
  userUpdated : getSignal( 'userUpdated', 2 ),
  set user( updatedUser : any ) {
    switch( true ) {
      case store._user === null && updatedUser === null :
        // noop
        break;
      case store._user === null && updatedUser !== null :
      case store._user !== null && updatedUser === null :
        store._user = updatedUser;
        store.userUpdated.dispatch( store._user );
        break;
      // TODO : do we want to keep merging these objects?
      case store._user !== null && updatedUser !== null :
        store._user = {
          ...store._user,
          ...updatedUser
        };
        store.userUpdated.dispatch( store._user );
        break;
      default :
        logger.warn( 'an unhandled case was encountered by store.user (set)' );
    }
  },
  get user() {
    return store._user;
  },

  // patron
  _patron : null as Patron | any,
  patronUpdated : getSignal( 'patronUpdated', 2 ),
  set patron( newPatron : Patron | any ){
    store._patron = newPatron;
    store.patronUpdated.dispatch( store._patron );
  },
  get patron() : Patron | any {
    return store._patron;
  },

  // patrons
  _patrons : [] as Patron[] ,
  patronsUpdated : getSignal( 'patronsUpdated', 2 ),
  set patrons( newPatrons : Patron[] ){
    if( !newPatrons || !newPatrons.length )
    {
      store._patrons = [];
    } else {
      store._patrons = store._patrons.concat( newPatrons );
    }
    store.patronsUpdated.dispatch( store._patrons );
  },
  get patrons() : Patron[] {
    return store._patrons;
  },


  // appTitle
  _appTitle : 'VibeWith.me',
  appTitleUpdated : getSignal( 'appTitleUpdated', 2 ),
  set appTitle( newTitle : string ) {
    newTitle = newTitle || 'VibeWith.me';
    store._appTitle = newTitle;
    store.appTitleUpdated.dispatch( store._appTitle );
  },
  get appTitle() { return store._appTitle; },

  // settingsPanelMode
  _settingsPanelMode : SettingsPanelModes.PROFILE as string,
  settingsPanelModeUpdated : getSignal( 'settingsPanelModeUpdated', 2 ),
  set settingsPanelMode( newMode : string ) {
    store._settingsPanelMode = newMode;
    store.settingsPanelModeUpdated.dispatch( store._settingsPanelMode );
  },
  get settingsPanelMode() : string {
    return store._settingsPanelMode;
  },

  // breakpoint
  _breakpoint : Breakpoints.LARGE as string,
  breakpointUpdated : getSignal( 'breakpointUpdated', 2 ),
  set breakpoint( newBreakpoint : string ) {
    if( newBreakpoint !== store._breakpoint ) {
      store._breakpoint = newBreakpoint;
      store.breakpointUpdated.dispatch( store._breakpoint );
    }
  },
  get breakpoint() : string {
    return store._breakpoint;
  },

  // activeTableID
  _activeTableID : '333333333',
  activeTableIDupdated : getSignal( 'activeTableIDupdated', 2 ),
  set activeTableID( tableID : string ) {
    store._activeTableID = tableID;
    store.activeTableIDupdated.dispatch( store._activeTableID );
  },
  get activeTableID() : string {
    return store._activeTableID;
  },

  // vibeEvent
  _vibeEvent : null as VibeEvent | any,
  vibeEventUpdated : getSignal( 'vibeEventUpdated', 2 ),
  get vibeEvent() {
    return store._vibeEvent;
  },
  set vibeEvent( newEvent : VibeEvent ) {
    store._vibeEvent = newEvent || null;
    store.vibeEventUpdated.dispatch( store._vibeEvent );
  },

  // polycast
  _polycast : null as Polycast | any,
  polycastUpdated : getSignal( 'polycastUpdated', 2 ),
  get polycast() {
    return store._polycast;
  },
  set polycast( newPolycast : Polycast ) {
    store._polycast = newPolycast || null;
    store.polycastUpdated.dispatch( store._polycast );
  },

  // dialogToDisplay
  _dialogToDisplay : '' as string,
  dialogToDisplayUpdated : getSignal( 'dialogToDisplayUpdated', 2 ),
  get dialogToDisplay() {
    return store._dialogToDisplay;
  },
  set dialogToDisplay( dialogID : string ) {
    store._dialogToDisplay = dialogID || '';
    store.dialogToDisplayUpdated.dispatch( store._dialogToDisplay );
  },

  // sessionData
  _sessionData : {} as any,
  sessionDataUpdated : getSignal( 'sessionDataUpdated', 0 ),
  get sessionData() {
    return store._sessionData;
  },
  set sessionData( newData ) {
    if( newData === null ) {
      store._sessionData = {};
    } else {
      store._sessionData = {
        ...store._sessionData,
        ...newData
      };
    }
    store.sessionDataUpdated.dispatch( store._sessionData );
  },

  // stringTable
  stringTable : {} as any

};
