import { store } from '../../data/store';
import { useRef, useCallback, useEffect, useState } from 'react';
import * as logger from '../../utils/logger';
import { Stream } from '../../vibe/vibe';
import StreamPanelHUDstar from '../StreamPanelHUDstar/StreamPanelHUDstar';

import './StreamPanelStar.less';

//region aws ivs player imports
/**
 * These imports are loaded via the file-loader, and return the path to the asset.
 * We use the TypeScript compiler (TSC) to check types; it doesn't know what this WASM module is, so let's ignore the error it throws (TS2307).
 */
// @ts-ignore
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm'
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';
//endregion

import './StreamPanelStar.less';
import { videoEvents } from './videoEvents';
import { addScriptTagTo } from '../../utils/utils';
import { streamPanelCoordsChanged } from '../../relay/signalRelay';
import { Play } from 'amazon-chime-sdk-component-library-react';
import { PlayerEventType } from 'amazon-ivs-player';

const CN : string = 'StreamPanelStar';

interface props {
  stream : Stream
}

export default function StreamPanelStar( {
  stream
} : props ) {

  // refs
  const playerRef : any = useRef( null );
  const videoRef : any = useRef( null );
  const streamPanelDivRef = useRef( null as any );

  // hooks
  const [ shouldDisplayPoster, setShouldDisplayPoster ] = useState( true );

  // callbacks
  const addPlayerListeners = useCallback( () => {
    const ivs = window[ 'IVSPlayer' ];
    const player = playerRef.current;

    const excludedEvents = [ 'BUFFER_UPDATE', 'TIME_UPDATE' ];

    videoEvents.find( videoEvent => {
      const cb = ( e ) => {
        logger.log( `star video received event : ${ videoEvent.type }` );
        logger.log( `\t${ videoEvent.msg }` );
        logger.log( `\t\t`, e );
        switch( true ) {
          case videoEvent.type === 'ERROR' :
            setShouldDisplayPoster( true );
            break;
          case videoEvent.type === 'STATE_CHANGED' && e === 'Ready' :
            setShouldDisplayPoster( false );
            break;
          case videoEvent.type === 'STATE_CHANGED' && e === 'Ended' :
            setShouldDisplayPoster( true );
            break;
        }
      };
      if( !excludedEvents.includes( videoEvent.type ) ){
        player.addEventListener( ivs.PlayerEventType[ videoEvent.type ], cb );
      }
    } );

  }, [] );

  const ivsScriptLoaded = useCallback( () => {
    logger.log( CN + '.ivsScriptLoaded' );

    const ivs = window[ 'IVSPlayer' ];

    if( !ivs.isPlayerSupported ) return logger.error( 'The current browser does not support the IVS player.' );

    // const playerStates = ivs.PlayerState;
    // const playerEventTypes = ivs.PlayerEventType;

    const player = ivs.create();
    playerRef.current = player;
    addPlayerListeners();
    logger.log( `IVS Player version : ${ player.getVersion() }` );
    player.attachHTMLVideoElement( videoRef.current );

    player.setAutoplay( true );
    // TODO : remove this hard coded string - the value lives in accounts - so we need to figure out how to make it public safely
    player.load( store.vibeEvent.streamSources[ 0 ].url );
    player.setVolume( 0.5 );

    player.setLogLevel( 'warn' );
  }, [] );

  useEffect( () => {
    if( window[ 'IVSPlayer' ] ){
      ivsScriptLoaded();
    } else {
      addScriptTagTo(
        'https://player.live-video.net/1.4.0/amazon-ivs-player.min.js',
        'body',
        ivsScriptLoaded );
    }
    return cleanup;
  }, [] );

  return <div
    ref={ streamPanelDivRef }
    id={ `streampanel_${ stream.streamID }` }
    key={ stream.streamID }
    draggable={ true }
    className={ `panel-stream star` }
    data-coord={ stream.coord }
    data-patronid={ 'star' }
    onDragEnter={e => {
      (e.target as HTMLElement).classList.add( 'targeted' );
      e.preventDefault();
    }}
    onDragOver={e => {
      (e.target as HTMLElement).classList.add( 'targeted' );
      e.preventDefault();
    }}
    onDragLeave={e => {
      (e.target as HTMLElement).classList.remove( 'targeted' );
    }}
    onDragStart={e => {
      dragStarted( e.target );
    }}
    onDrop={dropped}>
      <video
        ref={ videoRef }
        id={ `star-video` }
        playsInline={ true }
        controls={ false }
        muted={ false }
        role={ 'presentation' }
        crossOrigin={ 'anonymous' }
        autoPlay={ true }
        loop={ true }
      />

      <StreamPanelHUDstar
        playerRef={ playerRef }
      />
    { shouldDisplayPoster &&
      <div className="poster"/>
    }
  </div>;

  // `https://dev.vibertc.net:5443/vibertc/streams/${ stream.streamID }.m3u8`

  function dragStarted( streamPanel ) : void {
    streamPanelDivRef.current.classList.add( 'dragging' );
    store.sessionData = {
      streamPanelDragged : streamPanel
    };
  }

  function dragEnded() {
    const panelBeingDragged : HTMLDivElement = document.querySelector( '.dragging' ) as HTMLDivElement;
    panelBeingDragged && panelBeingDragged.classList.remove( 'dragging' );
    const dropTargets = document.querySelectorAll( '.drop-target' );
    const streamPanels = document.querySelectorAll( '.panel-stream' );
    dropTargets.forEach( dropTarget => dropTarget.classList.remove( 'targeted' ) );
    streamPanels.forEach( streamPanel => streamPanel.classList.remove( 'targeted' ) );
    streamPanelCoordsChanged.dispatch();
  }

  function dropped( e ) : void {
    let streamPanel;
    let target = e.target;
    while( !streamPanel ) {
      if( target?.classList?.contains( 'panel-stream' ) ) {
        streamPanel = target;
      } else {
        if( target.parentNode === null ) {
          e.preventDefault();
          dragEnded();
          return;
        }
        target = target.parentNode;
      }
    }
    const streamPanelDragged = store.sessionData.streamPanelDragged;
    const movedToCoord = streamPanel.getAttribute( 'data-coord' );
    const movedFromCoord = streamPanelDragged.getAttribute( 'data-coord' );
    streamPanel.setAttribute( 'data-coord', movedFromCoord );
    streamPanelDragged.setAttribute( 'data-coord', movedToCoord );
    dragEnded();
  }

  function cleanup() : void {
    logger.log( CN + '.cleanup' );


  }

}

