import { useState, useCallback } from 'react';
import { store } from '../../data/store';
import ClubViewPanel from '../ClubViewPanel/ClubViewPanel';

import './ClubPanel.less';

const CN : string = 'ClubPanel';

export default function ClubPanel() {
  console.log( CN + '.ClubPanel' );

  // hooks
  const [ club, setClub ] = useState( store.club || {} );

  // callbacks
  const clubUpdated = useCallback( setClub, [] );

  // signals remove
  store.clubUpdated.remove( clubUpdated );

  // signals add
  store.clubUpdated.add( clubUpdated );

  return (
    <div className="panel-club">
      <ClubViewPanel club={ club }/>
    </div>
  );
}

