import { store } from '../../data/store';
import * as logger from '../../utils/logger';
import { AppModes, dialogIDs } from '../../data/consts';
import { Fragment, ReactElement, useState, useCallback, useEffect } from 'react';

import { enterPolycast } from '../../fbase/fbase';
import { Patron, Status, TableInfo } from '../../vibe/vibe';
import { RelayTypes, SoundEvent, RelayPayload, useP2Prelay } from '../../relay/p2pRelay';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

import './ActivityMenu.less';

const CN = 'ActivityMenu';

interface props {
  appMode : string,
  patron : Patron,
  hasPolycast : boolean
}

let currentAudioVideo : any = null;

export default function ActivityMenu( {
  appMode,
  patron,
  hasPolycast
} : props ) : ReactElement {

  // hooks
  const [ isPolycastMuted, setIsPolycastMuted ] = useState( store.isPolycastMuted );
  const { relayPayload } = useP2Prelay( useAudioVideo() );
  const audioVideo = useAudioVideo();

  if( currentAudioVideo !== audioVideo ) {
    currentAudioVideo = audioVideo;
  }

  useEffect( () => {
    store.isPolycastMutedUpdated.add( setIsPolycastMuted );
    return( () => {
      store.isPolycastMutedUpdated.remove( setIsPolycastMuted );
    } );
  }, [] );

  return (
    <div
      className="activity-menu"
    >
      <div className="title">{ appMode.toLowerCase() }</div>
      { getBtns() }
    </div>
  );

  function getBtns() {
    switch( appMode ) {
      case AppModes.HOME :
        return <Fragment>
          { getBtnToggle() } { getTableBtns() }
        </Fragment>;
      case AppModes.SETTINGS :
        return <Fragment>
          { getBtnToggle() } { getTableBtns() }
        </Fragment>;
      case AppModes.EVENT :
        return <Fragment>
          { getBtnToggle() } { getBtnTip() } { hasPolycast && getBtnAirhorn() } { hasPolycast && getBtnMuteEveryone() }
        </Fragment>;
    }
  }

  function getBtnMuteEveryone() {
    return <button
      className={ `btn-mute-everyone${ isPolycastMuted ? ' off' : '' }` }
      onClick={ () => store.isPolycastMuted = !isPolycastMuted }
    >
      { isPolycastMuted ? 'Unmute Everyone' : 'Mute Everyone' }
    </button>;
  }

  function getBtnAirhorn() {
    return <button
      className="btn-airhorn"
      onClick={ ( e ) => {
        ( e.target as HTMLButtonElement )?.classList.add( 'clicked' );
        relayPayload(
          {
            type : RelayTypes.SoundEvent,
            data : {
              from : store.patron.patronID,
              to : [],
              soundID : 'airhorn',
              url : 'https://vibewith.me/snd/ham_horn_airhorn.mp3'
            } as SoundEvent
          } as RelayPayload );
      } }
      onTransitionEnd={ ( e ) => {
        ( e.target as HTMLButtonElement )?.classList.remove( 'clicked' );
      } }
    >Air Horn
    </button>;
  }

  function getBtnToggle() {
    return <button
      className="btn-toggle-pinned"
      onClick={ e => {
        const menu : HTMLDivElement = ( e.target as HTMLButtonElement ).parentNode as HTMLDivElement;
        menu.classList.toggle( 'pinned' );
      } }
    />;
  }

  function getBtnTip() {
    return <button
      className="btn-love"
      onClick={ ( e ) => {
        ( e.target as HTMLButtonElement )?.classList.add( 'clicked' );
        store.dialogToDisplay = dialogIDs.dialogTipTheStar;
      } }
      onTransitionEnd={ ( e ) => {
        ( e.target as HTMLButtonElement )?.classList.remove( 'clicked' );
      } }
    >Tip the DJ</button>;
  }

  function getSeparator() {
    return <div className="separator"/>;
  }

  function getTableBtns() {
    const elements : any[] = [];
    if( patron && patron.tables && patron.tables.length ) {
      elements.push( getSeparator() );
      patron.tables.find( ( tableInfo : TableInfo ) => {
        elements.push(
          <button
            className={ `btn-table${ tableInfo.seatStatus === Status.approved && tableInfo.tableStatus === Status.open ? ' approved' : ' pending' }` }
            onClick={ e => {
              if( tableInfo.seatStatus === Status.approved && tableInfo.tableStatus === Status.open ) {
                enterPolycast( tableInfo );
              } else {
                logger.warn( 'you cannot enter this table at this time.' );
              }
            } }
          >{ tableInfo.name || 'Table' }
          </button>
        );
        return false;
      } );
      elements.push( getSeparator() );
    }
    return elements;
  }
}
