import { store } from '../../data/store';

import './DialogHeader.less'

interface props {
  title? : string,
  onCloseClick? : any
}

export default function DialogHeader( {
  title = '',
  onCloseClick = () => store.dialogToDisplay = ''
} : props ) {
  return (
    <div className="header">
      <button
        className="btn-close"
        onClick={ onCloseClick }/>
      { title && <div className="title">{title}</div> }
    </div>
  );
}
