import { useRef, useState, useCallback, useEffect, ReactElement } from 'react';
import { store } from '../../data/store';
import { MeetingManager, useMeetingManager, useRemoteVideoTileState, useAudioVideo, useToggleLocalMute } from 'amazon-chime-sdk-component-library-react';
import { Polycast, Coord, Attendee, PresentationModes, MimeTypes, FXinfo, Filters } from '../../vibe/vibe';
import { useP2Prelay, TipEvent, RelayTypes, FXevent } from '../../relay/p2pRelay';
import StreamPanelLocal from '../StreamPanelLocal/StreamPanelLocal';
import DropTarget from '../DropTarget/DropTarget';
import StreamPanelStar from '../StreamPanelStar/StreamPanelStar';
import { addMotionPathSVG, removeElement, nodeListToArray } from '../../utils/utils';
import * as logger from '../../utils/logger';
import StreamPanelRemote from '../StreamPanelRemote/StreamPanelRemote';
import PolycastBar from '../PolycastBar/PolycastBar';
import { gsap, Power4 } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
import { chimeMeetingJoined, polycastTouched, polycastAddFaux, streamPanelCoordsChanged, streamPanelFXreceived } from '../../relay/signalRelay';
import StreamPanelFaux from '../StreamPanelFaux/StreamPanelFaux';

import './PolycastPanel.less';
import '../StreamPanel/StreamPanel.less';
import Portal from '../Portal/Portal';

const CN : string = 'PolycastPanel';
const loadOrder : Coord[] = [ Coord.a5, Coord.b5, Coord.c5, Coord.d5, Coord.e5, Coord.a4, Coord.e4, Coord.a3, Coord.e3, Coord.a2, Coord.e2, Coord.a1, Coord.b1, Coord.c1, Coord.d1, Coord.e1 ];
const stageDropTargetCoords : string[] = [ 'stage-center', 'stage-tl', 'stage-tr', 'stage-bl', 'stage-br' ];


let renderCount : number = 0;

let lastCoordSnapshot;
let coordSnapshot;

gsap.registerPlugin( MotionPathPlugin );

function muteMeeting() {
  const audio : HTMLAudioElement = document.querySelector( 'audio' ) as HTMLAudioElement;
  if( !audio ) return logger.error( 'there is not <audio/> element to mute!' );

  audio.muted = true;
}

function unmuteMeeting() {
  const audio : HTMLAudioElement = document.querySelector( 'audio' ) as HTMLAudioElement;
  if( !audio ) return logger.error( 'there is not <audio/> element to UNmute!' );

  audio.muted = false;
}

export default function PolycastPanel() {
  renderCount++;

  logger.log( CN + ' rendering - render : ' + renderCount );

  const meetingManager : MeetingManager = useMeetingManager();

  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();

  const { relaySignals, relayPayload } = useP2Prelay( useAudioVideo() );

  // hooks
  const [ polycast, setPolycast ] = useState( store.polycast as Polycast );
  const [ fauxPanels, setFauxPanels ] = useState( [] as any[] );
  const [ isPolycastMuted, setIsPolycastMuted ] = useState( store.isPolycastMuted );
  const { muted, toggleMute } = useToggleLocalMute();

  // refs
  const polycastDivRef = useRef( null as any );

  // callbacks
  const isPolycastMutedUpdated = useCallback( ( isMuted : boolean ) => {
    isMuted ? muteMeeting() : unmuteMeeting();
    setIsPolycastMuted( isMuted );
  }, [] );
  const polycastUpdated = useCallback( setPolycast, [] );
  const takeCoordSnapshot = useCallback( () => {
    console.log( CN + '.takeCoordSnapshot' );

    const streamPanels : HTMLDivElement[] = nodeListToArray( getPolycastDiv().querySelectorAll( '.panel-stream' ) );
    lastCoordSnapshot = coordSnapshot;
    coordSnapshot = {};
    streamPanels.find( streamPanel => {
      coordSnapshot[ streamPanel.getAttribute( 'data-patronid' ) as string ] = streamPanel.getAttribute( 'data-coord' );
    } );
    // console.log( JSON.stringify( coordSnapshot, null, 2 ) );
  }, [] );
  const addFauxPanel = useCallback( () => {
    fauxPanels.push( <StreamPanelFaux coord={ getNextEmptyCoord() }/> );
    setFauxPanels( [ ...fauxPanels ] );
  }, [] );

  // signals remove
  store.polycastUpdated.remove( polycastUpdated );
  store.isPolycastMutedUpdated.remove( isPolycastMutedUpdated );

  // signals add
  store.polycastUpdated.add( polycastUpdated );
  store.isPolycastMutedUpdated.add( isPolycastMutedUpdated );

  const hasHostPrivileges = true; // myStream.role === Roles.host || myStream.role === Roles.cohost;

  const join = async () => {
    logger.log( CN + '.join' );

    try {
      const joinInfo = {
        meetingInfo : polycast.chime.meeting,
        attendeeInfo : polycast.myAttendeeInfo
      };

      if( meetingManager ) {
        await meetingManager.join( joinInfo );
      } else {
        logger.warn( CN + '.join failed! WARNING : !meetingManager - TODO : implement retry logic' );
      }

      // At this point you could let users setup their devices, or by default
      // the SDK will select the first device in the list for the kind indicated
      // by `deviceLabels` (the default value is DeviceLabels.AudioAndVideo)

      await meetingManager.start();
    } catch( err ) {
      console.log( 'polycast :', polycast );
      logger.error( err );
    }
  };

//region every render useEffect(s)
  useEffect( takeCoordSnapshot );
//endregion
//region one time useEffect(s)
  useEffect( () => {
    join()
      .then( () => {
        logger.log( 'joined the chime meeting' );
        chimeMeetingJoined.dispatch();
        store.isChatEnabled = true;
        window.setTimeout( () => {
          const talkBtn = window.document.querySelector( '.btn-talking' ) as HTMLDivElement;
          talkBtn?.click();
        }, 50 );
      } )
      .catch( err => logger.error( err ) );

    relaySignals[ RelayTypes.TipEvent ].add( tipGiven );
    relaySignals[ RelayTypes.FXevent ].add( fxReceived );
    store.polycastUpdated.add( setPolycast );
    polycastAddFaux.add( addFauxPanel );
    streamPanelCoordsChanged.add( takeCoordSnapshot );

    return cleanup;
  }, [] )
//endregion

function getMutedCurrent() {
  return muted;
}

  return <div className="aspect-container">
      <div
        onMouseOver={ () => polycastTouched.dispatch() }
        className="panel-polycast"
        ref={ polycastDivRef }
      >
        { getDropTargets() }
        <StreamPanelLocal/>
        <StreamPanelStar
          stream={ { ...( store.vibeEvent.eventStream || {} ), coord : Coord.stageCenter } }
        />
        { getStreamPanels( tiles ) }
        { fauxPanels }
        <PolycastBar
          isPolycastMuted={ isPolycastMuted }
          polycastDivRef={ polycastDivRef }
          hasHostPrivileges={ hasHostPrivileges }
        />
      </div>
      <Portal className="polycast-hud">
        <div
          className={`btn-talking${ !muted ? ' talking' : '' }`}
          onClick={ () => { !muted && toggleMute() } }
          onMouseDown={ () => { muted && toggleMute() } }
          onMouseOut={ () => { !muted && toggleMute() } }
          onMouseUp={ () => { !muted && toggleMute() } }
        />
      </Portal>
    </div>;

  function getStreamPanels( tileIDs : number[] ) : any[] {
    // TODO : wallflowers and other considerations past 15 participants
    const streamPanels = [] as any[];

    tileIDs.find( id => {
      const attendee : Attendee = getAttendeeByTileID( id );
      const existingStreamPanel = getPolycastDiv().querySelector( `#panel-stream-${ attendee?.patron?.patronID }` );
      let coord;
      switch( true ) {
        case coordSnapshot.hasOwnProperty( attendee?.patron?.patronID as string ) :
          coord = coordSnapshot[ attendee.patron?.patronID as string ] || lastCoordSnapshot[ attendee.patron?.patronID as string ];
          logger.tracer( `we have a coord from snapshot : ${ coord }` );
          break;
        case !!existingStreamPanel :
          logger.tracer( 'we have an existingStreamPanel' );
          coord = existingStreamPanel?.getAttribute( 'data-coord' );
          break;
        default :
          logger.tracer( 'we are getting a brand new coord' );
          coord = getNextEmptyCoord();
      }

      if( coord === Coord.none ) {
        logger.tracer( `coord === Coord.none - not pushing <StreamPanelRemote/> from tileID` );
        return true;
      }

      if( attendee?.patron ){ // skip this one if the patron is gone
        streamPanels.push( <StreamPanelRemote
          key={ attendee.patron?.patronID }
          tileID={ id }
          providedAttendee={ attendee }
          coord={ coord }
        /> );
      }
    } )

    // TODO : iterate through attendees with mode === 'mediaVideo'
    polycast.chime.attendees.find( ( attendee : Attendee ) => {
      switch( true as boolean ) {
        case !attendee :
          logger.tracer( 'undefined attendee encountered.' );
          break;
        case !attendee.hasOwnProperty( 'mode' ) || attendee.mode === PresentationModes.streamVideo :
          logger.tracer( 'case mode streamVideo' );
          break;
        case attendee.mode === PresentationModes.mediaVideo && store.patron.patronID !== attendee?.patron?.patronID :
          logger.tracer( 'case mode mediaVideo and not local video' );
          const coord : Coord = coordSnapshot[ attendee.patron?.patronID as string ] || lastCoordSnapshot[ attendee.patron?.patronID as string ];
          streamPanels.push( <StreamPanelRemote
            key={ attendee.patron?.patronID }
            tileID={ -1 }
            providedAttendee={ attendee }
            coord={ coord }
          /> );
          break;
        default :
          logger.warn( `unhandled attendee mode encountered : ${ attendee.mode }` );
      }
    } );

    return streamPanels;
  }

  function getAttendeeByTileID( tileID : number ) : Attendee {
    let attendee : Attendee = polycast.chime.attendees.find( attendee => attendee.AttendeeId === tileIdToAttendeeId[ tileID ] ) as Attendee;
    attendee || logger.warn( `the current polycast.chime.attendees doesn't contain an attendee with AttendeeId : ${ tileIdToAttendeeId[ tileID ] }` );
    return attendee;
  }

  function getDropTargets() : ReactElement[] {
    const dropTargets : ReactElement[] = [];
    loadOrder.find( ( coord : string, i : number ) => {
      dropTargets.push(
        <DropTarget
          id={ `dp_edge_${i}` }
          coord={ coord }
        />
      )
    } );

    stageDropTargetCoords.find( ( coord : string, i : number ) => {
      dropTargets.push(
        <DropTarget
          id={ `dp_stage_${ i }` }
          coord={ coord }
        />
      )
    } );

    return dropTargets;
  }

  function getPolycastDiv() : HTMLDivElement {
    return polycastDivRef.current as HTMLDivElement;
  }

  function getNextEmptyCoord() : Coord {
    return loadOrder.find( coord => !( getPolycastDiv().querySelector( `.panel-stream[data-coord="${ coord }"]` ) ) ) || Coord.none;
  }

  function tipGiven( tipEvent : TipEvent ) : void {
    const polycastDiv : HTMLDivElement = polycastDivRef.current as HTMLDivElement;
    const fromQuerySelector : string = `#panel-stream-${ tipEvent.from === store.patron.patronID ? 'local' : tipEvent.from }`;
    const fromStreamPanel : HTMLDivElement = polycastDiv.querySelector( fromQuerySelector ) as HTMLDivElement;
    const toStreamPanel : HTMLDivElement = polycastDiv.querySelector( `.panel-stream.star` ) as HTMLDivElement;

    // get rects (relative to viewport)
    const fromRect : DOMRect = fromStreamPanel.getBoundingClientRect();
    const toRect : DOMRect = toStreamPanel.getBoundingClientRect();
    const polycastRect : DOMRect = polycastDiv.getBoundingClientRect();

    // make coords local to polycast div
    fromRect.x -= polycastRect.x;
    fromRect.y -= polycastRect.y;

    toRect.x -= polycastRect.x;
    toRect.y -= polycastRect.y;

    polycastRect.x = 0;
    polycastRect.y = 0;

    // create hearts
    const hearts : HTMLDivElement[] = [];
    let heart : HTMLDivElement;
    for( let i=0; i< tipEvent.tipAmount; i++ ) {
      heart = document.createElement( 'div' );
      heart.className = 'tip-heart';
      // heart.setAttribute( 'data-index', i.toString() );
      hearts.push( heart );
    }

    // animate hearts for tipping
    let coord = fromStreamPanel.getAttribute( 'data-coord' );

    const { removeSVG, arcSelector }  = addMotionPathSVG( fromRect, toRect, polycastRect, '.panel-polycast' );

    let tweens : gsap.core.Tween[] = [];

    hearts.find( ( heartDiv : HTMLDivElement, i : number ) => {
      let tween : gsap.core.Tween;
      polycastDiv.appendChild( heartDiv );

      gsap.set( heartDiv, {
        scale : 0.5,
        x : fromRect.x + ( fromRect.width * 0.5 ),
        y : fromRect.y + ( fromRect.height * 0.5 ),
        opacity : 0
      } );

      tween = gsap.to( heartDiv, 0.8, {
        delay : i * 0.45,
        scale : 0.5,
        opacity : 0.8,
        ease : Power4.easeOut,
        motionPath:{
          path: arcSelector,
          align: arcSelector,
          alignOrigin: [ 0.5, 0.5 ]
        },
        onStart : () => {
          gsap.set( heartDiv, {
            opacity : 0.2
          } );
        },
        onUpdate : () => {
          const coordNow = fromStreamPanel.getAttribute( 'data-coord' );
          if( coordNow !== coord ) {
            logger.warn( 'fromStreamPanel moved!!' );
            /* TODO :
             * • kill all tweens
             * • remove all hearts
             * • update tipEvent.tipAmount
             * • re-entrant
             */
          }
        },
        onComplete : () => {
          removeElement( heartDiv, true );
          tweens.find( ( heartTween : gsap.core.Tween, ii : number ) => {
            if( heartTween.targets()[ 0 ] === heartDiv ) {
              tweens.splice( ii, 1 );
              return true;
            }
          } );
          if( !tweens.length ) {
            removeSVG();
          }
        }
      }  );
      tweens.push( tween );
    } );
  }

  function fxReceived( fxEvent : FXevent ) : void {
    console.log( CN + '.fxReceived' );

    // TODO : const fxInfo : FXinfo | null = await getFXmediaInfo( fxEvent );
    /**
     * isFX?
     * isAuthorized? ( usageCount++ )
     * is
     */
    // if( !fxInfo ) return;

    const files = {
      'heartBeating' : 'fx_heart_beating.svg',
      'heartRain' : 'fx_heart_rain.svg',
      'heartCenter' : 'fx_heart_grow_fade.svg'
    }

    fxEvent.fxInfo = {
      fxID : fxEvent.fxID,
      filter : Filters[ fxEvent.filter ],
      mediaInfo : {
        mediaID : fxEvent.fxID,
        url : `${ window.location.protocol }//${ window.location.host }/img/${ files[ fxEvent.fxID ] }`,
        mimeType : MimeTypes.svg,
        duration : 8,
        thumb : {
          url : `${ window.location.protocol }//${ window.location.host }/img/icon_heart.svg`,
          mimeType : MimeTypes.svg
        }
      }
    }

    const polycastDiv : HTMLDivElement = polycastDivRef.current as HTMLDivElement;
    const fromQuerySelector : string = `#panel-stream-${ fxEvent.from === store.patron.patronID ? 'local' : fxEvent.from }`;
    const fromStreamPanel : HTMLDivElement = polycastDiv.querySelector( fromQuerySelector ) as HTMLDivElement;
    const toQuerySelector : string = `#panel-stream-${ fxEvent.to[ 0 ] === store.patron.patronID ? 'local' : fxEvent.to[ 0 ] }`;
    const toStreamPanel : HTMLDivElement = polycastDiv.querySelector( toQuerySelector ) as HTMLDivElement;

    // NOTE : there is catastrophic failure if an error occurs in the stack when a data channel message is received
    if( !fromStreamPanel || !toStreamPanel || !polycastDiv ){
      logger.warn( 'missing fromStreamPanel or toStreamPanel or polycastDiv' );
      console.log( 'fromStreamPanel :', fromStreamPanel );
      console.log( 'toStreamPanel :', toStreamPanel );
      console.log( 'polycastDiv :', polycastDiv );
      console.log( 'fxEvent :', JSON.stringify( fxEvent, null, 2 ) );
      logger.warn( 'NOTE : fx will not be displayed.' );
      return;
    }

    // get rects (relative to viewport)
    const fromRect : DOMRect = fromStreamPanel.getBoundingClientRect();
    const toRect : DOMRect = toStreamPanel.getBoundingClientRect();
    const polycastRect : DOMRect = polycastDiv.getBoundingClientRect();

    // localize coords to polycast div
    fromRect.x -= polycastRect.x;
    fromRect.y -= polycastRect.y;

    toRect.x -= polycastRect.x;
    toRect.y -= polycastRect.y;

    polycastRect.x = 0;
    polycastRect.y = 0;

    const thumb : HTMLObjectElement = document.createElement( 'object' );
    thumb.className = `svg-in-object thumb ${ fxEvent.fxInfo.filter || Filters.red }`;
    thumb.type = MimeTypes.svg;
    thumb.data = fxEvent.fxInfo?.mediaInfo?.thumb?.url || '';
    thumb.onload = animateFX;

    polycastDiv.appendChild( thumb );

    function animateFX() {
      console.log( CN + '.animateFX' );

      const { removeSVG, arcSelector } = addMotionPathSVG( fromRect, toRect, polycastRect, '.panel-polycast' );

      gsap.set( thumb, {
        // NOTE: we scale the 500px to 50px - this is for iOS
        scale : 0.1,
        x : fromRect.x + ( fromRect.width * 0.5 ),
        y : fromRect.y + ( fromRect.height * 0.5 ),
        opacity : 0.6
      } );

      const tween = gsap.to( thumb, 0.8, {
        opacity : 1,
        ease : Power4.easeOut,
        motionPath:{
          path: arcSelector,
          align: arcSelector,
          alignOrigin: [ 0.5, 0.5 ]
        },
        onComplete : () => {
          removeElement( thumb, true );
          removeSVG();
          streamPanelFXreceived.dispatch( fxEvent );
        }
      }  );
    }
  }

  function cleanup() {
    console.log( CN + '.cleanup' );
    relaySignals[ RelayTypes.TipEvent ].remove( tipGiven );
    store.polycastUpdated.remove( setPolycast );
    polycastAddFaux.remove( addFauxPanel );
    streamPanelCoordsChanged.remove( takeCoordSnapshot );
  }
}
